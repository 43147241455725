import Color from 'color';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDimensions, useMedia } from 'react-recipes';
import { useNavigate } from 'react-router-dom';
import { Durations, Easings } from '../../animation';
import Appear from '../Appear';
import { TextButton } from '../Button';
import { ClipPathMask } from '../ClipPathMask';
import { Flex } from '../Flex';
import { MaskedReveal } from '../Motion';
import { MotionFlex } from '../MotionFlex';
import { Icon } from '../icons';
import Avatar from './Avatar';
import Details from './Details';
import DetailsMobile from './DetailsMobile';
import classes from './ModalBody.module.scss';
import Quote from './Quote';

function createElement(type, children, key) {
  if (children instanceof Array) {
    return (
      React.createElement(type, {
        key,
        children: children.map(([type, children], key) => (
          createElement(type, children, key)
        ))
      })
    );
  }

  return React.createElement(type, { key, children });
}

function ModalBody({
  close,
  content,
  theme,
  onClose
}) {
  /** @type {[Function, DOMRect]} */
  const [bodyRef, dimensions] = useDimensions();
  const isMobile = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  const navigate = useNavigate();

  const {
    link: {
      href,
      target,
      text
    } = { href: null, target: null, text: null }
  } = content;

  const t = useMemo(() => {
    const height = dimensions?.height ?? 0;
    return height * 0.35
  }, [dimensions?.height])

  const Body = useMemo(() => (
    content?.body?.map(([type, children], key) => (
      createElement(type, children, key)
    ))
    || (
      <p></p>
    )
  ), [content]);

  const [isHover, setHover] = useState(false);

  const BackgroundColor = useMemo(() => (
    Color(theme.modalCloseBtnSecondary ?? '#2b292c')
  ), [theme.modalCloseBtnSecondary]);

  const variants = useMemo(() => ({
    closeBtn: {
      normal: {
        backgroundColor: BackgroundColor.hex()
      },
      hover: {
        backgroundColor: BackgroundColor.darken(0.25).hex()
      }
    },
    closeBtnIcon: {
      normal: (deg) => ({
        rotate: [null, deg],
        transition: {
          duration: 0.6,
          ease: Easings.easeOutCubic
        }
      }),
      hover: () => ({
        rotate: 0,
        transition: {
          duration: 0.6,
          ease: Easings.easeOutCubic
        }
      })
    }
  }), [BackgroundColor]);

  const DetailsElement = useMemo(() => {
    return React.createElement(
      isMobile
        ? DetailsMobile
        : Details,
      {
        ...content,
        theme,
        close
      }
    );
  }, [close, content, isMobile, theme]);

  const buttonClickHandler = useMemo(() => {
    const isInternal = href?.startsWith('/') ?? false;

    if (isInternal) {
      return (
        () => close(() => navigate(href)
        ))
    }

    return null;
  }, [close, href, navigate]);

  const LinkElement = useMemo(() => {
    if (!href) {
      return null;
    }

    const isInternal = href?.startsWith('/') ?? false;

    return (
      <TextButton
        asLink
        href={href}
        icon={(
          <Icon type={isInternal ? 'link' : 'linkFlip'} />
        )}
        target={target}
        onClick={buttonClickHandler}
      >
        {text ?? href}
      </TextButton>
    )
  }, [buttonClickHandler, href, target, text]);

  const handleHoverEnd = useCallback(() => {
    setHover(false);
  }, []);

  const handleHoverStart = useCallback(() => {
    setHover(true);
  }, []);

  return (
    <div
      className={classes.root}
      data-lenis-prevent="true"
    >
      <Flex
        className={classes.inner}
        direction="column"
        justifyContent="space-between"
      >
        <MotionFlex
          alignItems="center"
          className={classes.close}
          initial={variants.closeBtn.normal}
          justifyContent="center"
          transition={{ duration: 0.24, ease: 'easeInOut' }}
          whileHover={variants.closeBtn.hover}
          onClick={close}
          onHoverEnd={handleHoverEnd}
          onHoverStart={handleHoverStart}
        >
          <motion.div
            animate={isHover ? 'hover' : 'normal'}
            className={classes.closeStroke}
            custom={-45}
            initial={variants.closeBtnIcon.normal}
            variants={variants.closeBtnIcon}
          />

          <motion.div
            animate={isHover ? 'hover' : 'normal'}
            className={classes.closeStroke}
            custom={45}
            initial="normal"
            variants={variants.closeBtnIcon}
          />
        </MotionFlex>

        <Flex
          className={classes.contentContainer}
          direction={isMobile ? 'column' : 'row'}
          grow={1}
          style={
            useMemo(() => (
              isMobile
                ? {
                  direction: 'column',
                  height: '100%',
                  overflow: 'hidden auto',
                } : {
                  height: '100%',
                  overflow: 'hidden'
                }
            ), [isMobile])
          }
        >
          <Flex
            className={classes.quote}
            direction="column"
            justifyContent="space-between"
          >
            <Quote>
              {content.quote}
            </Quote>

            <Flex
              alignItems="flex-end"
              className={classes.avatar}
              justifyContent="space-between"
            >

              {content.signature && (
                <Appear className={classes.signature} exit="">
                  <ClipPathMask
                    as={MotionFlex}
                    delay={1.12}
                    duration={4.8}
                    maskDirection="left"
                    style={{
                      width: 'inherit',
                      height: 'inherit'
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={content.signature.viewBox}
                    >
                      <path
                        d={content.signature.d}
                        style={{
                          fill: "var(--avatar-name, #fff)",
                          stroke: "var(--avatar-name, #fff)",
                        }}
                      />
                    </svg>
                  </ClipPathMask>
                </Appear>
              )}

              <Avatar
                name={content.name}
                src={content.thumb}
                title={content.title}
              />
              {!isMobile && LinkElement && (
                <MaskedReveal
                  delay={[0.92]}
                >
                  {LinkElement}
                </MaskedReveal>
              )}
            </Flex>

            {!isMobile ? (DetailsElement) : null}
          </Flex>

          <Flex
            ref={bodyRef}
            className={classes.bodyWrapper}
            style={{
              '--scrollbar-margin': `${t}px`
            }}
          >
            <MotionFlex
              animate={{ y: '0', opacity: 1 }}
              className={classes.body}
              direction="column"
              initial={{ y: !isMobile ? '-100%' : '100%', opacity: 0 }}
              transition={{
                delay: 0.52,
                duration: Durations.base * 1.2,
                ease: Easings.easeOutCubic
              }}
            >
              {Body}
            </MotionFlex>
          </Flex>

          {isMobile ? (DetailsElement) : null}
        </Flex>
      </Flex>
    </div>
  );
}

ModalBody.propTypes = {
  close: PropTypes.func,
  theme: PropTypes.object
};

ModalBody.defaultProps = {
  close: null,
  theme: {}
};

export default ModalBody;
