import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from "../../gsap";

const Line = styled.hr`
  border: none;
  width: ${props => props.isVertical ? '1px' : '100%'};
  height: ${props => props.isVertical ? (props.height || '100%') : '1px'};
  background-color: ${props => props.color || 'currentColor'};
  opacity: ${props => props.opacity};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;

const Border = (props) => {
  const el = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // or refs
      gsap.from(el.current, {
        [props.isVertical ? 'scaleY' : 'scaleX']: 0,
        transformOrigin: props.isVertical ? 'top' : 'left',
        scrollTrigger: {
          trigger: el.current,
        }
      });
      
    }, el);
    
    return () => ctx.revert();
  }, [props.isVertical]);

  return (
    <Line ref={el} className={props.className} isVertical={props.isVertical} height={props.height} color={props.color} opacity={props.opacity} margin={props.margin} padding={props.padding} />
  );
};

export default Border;
