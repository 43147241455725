/* eslint-disable max-len */

import classNames from 'classnames/dedupe';
import React, { useMemo } from 'react';
import classes from './Flex.module.scss';
import { getGapValue, isFlexGapSupported } from './helpers';
import { FlexDefaultProps, FlexPropTypes } from './props';

/**
 * @typedef {object} FlexProps
 * @property {'flex-end'|'flex-start'|'baseline'|'center'|'stretch'} alignItems
 * @property {'column'|'column-reverse'|'row'|'row-reverse'} direction
 * @property {'flex-end'|'flex-start'|'space-around'|'space-between'|'space-evenly'|'center'} justifyContent
 */

const directionToClassNameMap = {
  column: classes.directionColumn,
  'column-reverse': classes.directionColumnReverse,
  row: null,
  'row-reverse': classes.directionRowReverse
};

const justifyContentToClassNameMap = {
  'flex-end': classes.justifyContentFlexEnd,
  'flex-start': classes.justifyContentFlexStart,
  'space-around': classes.justifyContentSpaceAround,
  'space-between': classes.justifyContentSpaceBetween,
  'space-evenly': classes.justifyContentSpaceEvenly,
  center: classes.justifyContentCenter,
};

const alignItemsToClassNameMap = {
  'flex-end': classes.alignItemsFlexEnd,
  'flex-start': classes.alignItemsFlexStart,
  baseline: classes.alignItemsBaseline,
  center: classes.alignItemsCenter,
  stretch: classes.alignItemsStretch
};

/**
 * @param {FlexProps} props
 * @returns {JSX.Element}
 */
const Flex = React.forwardRef((
  {
    alignItems,
    children,
    className,
    columnGap,
    direction,
    el,
    gap,
    grow,
    justifyContent,
    rowGap,
    style,
    wrap,
    onClick,
    ...rest
  },
  ref
) => {
  const shouldRenderFallback = !isFlexGapSupported();

  const computedStyle = useMemo(() => {
    if (shouldRenderFallback) {
      return {
        ...style,
        '--column-gap': getGapValue(columnGap),
        '--gap': getGapValue(gap),
        '--row-gap': getGapValue(rowGap)
      };
    }

    return {
      ...style,
      columnGap: columnGap || gap,
      flexGrow: grow,
      rowGap: rowGap || gap
    };
  }, [columnGap, gap, grow, rowGap, shouldRenderFallback, style]);

  const element = (
    React.createElement(
      el,
      {
        ref: ref,
        className: classNames([
          classes.root,
          directionToClassNameMap[direction],
          justifyContentToClassNameMap[justifyContent],
          alignItemsToClassNameMap[alignItems],
          {
            [classes.wrap]: wrap,
            [classes.flexGap]: shouldRenderFallback
          },
          className
        ]),
        style: computedStyle,
        ...rest,
        onClick
      },
      children
    )
  );

  if (shouldRenderFallback) {
    return (
      <div
        className={classes.flex}
        onClick={onClick}
      >
        {element}
      </div>
    );
  }

  return (element);
});

Flex.displayName = 'Flex';

Flex.propTypes = FlexPropTypes;

Flex.defaultProps = FlexDefaultProps;

export default Flex;
