import React from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";

const Text = styled.span`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: ${(props) => props.fontSize || pxToRem(14)};
  letter-spacing: -0.04em;
  line-height: 1.714;
  text-transform: ${(props) => props.transform || "uppercase"};
  text-align: ${(props) => props.align || "center"};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  display: flex;
  white-space: nowrap;
`;

const Textmain = styled.span`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  margin-right: ${pxToRem(6)};
  font-size: ${(props) => props.fontSize || pxToRem(14)};
  letter-spacing: 0.04em;
  line-height: 1.714;
  display: inline-block;
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${(props) => props.fontSizeM || pxToRem(12)};
    line-height: 2;
  }
`;

const Numbertext = styled.span`
  font-family: "Helvetica Now Text", sans-serif;
  font-weight: 400;
  font-size: ${(props) => props.fontSize || pxToRem(14)};
  letter-spacing: 0em;
  line-height: 1.714;
  display: inline-block;
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${(props) => props.fontSizeM || pxToRem(12)};
    line-height: 2;
  }
`;

const Numbericon = styled.span`
  font-family: "Helvetica Now Text", sans-serif;
  font-weight: 400;
  margin-left: 1px;
  font-size: ${(props) => props.fontSize || pxToRem(14)};
  letter-spacing: 0em;
  line-height: 1.846;

  @media screen and (max-width: 620px) {
    font-size: ${(props) => props.fontSizeM || pxToRem(12)};
    line-height: 2;
  }
`;

const Chapter = (props) => {
  return (
    <Text
      className={props.className}
      color={props.color}
      align={props.align}
      transform={props.transform}
      margin={props.margin}
      fontSize={props.fontSize}
    >
      {props.text && (<Textmain fontSize={props.fontSize} fontSizeM={props.fontSizeM}>{props.text}</Textmain>)} <Numbertext className="numbertext" fontSize={props.fontSize} fontSizeM={props.fontSizeM}>№</Numbertext>
      <Numbericon className="numbericon" fontSize={props.fontSize} fontSizeM={props.fontSizeM}>{props.number}</Numbericon>
    </Text>
  );
};

export default Chapter;
