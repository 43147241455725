import { motion } from 'framer-motion';
import { clamp } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useWindowSize } from 'react-recipes';
import classes from './Large.module.scss';
import { CursorProps } from './props';
import PointerTypes from './types';

const SIZE_FACTOR =  11.4285;
function Large({
  label,
  type,
  x,
  y,
}) {
  const { width } = useWindowSize();

  const size = useMemo(() => (
    clamp(Math.floor(width / SIZE_FACTOR), 100, 134)
  ), [width]);

  const computedStyle = useMemo(() => ({
    height: size,
    left: -(size / 2),
    position: 'fixed',
    top: -(size / 2),
    width: size
  }), [size]);

  return (
    <motion.div
      animate={{ scale: type === PointerTypes.Default ? 0 : 1 }}
      initial={{ scale: 0 }}
      className={classes.root}
      style={{ ...computedStyle, x, y }}
    >
      <div className={classes.label}>
        {label}
      </div>
    </motion.div>
  );
}

Large.propTypes = {
  ...CursorProps,
  x: PropTypes.object,
  y: PropTypes.object
};

export default Large;
