import { useModal } from '@liholiho/react-modal-hook';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import OverlayMask, { OverlayMaskPropTypes } from '../OverlayMask';
import Content from './Content';
import './Modal.scss';
import { useGlobalModalState } from './globalModalStateContext';

function ModalContentWrapper({
  body,
  contentEl: Content,
  contentProps,
  hideModal,
  overlayProps,
  withOverlay,
  onBeforeClose,
  onBeforeOpen,
  onClose,
  onOpen
}) {
  const ref = useRef();

  const handleHideModal = useCallback(() => {
    if (onBeforeClose instanceof Function) {
      onBeforeClose();
    }

    hideModal();

    if (onClose instanceof Function) {
      onClose();
    }
  }, [hideModal, onBeforeClose, onClose]);

  const Body = (
    <Content
      ref={ref}
      body={body}
      contentProps={contentProps}
      hideModal={hideModal}
      onOpen={onOpen}
      onClose={onClose}
      onBeforeOpen={onBeforeOpen}
      onBeforeClose={onBeforeClose}
    />
  );

  if (withOverlay) {
    return (
      <OverlayMask
        {...overlayProps}
        onClick={handleHideModal}
      >
        {Body}
      </OverlayMask>
    );
  }

  return (Body);
}

function Modal({
  body,
  children,
  contentEl: Content,
  contentProps,
  overlayProps,
  withOverlay,
  onBeforeClose,
  onBeforeOpen,
  onClose,
  onOpen
}) {
  const onCloseRef = useRef();
  const { set } = useGlobalModalState();

  const handleOpen = useCallback(() => {
    set(true);

    if (onOpen instanceof Function) {
      onOpen();
    }
  }, [set, onOpen]);

  const handleClose = useCallback(() => {
    set(false);

    if (onClose instanceof Function) {
      onClose();
    }
  }, [set, onClose]);

  const [showModal, hideModal] = useModal(() => {
    return (
      <ModalContentWrapper
        body={body}
        contentEl={Content}
        contentProps={contentProps}
        hideModal={onCloseRef.current}
        overlayProps={overlayProps}
        withOverlay={withOverlay}
        onBeforeClose={onBeforeClose}
        onBeforeOpen={onBeforeOpen}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    )
  }, [
    body,
    Content,
    contentProps,
    overlayProps,
    withOverlay,
    onBeforeClose,
    onBeforeOpen,
    onClose,
    onOpen
  ]);

  onCloseRef.current = hideModal;

  return (children({ showModal, hideModal }));
}

Modal.propTypes = {
  body: PropTypes.elementType.isRequired,
  bodyData: PropTypes.object,
  children: PropTypes.elementType.isRequired,
  contentEl: PropTypes.elementType,
  overlayProps: PropTypes.shape(OverlayMaskPropTypes),
  withOverlay: PropTypes.bool,
  onBeforeClose: PropTypes.func,
  onBeforeOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

Modal.defaultProps = {
  bodyData: {},
  contentEl: Content,
  overlayProps: {},
  withOverlay: false,
  onBeforeClose: noop,
  onBeforeOpen: noop,
  onClose: noop,
  onOpen: noop
};

export default Modal;
