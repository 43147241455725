import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor};
`;

const Image = (props) => {
  return (
    <Wrapper className={props.className} width={props.width} backgroundColor={props.backgroundColor} margin={props.margin} padding={props.padding} style={{ "--aspect": props.aspect }}>
      {props.children}
    </Wrapper>
  );
};

export default Image;
