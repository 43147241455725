import { useCallback, useMemo, useState } from "react";
import { GlobalModalStateContextProvider } from "./globalModalStateContext"

/** @type {import('react').FC<import('react').PropsWithChildren<{}>} */
export const GlobalModalStateProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const set = useCallback((open) => {
    setOpen(open);
  }, []);

  const contextValue = useMemo(() => ({
    open,
    set
  }), [open, set]);

  return (
    <GlobalModalStateContextProvider value={contextValue}>
      {children}
    </GlobalModalStateContextProvider>
  );
};

GlobalModalStateProvider.displayName = 'GlobalModalStateProvider';
