import { ModalProvider } from '@liholiho/react-modal-hook';
import { useEventListener, useRequest, useThrottleFn } from 'ahooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMedia } from 'react-recipes';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import 'swiper/css/parallax';
import './App.scss';
import cover from './assets/img/home-bg.jpg';
import video from './assets/media/45fps-slower.mp4';
import mobileVideo from './assets/media/mobile-bg.mp4';
import { usePageTransition } from './components/PageTransitionProvider';
import SiteLayout from './components/SiteLayout';
import VideoBackgroundProvider from './components/VideoBackgroundProvider/provider';
import { getRegisteredRoutes, getRouteObject } from './routing';

import { SiteConfigurationAPI } from './api/siteConfiguration';
import { CookiePrompt } from './components/CookiePrompt/CookiePrompt';
import { SiteConfigurationProvider } from './contexts/siteConfigurationContext';
import { ScrollTrigger } from "./gsap";
import { GlobalModalStateProvider } from './components/Modal/GlobalModalProvider';



if (typeof window !== 'undefined' && window.history) {
  // Reset the state of the history object on page load.
  delete window.history.state?.usr?.from;
}


function App() {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, []);

  const { mode } = usePageTransition();

  const { data: siteConfigurationContextValue } = useRequest(SiteConfigurationAPI.get);

  const location = useLocation();
  const isMobileVideo = useMedia(["(max-width: 1080px)"], [true], false);
  const videoRef = useRef();
  const [shouldRenderCookiePrompt, setShouldRenderCookiePrompt] = useState(() => (
    'cookie' in window && !window.cookie('consent')
  ));

  const routeOptions = useMemo(() => (
    getRouteObject(location.pathname)?.options ?? {}
  ), [location.pathname]);

  const Video = useMemo(() => {
    if (!routeOptions?.withVideoBackground) return;
    const videoProps = {
      autoPlay: true,
      cover: isMobileVideo ? null : cover,
      key: isMobileVideo ? mobileVideo : video,
      playsInline: true,
      loop: true,
      muted: true,
      src: isMobileVideo ? mobileVideo : video,
      style: {
        zIndex: -1
      }
    };

    if (isMobileVideo) {
      return (
        <div
          className="backgroundHolder"
          dangerouslySetInnerHTML={{
            __html: `

            <video
              src="${mobileVideo}"
              loop
              muted
              playsInline
              autoPlay
            >
            </video>
            <div className="overlay"></div>
            `,
          }}
        />
      );
    }

    return (
      <div className="backgroundHolder">
        <motion.video
          {...videoProps}
          ref={videoRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        // onPlaying={playingHandler}
        />
        <div className="overlay"></div>
      </div>
    );
  }, [isMobileVideo, routeOptions?.withVideoBackground]);

  const handleOnExitComplete = useCallback(() => {
  }, []);

  const { run: setViewportVariable } = useThrottleFn(
    useCallback(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, []),
    {
      wait: 150,
    }
  );

  useEventListener("resize", setViewportVariable);

  useEffect(() => {
    setViewportVariable();
  }, [setViewportVariable]);

  useEffect(() => {
    console.log(
      `Route from: ${location.state?.from} to: ${location.pathname}\t mode: ${mode}`
    );
  }, [location.pathname, location.state?.from, mode]);

  useEffect(() => {
    if ('cookie' in window && 'ga' in window && window.cookie('consent') === 'yes') {
      window.ga('send', 'pageview');
    }
  }, [location.pathname]);

  return (
    <SiteConfigurationProvider value={siteConfigurationContextValue}>
      <ModalProvider rootComponent={AnimatePresence}>
        <GlobalModalStateProvider>
          <VideoBackgroundProvider ref={videoRef}>
            <SiteLayout>
              {Video}
              <AnimatePresence
                initial="exit"
                mode={mode}
                onExitComplete={handleOnExitComplete}
              >
                <Routes
                  key={location.pathname}
                  location={location}
                >
                  {getRegisteredRoutes().map(({ route, component: Component, options }) => (
                    <Route
                      key={options.key}
                      element={(
                        <Component
                          key={options.key}
                          routeOptions={options}
                        />
                      )}
                      path={route}
                    />
                  ))}
                  <Route
                    key="not_found"
                    path="*"
                    element={(
                      <Navigate to="/" />
                    )}
                  />
                </Routes>
              </AnimatePresence>

              <AnimatePresence>
                {shouldRenderCookiePrompt && (
                  <CookiePrompt onResolved={() => setShouldRenderCookiePrompt(false)} />
                )}
              </AnimatePresence>
            </SiteLayout>
          </VideoBackgroundProvider>
        </GlobalModalStateProvider>
      </ModalProvider>
    </SiteConfigurationProvider>
  );
}

export default App;
