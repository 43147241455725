import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";

import { gsap } from "../../../gsap";

const Wrapper = styled.div`
  overflow: hidden;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  box-sizing: border-box;
`;

const Placeholder = styled.div`
  background-color: rgb(215 215 215);
`;

const Image = (props) => {
  let el = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (props.parallax) {
        const tl = gsap.timeline({
          defaults: {
            ease: "none",
          },
          scrollTrigger: {
            trigger: el.current,
            start: "clamp(top bottom)",
            end: "bottom top",
            scrub: true,
          },
        });

        gsap.set(".img", {
          yPercent: -15,
        });

        tl.to(".img", {
          yPercent: 15,
        });
      }

      if (props.noAnimated) return;
      if (!props.animateMask) {
        gsap.from(el.current, {
          delay: props.delay || 0,
          y: 35,
          ease: "customEase2",
          scrollTrigger: {
            trigger: el.current,
          },
        });
      } else {
        const tl = gsap.timeline({
          delay: props.delay || 0,
          defaults: {
            duration: gsap.defaults().duration * 2,
            ease: "customEase2",
          },
          scrollTrigger: {
            trigger: el.current,
            start: "top-=15% bottom",
            end: "bottom top",
            scrub: true,
          },
        });

        tl.from(el.current, {
          yPercent: 10,
          scale: 0.85,
        });

        tl.from(
          ".img",
          {
            scale: 1.15,
          },
          0
        );

        tl.to(
          ".img",
          {
            yPercent: 15,
            ease: "none",
          },
          "<=80%"
        );
      }
    }, el);

    return () => ctx.revert();
  }, [props.animateMask, props.delay, props.noAnimated, props.parallax]);

  return (
    <Wrapper
      margin={props.margin}
      padding={props.padding}
      className={props.className}
    >
      <div
        ref={el}
        width={props.width}
        {...(props.noAspect ? {} : {style: {"--aspect": props.aspect || "16/9"}})}
        parallax={props.parallax}
      >
        {props.src ? (
          <img
            className="img"
            src={props.src}
            alt={props.alt}
            loading={props.loading ?? "lazy"}
            draggable="false"
          />
        ) : (
          <Placeholder />
        )}
      </div>
    </Wrapper>
  );
};

export default Image;
