import PropTypes from 'prop-types';

export const ContextMenuItemActionProps = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['danger']),
  onClick: PropTypes.func
};

export const ContextMenuItemActionDefaultProps = {
  className: null,
  disabled: false,
  theme: null,
  onClick: null
};

export const ContextMenuItemModalActionProps = {
  ...ContextMenuItemActionProps,
  modalProps: PropTypes.object,
  modalType: PropTypes.elementType.isRequired
};

export const ContextMenuItemActionModalDefaultProps = {
  ...ContextMenuItemActionDefaultProps,
  modalProps: {}
};
