import React from "react";
import styled from "styled-components";
import ImageRegular from "./ImageRegular";

const Wrapper = styled.div``;

const ImageCenter = (props) => {
  return (
    <Wrapper
      className={props.className}
      style={{ "--aspect": props.aspect || "16/9" }}
    >
      <ImageRegular src={props.src} alt={props.alt} />
    </Wrapper>
  );
};

export default ImageCenter;
