import PropTypes from 'prop-types';

export const OverlayMaskPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dimmerAnimationProps: PropTypes.object,
  invisible: PropTypes.bool,
  noPointerEvents: PropTypes.bool,
  onClick: PropTypes.func
};

export const OverlayMaskDefaultProps = {
  block: true,
  className: null,
  dimmerAnimationProps: {},
  invisible: false,
  noPointerEvents: false,
  onClick: null
};
