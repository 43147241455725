/**
 * @param {number|number[]} delay
 * @param {boolean} [same=false]
 * @returns {number[]}
 */
export function useNormalizeValue(delay = 0, same = false) {
  if (Array.isArray(delay) && delay.length > 1) {
    const [enter, exit] = delay;

    return [enter, exit];
  }

  return [delay, same ? delay : 0];
}
