import classnames from 'classnames';
import { motion, usePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFullScreen } from 'react-recipes';
import { Easings } from '../../animation';
import { useIsMobile } from '../../hooks/is-mobile';
import { useCursor } from '../Cursor/hooks';
import PointerTypes from '../Cursor/types';

function Content({
  body,
  hideModal,
  onBeforeClose,
  onBeforeOpen,
  onClose,
  onOpen
}) {
  const isMobile = useIsMobile();
  const [isPresent, safeToRemove] = usePresence();
  const [isOpen, setOpen] = useState(false);
  const [isClosing, setClosing] = useState(false);
  const { open, close } = useFullScreen();

  const { bind, setType } = useCursor();
  const handlers = bind({
    options: {
      label: 'CLOSE'
    },
    type: PointerTypes.Large
  });

  const variants = useMemo(() => {
    if (isMobile.phone) {
      return {};
    }

    return {
      letterbox: {
        open: (position) => {
          return {
            y: `${position}%`,
            transition: {
              duration: 1.5,
              ease: Easings.brunoIn
            }
          };
        },
        closed: () => ({
          y: 0,
          transition: {
            duration: 1.5,
            ease: Easings.brunoOut
          }
        })
      },
      backdrop: {
        open: {
          opacity: 1,
          transition: {
            duration: 0.25,
            ease: 'easeOut'
          }
        },
        closed: {
          opacity: 0,
          transition: {
            duration: 0.25,
            delay: 1.3,
            ease: 'easeInOut'
          }
        }
      },
      content: {
        open: {
          scale: 1,
          transition: {
            delay: 0.5,
            ease: Easings.brunoIn,
            duration: 1.5
          }
        },
        closed: {
          scale: 0.55,
          transition: {
            ease: Easings.brunoOut,
            duration: 1.5
          }
        }
      }
    };
  }, [isMobile.phone]);

  const Component = useMemo(() => {
    return React.createElement(body, {
      isClosing,
      isOpen
    })
  }, [body, isClosing, isOpen]);

  const handleShowModal = useCallback(() => {
    if (!isClosing && !isOpen) {
      if (isMobile.phone) {
        open();
      }

      setOpen(true);
      onOpen();
    }
    else {
      onClose();
    }
  }, [isClosing, isOpen, isMobile.phone, open, onOpen, onClose]);

  const handleHideModal = useCallback(() => {
    if (!isClosing) {
      setOpen(false);
      setClosing(true);
      hideModal();
      setType(PointerTypes.Default);

      if (isMobile.phone) {
        close();
      }

      onBeforeClose();
    }
  }, [close, hideModal, isMobile.phone, setType, isClosing, onBeforeClose]);

  useEffect(() => {
    if (!isPresent && safeToRemove) {
      safeToRemove();
    }
  }, [safeToRemove, isPresent]);

  useEffect(() => {
    onBeforeOpen();
  }, [onBeforeOpen]);

  return (
    <motion.div
      {...handlers}
      className={classnames([
        'Modal',
        {
          'Modal--fullscreen': isMobile.phone
        }
      ])}
      initial="closed"
      animate="open"
      exit="closed"
      variants={variants.backdrop}
      onClick={handleHideModal}
    >
      <motion.div
        animate="open"
        className="Modal__content"
        exit="closed"
        initial="closed"
        variants={variants.content}
        onAnimationStart={handleShowModal}
      >
        {Component}
      </motion.div>

      {!isMobile.phone && (
        <>
          <motion.div
            className="Modal__letterbox letterbox-1"
            custom={-100}
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants.letterbox}
          />
          <motion.div
            className="Modal__letterbox letterbox-2"
            custom={100}
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants.letterbox}
          />
        </>
      )}
    </motion.div>
  );
}

Content.propTypes = {
  body: PropTypes.elementType.isRequired,
  hideModal: PropTypes.func.isRequired,
  onBeforeClose: PropTypes.func,
  onBeforeOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

Content.defaultProps = {
  onBeforeClose: () => { },
  onBeforeOpen: () => { },
  onClose: () => { },
  onOpen: () => { }
};

export default Content;
