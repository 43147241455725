import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import IconInfo from "../Icon/Info";
import pxToRem from "../../Utils/pxToRem";
import ImageRegular from "./ImageRegular";

import { gsap } from "../../../gsap";

const Wrapper = styled.div`
  position: relative;

  &:hover {
    .image-1 {
      opacity: 0;
    }

    .image-2 {
      opacity: 1;
    }

    .image-icon {
      svg {
        circle {
          fill: currentColor;
          stroke: currentColor;
        }

        path {
          fill: #ffffff;
        }
      }
    }
  }

  .image-icon {
    position: absolute;
    top: ${pxToRem(24)};
    left: ${pxToRem(24)};
    z-index: 1;

    @media (max-width: 620px) {
      top: ${pxToRem(10)};
      left: ${pxToRem(10)};
    }
  }

  .image-1,
  .image-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }

  .image-2 {
    opacity: 0;
  }

  .image-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ImageInfo = (props) => {
  let el = useRef();

  useLayoutEffect(() => {
    if (props.noAnimated) return;
    let ctx = gsap.context(() => {
      if (!props.animateMask) {
        gsap.from(el.current, {
          delay: props.delay || 0,
          y: 35,
          ease: "customEase2",
          scrollTrigger: {
            trigger: el.current,
          },
        });
      } else {
        const tl = gsap.timeline({
          delay: props.delay || 0,
          defaults: {
            duration: gsap.defaults().duration * 2,
            ease: "customEase2",
          },
          scrollTrigger: {
            trigger: el.current,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
          },
        });

        tl.from(el.current, {
          yPercent: 10,
          scale: 0.85,
        });

        tl.from(
          ".img",
          {
            scale: 1.15,
          },
          0
        );

        tl.to(
          ".img",
          {
            yPercent: 15,
            ease: "none",
          },
          "<=80%"
        );
      }
    }, el);

    return () => ctx.revert();
  }, [props.animateMask, props.delay, props.noAnimated]);

  return (
    <Wrapper ref={el} className={props.className}>
      <IconInfo
        className="image-icon"
        widthM="12px"
        heightM="12px"
        enableHover={true}
      />
      <div style={{ "--aspect": props.aspect || "16/9" }}>
        <ImageRegular className="image-1" src={props.src} alt={props.alt} />
      </div>
      <div className="image-absolute" style={{ "--aspect": props.aspect || "16/9" }}>
        <ImageRegular className="image-2" src={props.src2} alt={props.alt2} />
      </div>
    </Wrapper>
  );
};

export default ImageInfo;
