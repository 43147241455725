import React from 'react';
import styled from 'styled-components';
import pxToRem from '../../Utils/pxToRem';

const Text = styled.span`
  font-family: "Helvetica Now Display", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(40)};
  letter-spacing: 0.07em;
  line-height: 1.4;
  text-transform: ${(props) => props.transform || 'uppercase'};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
`;

const Label2 = (props) => {
  return (
    <Text className={props.className} color={props.color} align={props.align} transform={props.transform} margin={props.margin}>{props.text}</Text>
  );
};

export default Label2;
