import React, { useRef, useEffect } from 'react';
import styled from "styled-components";
import { useInView } from "framer-motion";
import lottie from 'lottie-web';

const Wrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  transform: ${(props) => props.transform};
`;

const Lottie = (props) => {
  const container = useRef(null);
  const animation = useRef(null);
  const isInView = useInView(container, { margin: "100% 0px"});

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: props.loop || true,
      autoplay: props.autoplay || true,
      animationData: props.src,
    });

    return () => animation.current.destroy();
  }, [props.autoplay, props.loop, props.src]);

  useEffect(() => {
    if (isInView) {
      animation.current.play();
    } else {
      animation.current.pause();
    }
  }, [isInView]);

  return <Wrapper ref={container} className={props.className} transform={props.transform} backgroundColor={props.backgroundColor} style={{ "--aspect": props.aspect }} />;
};

export default Lottie;
