import React, { useRef, useState, useEffect } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";
import { Durations, Easings } from "../../animation";
import { ListItem } from "../../components/Testimonial";
import ButtonCursor from "./ButtonCursor";

import { gsap } from "../../gsap";

const List = styled.ul`
  list-style: none;
  padding: ${(props) => props.padding};
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  pointer-events: ${(props) => (props.nodetail ? "none" : "auto")};

  .testimonials {
    padding: 11rem;
    margin: -11rem;

    .testimonials__name {
      font-family: "Suisse Intl", sans-serif;
      font-weight: 100;
      font-style: italic;
      font-size: ${pxToRem(57)};
      letter-spacing: 0em;
      line-height: 1.2;
      padding: 0;
      text-transform: none;
      text-align: center;

      &::after {
        display: none;
      }

      @media screen and (max-width: 620px) {
        font-size: ${pxToRem(28)};
        letter-spacing: 0em;
        line-height: 1.1;
      }
    }
  }
`;

const Testimonial = (props) => {
  const [firstEnter, setFirstEnter] = useState(false);

  const el = useRef();
  const elCursor = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  useEffect(() => {
    if (!elCursor.current) return;
    if (isMobile) return;

    const handleMouseMove = (event) => {
      if (isMobile) return;

      const elRect = el.current.getBoundingClientRect();
      const cursorRect = elCursor.current.getBoundingClientRect();

      const x = event.clientX - elRect.left - cursorRect.width / 2;
      const y = event.clientY - elRect.top - cursorRect.height / 2;

      if (!firstEnter) {
        gsap.set(elCursor.current, {
          left: x,
          top: y,
        });

        setFirstEnter(true);
      }

      gsap.to(elCursor.current, {
        scale: 1,
        left: x,
        top: y,
        duration: 0.6,
        delay: 0.01,
        ease: "power4.out",
      });
    };

    const handleMouseClick = () => {
      if (isMobile) return;

      const tl = gsap.timeline({
        defaults: {
          duration: 0.3,
          ease: "power2.out",
        },
      });

      tl.to(elCursor.current, {
        scale: 0.9,
      });

      tl.to(elCursor.current, {
        scale: 1,
      });
    };

    const handleMouseLeave = () => {
      if (isMobile) return;
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.6,
        overwrite: true,
        ease: "power4.out",
        onComplete: () => {
          setFirstEnter(false);
        },
      });
    };

    const currentEl = el.current;

    currentEl.addEventListener("mousemove", handleMouseMove);
    currentEl.addEventListener("mouseleave", handleMouseLeave);
    currentEl.addEventListener("click", handleMouseClick);

    return () => {
      if (currentEl) {
        currentEl.removeEventListener("mousemove", handleMouseMove);
        currentEl.removeEventListener("mouseleave", handleMouseLeave);
        currentEl.removeEventListener("click", handleMouseClick);
      }
    };
  }, [isMobile, firstEnter]);

  return (
    <List ref={el} padding={props.padding} nodetail={props.nodetail}>
      {!props.nodetail && (
        <ButtonCursor
          ref={elCursor}
          text="Read Review"
          fontSize={isMobile ? pxToRem(7.6) : pxToRem(10)}
          font
          size={pxToRem(134)}
          sizeMobile={pxToRem(93)}
          topMobile="70%"
          leftMobile="85%"
          bgColor={props.cursorBgColor}
          textColor={props.cursorTextColor}
        />
      )}
      {props.items.map((item, key, c) => (
        <ListItem
          key={key}
          appearMotionPropVariants={{
            enter: () => ({
              y: "0%",
              transition: {
                delay: key * Durations.gap,
                duration: Durations.base,
                ease: Easings.easeOutCubic,
              },
            }),
            exit: () => ({
              y: "100%",
              transition: {
                delay: (c.length - key) * Durations.gap,
                duration: Durations.base,
                ease: Easings.easeInCubic,
              },
            }),
          }}
          idx={key + 1}
          listItemData={item}
        />
      ))}
    </List>
  );
};

export default Testimonial;
