import classnames from 'classnames';
import Color from 'color';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavigateBackButton.scss';

export const sizeToClassNameMap = {
  m: 'NavigateBackButton--medium',
  l: 'NavigateBackButton--large'
}

const BackgroundColor = Color('#2b292c');

function NavigateBackButton({
  className,
  size
}) {
  const location = useLocation();
  const [isHover, setHover] = useState(false);
  const animate = isHover ? 'hover' : 'normal';
  const from = location.state?.from ?? '/';

  const routerState = useMemo(() => ({
    from: location.pathname
  }), [location.pathname]);

  const variants = useMemo(() => {
    return {
      arrow: {
        normal: {},
        hover: {
          x: ['0%', '-100%', '100%', '0%'],
          transition: {
            x: {
              duration: 0.48,
              ease: 'easeInOut',
              times: [0, 0.5, 0.5, 1]
            }
          }
        }
      },
      backdrop: {
        normal: {
          backgroundColor: BackgroundColor.hex()
        },
        hover: {
          backgroundColor: BackgroundColor.darken(0.25).hex()
        }
      }
    };
  }, []);

  const handleHover = useCallback((isHover) => () => {
    setHover(isHover)
  }, []);

  return (
    <motion.div
      className={classnames([
        'NavigateBackButton',
        sizeToClassNameMap[size],
        className
      ])}
      animate={animate}
      transition={{ duration: 0.24, ease: 'easeInOut' }}
      variants={variants.backdrop}
      // onClick={handleBack}
      onHoverEnd={handleHover(false)}
      onHoverStart={handleHover(true)}
    >
      <Link to={from} state={routerState} className="NavigateBackButton__link">
        <div style={{ overflow: 'hidden' }}>
          <motion.div
            className="NavigateBackButton__arrow"
            variants={variants.arrow}
          >

            ←
          </motion.div>
        </div>
      </Link>
    </motion.div >
  );
}

NavigateBackButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizeToClassNameMap))
};

NavigateBackButton.defaultProps = {
  size: 'm'
};


export default NavigateBackButton;
