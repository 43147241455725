import React from 'react';
import { useCurrentTime } from '../hooks';
import { Analog, Digital } from './Clock';
import './Time.scss';

function Time({className}) {
  const time = useCurrentTime();

  return (
    <div className={"Time " + className}  >
      <div className="Time__label">
        Local Time
      </div>

      <div className="Time__separator" />

      <div className="Time__digital">
        <Digital time={time} />
      </div>

      <div className="Time__analog">
        <Analog time={time} />
      </div>
    </div>
  );
}

export default Time;
