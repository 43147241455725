import React from "react";
import MainFooter from "./MainFooter";
import SiteHeader from "./SiteHeader";
// import { useMedia } from "react-recipes";

export default function SiteLayout({ children }) {
  // const isXsDevice = useMedia(["(max-width: 320px"], [true], false);

  return (
    <>
      {/* {isXsDevice && (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <a
            href="https://www.apple.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Need a new phone?
          </a>
        </div>
      )} */}
      {/* {!isXsDevice && ( */}
        <>
          <SiteHeader />
          <div className="clone-transition" data-flip-id="fullscreen"></div>
          {children}
          <MainFooter />
        </>
      {/* )} */}
    </>
  );
}
