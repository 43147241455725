import { useMount, useUnmount } from 'ahooks';
import axios from 'axios';
import { useReducerAsync } from 'use-reducer-async';

/**
 * @typedef {object} ResourceLoaderState
 * @property {boolean} loading
 * @property {string[]} resources
 */

/** @type {ResourceLoaderState} */
const initialState = {
  loading: true,
  resources: []
};

const resourceLoaderReducer = (state, action) => {
  switch (action.type) {
    case 'BEGIN_LOAD':
      return { ...state, loading: true };

    case 'END_LOAD':
      return { ...state, loading: false, resources: action.resources };

    case 'DISPOSE':
      state.resources.forEach(URL.revokeObjectURL);
      return { ...state, resources: [] };

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const loadResource = async (resource) => axios.get(resource, { responseType: 'blob' });

const loadResources = async (resources) => {
  return (await Promise.allSettled(resources.map(loadResource)))
    .filter((result) => result.status === 'fulfilled')
    .map((result) => URL.createObjectURL(result.value.data));
};

const asyncHandlers = {
  LOAD: ({ dispatch }) => async (action) => {
    dispatch({ type: 'BEGIN_LOAD', loading: true });
    const result = await loadResources(action.resources);

    dispatch({ type: 'END_LOAD', resources: result });
  }
};

/** @type {(resources: string[]) => ResourceLoaderState} */
export const useResourceLoader = (resources) => {
  const [state, dispatch] = useReducerAsync(resourceLoaderReducer, initialState, asyncHandlers);

  useMount(() => {
    dispatch({ type: 'LOAD', resources });
  });

  useUnmount(() => {
    dispatch({ type: 'DISPOSE' });
  });

  return state;
};
