import React from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";

const Icon = styled.div`
  font-family: "Helvetica Now Display", sans-serif;
  font-weight: 300;
  font-size: ${(props) => props.size || "1em"};
  line-height: 0;
  display: inline-block;
  text-align: ${(props) => props.align};
  color: ${(props) => props.color || "currentColor"};
  margin: ${(props) => props.margin || "0 0 0 0"};
  padding-top: ${(props) => !props.centerPadding || pxToRem(4)};

  @media (max-width: 620px) {
    padding-top: ${(props) => !props.centerPadding || "3px"};
  }

  svg {
    width: ${(props) => props.width || "13px"};
    height: ${(props) => props.height || "13px"};

    @media (max-width: 1500px) {
      width: ${(props) => props.width || "10px"};
      height: ${(props) => props.height || "10px"};
    }

    @media (max-width: 620px) {
      width: ${(props) => props.widthM || "9px"};
      height: ${(props) => props.heightM || "9px"};
    }

    circle {
      stroke: currentColor;
      transition: fill 0.3s ease, stroke 0.3s ease;
      transition-delay: 0.15s;
    }

    path {
      fill: currentColor;
      transition: fill 0.3s ease;
    }
  }

  &:hover {
    /* if props.enableHover true */
    ${(props) =>
      props.enableHover &&
      `
    svg {
      circle {
        fill: currentColor;
        stroke: currentColor;
      }

      path {
        fill: #ffffff;
      }
    }
    `}
  }
`;

const IconInfo = (props) => {
  return (
    <Icon
      className={props.className}
      size={props.size}
      color={props.color}
      align={props.align}
      margin={props.margin}
      centerPadding={props.centerPadding}
      width={props.width}
      height={props.height}
      widthM={props.widthM}
      heightM={props.heightM}
      enableHover={props.enableHover}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7.5" cy="7.5" r="7" stroke={props.color} />
        <path
          d="M8.0767 4.51085V3.46094H6.92285V4.51085H8.0767ZM8.0767 11.5379V6.06557H6.92285V11.5379H8.0767Z"
          fill={props.color}
        />
      </svg>
    </Icon>
  );
};

export default IconInfo;
