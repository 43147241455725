import { useEventListener } from 'ahooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Simplebar from 'simplebar-react';
import './Container.scss';
import { ContainerProvider } from './ContainerProvider';

export const sizeToClassNameMap = {
  full: null,
  m: 'Container--medium',
  l: 'Container--large'
}

/**
 *
 * @param {import('react').MutableRefObject<any>} ref
 * @param {function).Dispatch} set
 */
function setObjectBounds(ref, set) {
  if (ref.current instanceof Element) {
    set(ref.current.getBoundingClientRect());
  }
  else if (ref.current?.contentWrapperEl && ref.current.contentWrapperEl instanceof Element) {
    set(ref.current.contentWrapperEl.getBoundingClientRect());
  }
}

const DefaultBounds = {
  bottom: 0,
  height: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0
};

/**
 * @typedef {object} ContainerProps
 * @property {string} [className]
 * @property {boolean} [centered=true]
 * @property {boolean} [frame=false]
 * @property {keyof typeof sizeToClassNameMap} [size='m']
 * @property {import('react').CSSProperties} [style]
 * @property {import('react').MutableRefObject<HTMLDivElement} ref
 */

/**
 * @type {import('react').ForwardRefRenderFunction<HTMLElement, ContainerProps>}
 */
const Container = React.forwardRef((
  {
    children,
    centered,
    className,
    frame,
    size,
    style
  },
  inRef
) => {
  /** @type {import('react').MutableRefObject<any>} */
  const ref = useRef();

  const el = frame ? Simplebar : 'div';
  const [bounds, setBounds] = useState(DefaultBounds);

  const captureRef = useCallback((node) => {
    ref.current = node?.el ?? node;

    if (inRef && Object.prototype.hasOwnProperty.call(inRef, 'current')) {
      inRef.current = ref.current;
      return;
    }

    if (inRef instanceof Function) {
      inRef(node);
    }
  }, [inRef]);

  const props = useMemo(() => {
    if (el?.displayName === 'SimpleBar') {
      return {
        ref: captureRef,
        scrollableNodeProps: { ref: captureRef }
      };
    }

    return { ref: captureRef };
  }, [captureRef, el]);

  const handleResize = useCallback(() => {
    setObjectBounds(ref, setBounds);
  }, []);

  useEffect(() => {
    setObjectBounds(ref, setBounds);
  }, []);

  useEventListener(
    'resize',
    handleResize,
    {
      target: window
    }
  );

  return (
    <ContainerProvider
      bounds={bounds}
    >
      {React.createElement(
        el,
        {
          ...props,
          className: classNames([
            'Container',
            {
              [sizeToClassNameMap[size]]: size !== null,
              'Container--centered': centered,
              'Container--frame': frame
            },
            className
          ]),
          style,
          'data-lenis-prevent': true
        },
        children
      )}
    </ContainerProvider>
  );
});

Container.displayName = 'Container';

Container.propTypes = {
  centered: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizeToClassNameMap)),
  frame: PropTypes.bool,
  style: PropTypes.object
};

Container.defaultProps = {
  centered: true,
  frame: false,
  size: 'm'
};

export default Container;
