import React from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";

const Text = styled.h1`
  font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(140)};
  letter-spacing: -0.02857em;
  line-height: 0.7857;
  text-transform: ${(props) => props.transform || "uppercase"};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin || "0"};

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(44)};
    letter-spacing: -0.0295em;
    line-height: 0.81;
  }
`;

const Heading1 = (props) => {
  return (
    <Text
      className={props.className}
      color={props.color}
      align={props.align}
      transform={props.transform}
      margin={props.margin}
    >
      {props.text}
    </Text>
  );
};

export default Heading1;
