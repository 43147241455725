import { motion } from 'framer-motion';
import { merge } from 'lodash';
import React, { useMemo } from 'react';
import { useMedia } from 'react-recipes';
import { Durations } from '../animation';
import svgAddress from '../assets/img/address.svg';
import svgVat from '../assets/img/vat.svg';
import Appear from '../components/Appear';
import Block from '../components/Block/Block';
import { BusinessInquiry } from '../components/BusinessInquiry';
import { EmailInquiryButton, TextButton } from '../components/Button';
import { ClipPathMask } from '../components/ClipPathMask';
import ContactInfo from '../components/ContactInfo';
import Container from '../components/Container';
import { Flex } from '../components/Flex';
import { MaskedReveal } from '../components/Motion';
import Page from '../components/Page';
import { SiteHeaderMain } from '../components/SiteHeader';
import { Icon } from '../components/icons';
import config from '../config.json';
import { useNormalizeValue } from '../hooks';
import { withRegisterRoute } from '../routing';
import classes from './Contact.module.scss';

/**
 * @typedef {Pick<import('framer-motion').TargetAndTransition, 'transition'>} TitleMotionVariant
 */

/**
 * @typedef {object} TitleMotionValues
 * @property {TitleMotionVariant} enter
 * @property {TitleMotionVariant} exit
 */

/**
 * @typedef {object} TitleProps
 * @property {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'} [as='h1']
 * @property {React.ReactNode} children
 * @property {number | [number, number]} [delay=0]
 * @property {TitleMotionValues} [motionValues]
*/

/** @type {TitleMotionValues} */
const defaultTitleMotionValues = {
  enter: {
    transition: {
      staggerChildren: Durations.gap
    }
  },
  exit: {
    transition: {
      staggerChildren: Durations.gap
    }
  }
};

/** @type {import('react').FC<TitleProps & import('react').HTMLAttributes<HTMLHeadingElement>} */
const Title = (props) => {
  const {
    as = 'h1',
    children,
    delay = 0,
    motionValues = {},
    ...restProps
  } = props;

  const [delayEnter, delayExit] = useNormalizeValue(delay, false);

  const variants = useMemo(() => (
    merge(
      {
        enter: {
          transition: {
            delayChildren: delayEnter
          }
        },
        exit: {
          transition: {
            delayChildren: delayExit
          }
        }
      },
      defaultTitleMotionValues,
      motionValues
    )
  ), [delayEnter, delayExit, motionValues]);

  return (
    React.createElement(
      motion[as],
      {
        ...restProps,
        animate: 'enter',
        exit: 'exit',
        initial: 'exit',
        variants
      },
      React.Children.map(children, (child, key) => (
        React.createElement(
          MaskedReveal,
          {
            key,
            auto: true,
            withoutDelay: true
          },
          child.props.children
        )
      ))
    )
  );
}

/** @type {import('framer-motion').Variants} */
const HorizontalSeparatorMotionVariants = {
  enter: ([delay]) => ({
    width: '100%',
    transition: {
      duration: Durations.base,
      delay
    }
  }),
  exit: ([, delay]) => ({
    width: 0,
    transition: {
      duration: Durations.base,
      delay
    }
  })
};

/** @type {import('react').FC<{ delay: number|[number, number]}} */
const HorizontalSeparator = (props) => {
  const delay = useNormalizeValue(props.delay ?? 0);

  return (
    <motion.div
      animate="enter"
      className={classes.horizontalSeparator}
      custom={delay}
      exit="exit"
      initial="exit"
      variants={HorizontalSeparatorMotionVariants}
    />
  )
}

function Contact() {
  const isMobile = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  const shouldRenderInHeader = useMedia(
    ['(min-width: 1024px)'],
    [true],
    false
  );

  const shouldRenderSeparators = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  return (
    <Page>
      <Container
        className={classes.container}
        frame
      >
        {shouldRenderInHeader && (
          <Appear>
            <SiteHeaderMain.Source>
              <ContactInfo />
            </SiteHeaderMain.Source>
          </Appear>
        )}

        <Flex
          className={classes.content}
          direction="column"
          justifyContent="center"
        >
          <BusinessInquiry
            className={classes.businessInquiry}
            delay={0.5}
            withProgress
          />

          <Flex direction="column">
            {!isMobile && (
              <Title className={classes.title} delay={0.2}>
                <div>Take your</div>
                <div>story further</div>
              </Title>
            )}

            {isMobile && (
              <Title as="h2" className={classes.title}>
                <div>Take your</div>
                <div>story</div>
                <div>further</div>
              </Title>
            )}

            {!isMobile && (
              <Title as="h2" className={classes.subtitle} delay={1.2}>
                <span>Before we even begin talking about story, we have to establish a firm grasp of who your are as a brand — your ambitions and expectations. Together</span>
                <span>we'll assess where you are, how far you’ve come, and where you wish to go. Feel free to schedule a meeting or send us an email.</span>
              </Title>
            )}

            {isMobile && (
              <Title as="h2" className={classes.subtitle} delay={1}>
                <span>Before even begin talking about story, we have </span>
                <span>to establish a firm grasp of who your are as a</span>
                <span>brand - your ambitions and expectations. </span>
                <span>Together we'll assess where you are, how far</span>
                <span>you’ve come, and where you wish to go. Feel</span>
                <span>free to schedule a meeting or send us an email.</span>
              </Title>
            )}

            <Flex
              className={classes.buttons}
              justifyContent="center"
            >
              <MaskedReveal delay={isMobile ? 1.4 : 1.4}>
                <EmailInquiryButton email="hello@fiftyseven.co" />
              </MaskedReveal>

              <MaskedReveal as={Flex} delay={isMobile ? 1.6 : 1.6}>
                <div className={classes.buttonsDivider} />
              </MaskedReveal>

              <MaskedReveal delay={isMobile ? 1.8 : 1.8}>
                <TextButton
                  asLink
                  icon={(
                    <Icon type="linkFlip" />
                  )}
                  href="https://calendly.com/fiftyseven/introductory-meeting"
                  target="_blank"
                >
                  Book Call
                </TextButton>
              </MaskedReveal>
            </Flex>
          </Flex>
        </Flex>

        {!shouldRenderInHeader && (
          <Flex direction="column">
            {shouldRenderSeparators && <HorizontalSeparator />}

            <Appear style={{ width: '100%' }}>
              <ContactInfo />
            </Appear>

            {shouldRenderSeparators && <HorizontalSeparator />}
          </Flex>
        )}

        <Flex
          alignItems="flex-end"
          className={classes.containerBottom}
          justifyContent="space-between"
        >
          <Flex className={classes.blocks}>
            <Appear>
              <Block
                className={classes.block}
                delay={isMobile ? 0 : [1.4, 0.07]}
                text={[
                  'We’re always looking for talent',
                  'with integrity. <a href="mailto:hello@fiftyseven.co" target="_blank" rel="noopener noreferrer">Send us your CV</a>'
                ]}
                title="BECOME A PART OUR STORY"
              />
            </Appear>

            <Appear>
              <Block
                className={classes.block}
                delay={isMobile ? 0.2 : [1.6, 0.07]}
                text={[
                  `<a href="${config.mailchimp}" target="_blank" rel="noopener noreferrer">Subscribe</a> to our newsletter for insight into `,
                  'our process and what’s next for FIFTYSEVEN.'
                ]}
                title="KEEP UP WITH OUR STORY"
              />
            </Appear>
          </Flex>

          {isMobile && (
            <HorizontalSeparator />
          )}

          <Flex className={classes.images}>
            <Appear>
              <ClipPathMask maskDirection="bottom" delay={isMobile ? 0 : 2.2}>
                <img
                  alt=""
                  className={classes.image}
                  draggable={false}
                  src={svgVat}
                />
              </ClipPathMask>
            </Appear>

            <Appear>
              <ClipPathMask maskDirection="bottom" delay={isMobile ? 0.2 : 2.4}>
                <img
                  alt=""
                  className={classes.image}
                  draggable={false}
                  src={svgAddress}
                />
              </ClipPathMask>
            </Appear>
          </Flex>
        </Flex>
      </Container>
    </Page>
  )
}

export default withRegisterRoute(Contact, {
  route: '/contact',
  name: 'Contact',
  weight: 30,
  options: {
    navBackOptions: { mobile: true }
  }
});
