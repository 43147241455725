import { useMouse } from 'ahooks';
import { motion, useSpring } from 'framer-motion';
import React, { useCallback, useEffect, useRef } from 'react';

/**
 * @typedef {object} PointerMinionProps
 * @property {function} children
 * @property {import('react').ForwardedRef<HTMLElement>} boundingElement
 * @property {number | (rect: DOMRect) => number} [offsetX=0]
 * @property {number | (rect: DOMRect) => number} [offsetY=0]
 * @property {number} [damping=200]
 * @property {number} [stiffness=1500]
 */

/** @type {import('react').FC<PointerMinionProps>} */
export const PointerMinion = (props) => {
  const {
    boundingElement,
    children,
    damping = 200,
    offsetX = 0,
    offsetY = 0,
    stiffness = 1500
  } = props;
  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const elRef= useRef();

  /** @type {DOMRect} */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rect = boundingElement.current?.getBoundingClientRect() ?? { x: 0, y: 0, width: 0, height: 0 };

  const { clientX, clientY } = useMouse();
  const x = Number.isFinite(clientX) ? clientX : -1;
  const y = Number.isFinite(clientY) ? clientY : -1;

  const mX = useSpring(x, { damping, stiffness });
  const mY = useSpring(y, { damping, stiffness });

  const renderFn = useCallback(() => {
    return children(elRef);
  }, [children]);

  useEffect(() => {
    const elRect = elRef.current?.getBoundingClientRect() ?? { width: 0, height: 0 };
    // const yShift = y - rect.y > rect.height / 2 ? 1 : -1;

    mX.set(x - rect.x + offsetX - elRect.width / 2);
    mY.set((y - rect.y) + elRect.height + offsetY);
    // mY.set(y - rect.y - Math.abs(~(yShift >> 1)) * elRect.height / 2 + offsetY * yShift);
  }, [mX, mY, offsetX, offsetY, rect, x, y]);

  return (
    <motion.div
      style={{
        x: mX,
        y: mY,
        position: 'absolute',
        visibility: x !== -1 && y !== -1 ? 'visible' : 'hidden'
      }}
    >
      {renderFn()}
    </motion.div>
  )
};

PointerMinion.displayName = 'PointerMinion';
