import React from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import AnimTextPerline from "../Motion/Textperline";

const Text = styled.h4`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(27)};
  letter-spacing: 0em;
  line-height: 1.11;
  text-transform: ${(props) => props.transform || "uppercase"};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin || "0 0 1.125rem 0"};

  /* &:not(:last-child) {
    margin: ${(props) => props.margin || "0 0 1.125rem 0"};
  } */

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(24)};
    letter-spacing: 0em;
    line-height: 1.083;
  }
`;

const Heading3 = (props) => {
  return (
    
      <Text
        className={props.className}
        color={props.color}
        align={props.align}
        transform={props.transform}
        margin={props.margin}
      >
        <AnimTextPerline>
          <span>
        {props.text}
        </span>
        </AnimTextPerline>
      </Text>
  );
};

export default Heading3;
