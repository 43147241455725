import { useMedia } from 'react-recipes';
import { Durations, Easings } from '../animation';
import Appear from '../components/Appear';
import Container from '../components/Container';
import { List } from '../components/List/index';
import { ListItem, ListItemMobile } from '../components/Testimonial';

import { useIsPresent } from 'framer-motion';
import { useGlobalModalState } from '../components/Modal/globalModalStateContext';
import { withRegisterRoute } from '../routing';
import { data } from '../testimonials';
import classes from './Testimonials.module.scss';
import Page from '../components/Page';

function Testimonials() {
  const isPresent = useIsPresent();
  const { open } = useGlobalModalState();
  const shouldRender = isPresent || (!isPresent && !open);

  const isMobile = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  return (
    <Page>
      <Container
        className={classes.container}
        frame
      >
        {shouldRender && isMobile && (
          <List
            className={classes.mobileList}
            direction="column"
            listItemProps={{
              className: classes.mobileListItem
            }}
          >
            {data.map((item, key) => (
              <Appear
                key={key}
                threshold={0.5}
              >
                <ListItemMobile
                  idx={key + 1}
                  listItemData={item}
                />
              </Appear>
            ))}
          </List>
        )}

        {shouldRender && !isMobile && (
          <List
            className={classes.list}
            direction="column"
            justifyContent="center"
          >
            {data.map((item, key, c) => (
              <Appear
                key={key}
              >
                <ListItem
                  appearMotionPropVariants={{
                    enter: () => ({
                      y: '0%',
                      transition: {
                        delay: key * Durations.gap,
                        duration: Durations.base,
                        ease: Easings.easeOutCubic
                      }
                    }),
                    exit: () => ({
                      y: '100%',
                      transition: {
                        delay: (c.length - key) * Durations.gap,
                        duration: Durations.base,
                        ease: Easings.easeInCubic
                      }
                    })
                  }
                  }
                  idx={key + 1}
                  listItemData={item}
                  withHover
                  appearAnimation
                />
              </Appear>
            ))}
          </List>
        )}
      </Container>
    </Page>
  );
}

export default withRegisterRoute(Testimonials, {
  route: '/clients',
  name: 'Clients',
  weight: 10
});
