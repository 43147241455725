import classNames from 'classnames';
import React from 'react';
import classes from './ContextMenu.module.scss';
import { ContextMenuItemActionDefaultProps, ContextMenuItemActionProps } from './props';

function ContextMenuItemAction({
  className,
  disabled,
  name,
  theme,
  onClick,
}) {
  return (
    <button
      className={classNames([
        classes.item,
        classes[theme],
        className
      ])}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      {name}
    </button>
  );
}

ContextMenuItemAction.propTypes = {
  ...ContextMenuItemActionProps
};

ContextMenuItemAction.defaultProps = {
  ...ContextMenuItemActionDefaultProps
};

export default ContextMenuItemAction;
