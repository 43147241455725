import classNames from 'classnames';
import PropTypes from 'prop-types';
import ContextMenu from '../ContextMenu';
import contextMenuClasses from '../ContextMenu/ContextMenu.module.scss';
import Dropdown, { DropdownToggle } from '../Dropdown';
import { Flex } from '../Flex';
import detailClasses from './Details.module.scss';
import classes from './Services.module.scss';
import { useMemo } from 'react';

function Opener({
  isDropdownVisible,
  serviceCount,
  theme
}) {
  const fill = useMemo(() => (
    isDropdownVisible ? 'var(--info-icon-hover, #fff)' : 'var(--info-icon, #565656)'
  ), [isDropdownVisible]);

  // const color = useMemo(() => (
  //   isDropdownVisible ? '#fff' : '#939393'
  // ), [isDropdownVisible]);

  return (
    <Flex
      alignItems="center"
    >
      <div className={detailClasses.label}>
        Services:
      </div>

      <div
        className={detailClasses.value}
      // style={{ color }}
      >
        {serviceCount}
      </div>

      <Flex className={classes.opener}>
        <svg
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            style={{ fill }}
          />
        </svg>
      </Flex>
    </Flex>
  );
}
function Services({
  services,
  theme
}) {
  return (
    <Dropdown
      opener={DropdownToggle}
      openerProps={{
        children: Opener,
        childrenProps: { serviceCount: services.length, theme },
      }}
      popoverProps={{
        event: 'hover',
        offset: [10, 10],
        placement: 'top'
      }}
    >
      <ContextMenu
        className={classNames([
          classes.contextMenu
        ])}
        menuItems={services.map((item, idx) => ({
          render: () => (
            <div
              className={classNames([
                contextMenuClasses.item,
                classes.contextMenuItem
              ])}
            >
              {idx + 1}. {item}
            </div>
          )
        }))}
        style={{
          '--details-popup-text': theme?.detailsPopupText,
          '--details-popup-bg': theme?.detailsPopupBg
        }}
      />
    </Dropdown>
  )
}

Services.propTypes = {
  services: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object
};

Services.defaultProps = {
  services: [],
  theme: {}
};

export default Services
