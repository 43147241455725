import React, { useRef, useMemo, useLayoutEffect } from "react";
import { useMedia } from "react-recipes";
import { useLocation } from "react-router-dom";
import audio from "../assets/media/loop.mp3";
import AudioPlayer from "./AudioPlayer";
import Container from "./Container";
import "./MainFooter.scss";
import Panel from "./Panel";

import { ScrollTrigger } from "../gsap";

import { useAtom } from "jotai";
import { lenisAtom } from "../atom/transitionAtom";

function MainFooter() {
  const el = useRef();

  const location = useLocation();

  const [lenis, setLenis] = useAtom(lenisAtom);

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const currentYear = useMemo(() => {
    return new Date().getFullYear().toString();
  }, []);

  useLayoutEffect(() => {
    if (!lenis) return;
    if (!location.pathname.includes("/case")) return;

    ScrollTrigger.create({
      id: "footer",
      trigger: lenis.rootElement,
      start: "clamp(100vh top)",
      onEnter: () => {
        el.current.classList.add("active");
      },
      onLeaveBack: () => {
        el.current.classList.remove("active");
      },
    });

    if (isMobile) {
      ScrollTrigger.create({
        id: "footerMobile",
        trigger: lenis.rootElement,
        start: "clamp(280vh top)",
        onEnter: () => {
          el.current.classList.add("hide");
        },
        onLeaveBack: () => {
          el.current.classList.remove("hide");
        },
      });
    }

    return () => {
      ScrollTrigger.getById("footer").kill();

      if (isMobile) {
        ScrollTrigger.getById("footerMobile").kill();
      }
    }
  }, [location.pathname, lenis, isMobile]);

  return (
    <Container ref={el} className="MainFooter" size="l">
      <Panel>
        <div className="fs desktopOnly">F/S ©{currentYear}</div>
        <div className="text desktopOnly">INTEGRITY THROUGH EXPRESSION™</div>
        <div className="MainFooter__audio-player desktopOnly">
          <AudioPlayer src={audio} />
        </div>
        <div className="mobileFoot mobileOnly">
          INTEGRITY THROUGH EXPRESSION™ ©16/{currentYear.slice(2)}
        </div>
      </Panel>
    </Container>
  );
}

export default MainFooter;
