import { useEventListener } from 'ahooks';
import classNames from 'classnames';
import { motion, useIsPresent } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Easings } from '../../animation';
import classes from './OverlayMask.module.scss';
import { OverlayMaskDefaultProps, OverlayMaskPropTypes } from './props';

/** @type {import('framer-motion').AnimationProps} */
const defaultDimmerAnimationProps = {
  animate: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: {
      ease: Easings.easeInCubic
    }
  },
  exit: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    transition: {
      ease: Easings.easeInCubic,
      duration: 0.72
    }
  }
}

/**
 * @typedef {object} OverlayMaskProps
 * @property {React.ReactNode} children
 * @property {string} [className]
 * @property {import('framer-motion').AnimationProps} [dimmerAnimationProps]
 * @property {boolean} [invisible=false]
 * @property {boolean} [noPointerEvents=false]
 * @property {(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void} [onClick]
 * */

/** @type {import('react').FC<OverlayMaskProps>} */
function OverlayMask({
  children,
  className,
  dimmerAnimationProps,
  invisible,
  noPointerEvents,
  onClick
}) {
  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const node = useRef();
  const [isPortalTargetReady, setPortalTargetReady] = useState(false);
  const isPresent = useIsPresent();


  const Children = useMemo(() => {
    if (invisible) {
      return children;
    }

    return (
      <motion.div
        animate
        {...Object.assign({}, defaultDimmerAnimationProps, dimmerAnimationProps)}
        className={classes.dimmer}
      >
        {children}
      </motion.div>
    )
  }, [children, dimmerAnimationProps, invisible]);

  const handleClick = useCallback((e) => {
    if (e.target === node.current && onClick instanceof Function) {
      e.preventDefault();
      onClick(e);
    }
  }, [onClick]);

  useEffect(() => {
    node.current = document.createElement('div');

    document.body.classList.add(classes.hasOverlayMask);

    return () => {
      node.current = null;
      document.body.classList.remove(classes.hasOverlayMask);
    };
  }, []);

  useEffect(() => {
    const portalTarget = node.current;

    if (portalTarget) {
      document.body.appendChild(portalTarget);
    }

    setPortalTargetReady(true);

    return () => {
      if (portalTarget) {
        document.body.removeChild(portalTarget);
      }
    };
  }, []);

  useEffect(() => {
    if (node.current instanceof HTMLDivElement) {
      node.current.className = classNames([
        classes.root,
        className,
        {
          [classes.invisible]: invisible,
          [classes.noPointerEvents]: noPointerEvents || !isPresent
        }
      ]);
    }
  }, [className, invisible, isPresent, noPointerEvents]);

  useEventListener(
    'mousedown',
    handleClick,
    { target: node }
  );

  useEventListener(
    'touchend',
    handleClick,
    { target: node }
  );

  return isPortalTargetReady ? (
    createPortal(Children, node.current)
  ) : null;
}

OverlayMask.propTypes = OverlayMaskPropTypes;

OverlayMask.defaultProps = OverlayMaskDefaultProps;

export default OverlayMask;
