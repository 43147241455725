import React, { forwardRef } from "react";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";

const Button = styled.div`
  position: absolute;
  top: ${(props) => props.top || "50%"};
  left: ${(props) => props.left || "50%"};
  width: ${(props) => props.size || pxToRem(84)};
  height: ${(props) => props.size || pxToRem(84)};
  border-radius: 100%;
  background-color: ${(props) => props.bgColor || "#000"};
  z-index: 99;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: scale(0) translate(-50%, -50%);

  @media (max-width: 620px) {
    width: ${(props) => props.noMobile ? "" : props.sizeMobile};
    height: ${(props) => props.noMobile ? "" : props.sizeMobile};
    top: ${(props) => props.noMobile ? "" : `${props.topMobile} !important`};
    left: ${(props) => props.noMobile ? "" : `${props.leftMobile} !important`};
    display: ${(props) => props.noMobile ? "none" : "flex"};
    transform: ${(props) => props.noMobile ? "scale(0)" : "scale(1) translate(-50%, -50%)"};
  }

  .button-cursor-inner {
    font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
    font-weight: 700;
    font-size: ${(props) => props.fontSize || pxToRem(10)};
    letter-spacing: 0.07em;
    line-height: 1.2;
    width: 100%;
    color: ${(props) => props.textColor || "#fff"};
    position: relative;
    text-transform: uppercase;
  }

  .button-cursor-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ButtonCursor = forwardRef((props, ref) => {
  return (
    <Button ref={ref} top={props.top} left={props.left} noMobile={props.noMobile} size={props.size} sizeMobile={props.sizeMobile} topMobile={props.topMobile} leftMobile={props.leftMobile} bgColor={props.bgColor} textColor={props.textColor} fontSize={props.fontSize}>
      <div className="button-cursor-inner">
        <span className="button-cursor-text">{props.text}</span>
      </div>
    </Button>
  );
});

export default ButtonCursor;
