import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: ${(props) => props.margin ?? 1};
  opacity: ${(props) => props.opacity ?? "0.5"};
`;

const Overlay = (props) => {
  return (
    <Wrapper className={props.className} opacity={props.opacity} zIndex={props.color} />
  );
};

export default Overlay;
