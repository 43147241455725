import React, { useRef, useEffect, useState } from "react";

import { ScrollTrigger } from "../../../gsap";

const Image = (props) => {
  let el = useRef();
  let [isImgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    if (!props.loading) {
      ScrollTrigger.create({
        trigger: el.current,
        once: true,
        start: "top-=120% bottom",
        onEnter: () => {
          setImgLoaded(true);
        },
      });
    } else {
      setImgLoaded(true);
    }
  }, [props.loading]);

  return (
    <img
      className={props.className ?? "img"}
      src={props.loading ? props.src : isImgLoaded ? props.src : ""}
      alt={props.alt}
      // loading={props.loading ?? "lazy"}
      draggable="false"
    />
  );
};

export default Image;
