import React from 'react';

/**
 * @typedef {object} GlobalModalStateContextValue
 * @property {boolean} open
 * @property {(open: boolean) => void} set
 */

/** @type {GlobalModalStateContextValue} */
const defaultContextValue = {
  open: false,
  set: () => {}
};

/** @type {import('react').Context<GlobalModalStateContextValue>} */
const GlobalModalStateContext = React.createContext();

export const GlobalModalStateContextProvider = GlobalModalStateContext.Provider;

export const useGlobalModalState = () => {
  const context = React.useContext(GlobalModalStateContext);

  if (!context) {
    return defaultContextValue;
  }

  return context;
}
