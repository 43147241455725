import React from 'react';
import styled from 'styled-components';
import pxToRem from "../../Utils/pxToRem";
import AnimTextPerline from "../Motion/Textperline";

const Text = styled.h2`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(90)};
  letter-spacing: 0.01em;
  line-height: 1.08;
  text-transform: ${(props) => props.transform};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(44)};
    letter-spacing: 0.01em;
  }
`;

const Heading2 = (props) => {
  return (
    <AnimTextPerline>
      <Text
        className={props.className}
        color={props.color}
        align={props.align}
        transform={props.transform}
        margin={props.margin}
      >
        {props.text}
      </Text>
    </AnimTextPerline>
  );
};

export default Heading2;
