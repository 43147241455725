import React from "react";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";
import { useMedia } from "react-recipes";

const Item = styled.div`
  box-sizing: border-box;
  grid-column-start: ${(props) => props.start || 1};
  grid-column-end: ${(props) => props.end || -1};
  grid-row: ${(props) => props.gridRow};
  align-self: ${(props) => props.alignSelf};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  order: ${(props) => props.order};

  @media (max-width: 620px) {
    grid-column-start: ${(props) => props.startM || 1};
    grid-column-end: ${(props) => props.endM || -1};
    margin-top: ${(props) => props.noGapTop ? pxToRem(-10) : ''};
    margin-bottom: ${(props) => props.noGapBottom ? pxToRem(-10) : ''};
    order: ${(props) => props.orderM};
  }
`;

const MobileContainer = styled.div`
  padding-left: ${pxToRem(15)};
  padding-right: ${pxToRem(15)};
`;

const GridItem = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  return (
    <Item
      className={props.className}
      start={props.start}
      end={props.end}
      startM={props.startM}
      endM={props.endM}
      gridRow={props.gridRow}
      alignSelf={props.alignSelf}
      margin={props.margin}
      padding={props.padding}
      mobileContainer={props.mobileContainer}
      noGapTop={props.noGapTop}
      noGapBottom={props.noGapBottom}
      order={props.order}
      orderM={props.orderM}
    >
      {props.mobileContainer && isMobile ? (
        <MobileContainer>{props.children}</MobileContainer>
      ) : (
        <>{props.children}</>
      )}
    </Item>
  );
};

export default GridItem;
