import React, { useState, useRef, useEffect } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  EffectCreative,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { gsap, CustomEase } from "../../../gsap";
import convertToCircledNumber from "../../Utils/convertToCircledNumber";
import Paragraph4 from "../Text/Paragraph4";
import ButtonCursor from "../ButtonCursor";
import Drag from "../Drag";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  EffectCreative,
  EffectFade,
]);

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  user-select: none;
  cursor: pointer;
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: 620px) {
    width: calc((100% + var(--case-container)) * ${pxToRem(1)});
    margin-left: calc(var(--case-container) * -${pxToRem(1)});
    margin-right: calc(var(--case-container) * -${pxToRem(1)});
    padding-left: calc(var(--case-container) * ${pxToRem(1)});
    padding-right: calc(var(--case-container) * ${pxToRem(1)});
  }

  .swiper-wrapper {
    order: ${(props) => (props.paginationTop ? "2" : undefined)};
  }

  .swiper-pagination-wrapper {
    position: relative;
    justify-content: ${(props) => props.paginationJustify};

    @media (max-width: 620px) {
      flex-direction: column;
    }
  }

  .swiper-pagination-flex {
    position: relative;

    @media (max-width: 620px) {
      display: ${(props) => (props.paginationMobile ? "flex" : "none")};
      order: 2;

      ${(props) =>
        props.paginationTitle &&
        `
        width: calc((100% + var(--case-container)) * ${pxToRem(1)});
        margin-left: calc(var(--case-container) * -${pxToRem(1)});
        margin-right: calc(var(--case-container) * -${pxToRem(1)});
        padding-left: calc(var(--case-container)  * ${pxToRem(1)} + ${pxToRem(
          15
        )});
        padding-right: calc(var(--case-container) * ${pxToRem(1)} + ${pxToRem(
          15
        )});
        white-space: nowrap;
        flex-wrap: nowrap;
      `}
    }
  }

  .swiper-pagination {
    width: auto;
    position: relative;
    font-family: ${(props) =>
      props.paginationTitle ? "Suisse Intl" : "Helvetica Now Text"};
    font-weight: ${(props) => (props.paginationTitle ? 900 : 800)};
    font-size: ${(props) =>
      props.paginationTitle ? pxToRem(12) : pxToRem(18)};
    letter-spacing: ${(props) => (props.paginationTitle ? "0.04em" : "")};
    line-height: normal;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ${(props) => (props.paginationTitle ? pxToRem(64) : pxToRem(40 - 5))};
    list-style: none;
    text-transform: uppercase;
    white-space: nowrap;
    padding: ${(props) => (props.paginationTitle ? 0 : `0 ${pxToRem(5)}`)};

    @media (max-width: 620px) {
      ${(props) =>
        props.paginationTitle &&
        `
        flex-wrap: nowrap;
        width: 100%;
        margin-left: calc(var(--case-container) * -${pxToRem(1)} - ${pxToRem(
          15
        )});
        margin-right: calc(var(--case-container) * -${pxToRem(1)} - ${pxToRem(
          15
        )});
        padding-left: calc(var(--case-container)  * ${pxToRem(1)} + ${pxToRem(
          15
        )});
        padding-right: calc(var(--case-container) * ${pxToRem(1)} + ${pxToRem(
          15
        )});
      `}
    }
  }

  .swiper-pagination-bullet {
    width: auto;
    height: auto;
    background: none;
    border-radius: 0;
    color: ${(props) => props.bulletHighlightColor || "currentColor"};
    cursor: pointer;
    width: ${(props) => (props.paginationTitle ? "auto" : pxToRem(18))};
    padding-bottom: ${(props) => (props.paginationTitle ? "" : pxToRem(12))};
    margin-bottom: ${(props) => (props.paginationTitle ? pxToRem(16) : "")};
    opacity: 0.25;
    transition: opacity 0.3s ease;
    position: relative;

    span {
      opacity: 0.25;
      transition: opacity 0.3s ease;
      position: relative;
    }

    &:not(:last-child) {
      &::after {
        display: ${(props) => (props.paginationTitle ? "" : "none")};
        content: "";
        position: absolute;
        right: -${pxToRem(31)}; // half of the gap
        top: 0;
        width: 1px;
        height: 100%;
        background-color: currentColor;
        opacity: 1;
        transition: opacity 0.1ms step-start;
      }
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;

    &:not(:last-child) {
      &::after {
        opacity: 0.25;
      }
    }
  }

  .swiper-pagination-active {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    bottom: 0;
    z-index: 2;
    background-color: ${(props) =>
      props.bulletHighlightColor || "currentColor"};
    transition: background-color 0.3s ease;
    will-change: transform;
  }

  .swiper {
    width: 100%;
    height: 100%;

    img {
      width: ${(props) => (props.perfectSize ? "100%" : "calc(100% + 2px)")};
      height: 100%;
      object-fit: cover;
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }

    ${(props) =>
      (!props.paginationTitle && !props.noPeek) &&
      `@media (max-width: 620px) {
        width: calc((100% + var(--case-container)) * ${pxToRem(1)});
        margin-left: calc(var(--case-container) * -${pxToRem(1)});
        margin-right: calc(var(--case-container) * -${pxToRem(1)});
        padding-left: calc(var(--case-container) * ${pxToRem(1)});
        padding-right: calc(var(--case-container) * ${pxToRem(1)});
      }`}
  }

  .swiper-cursor {
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${pxToRem(84)};
    height: ${pxToRem(84)};
    border-radius: 100%;
    background-color: ${(props) => props.cursorColor || "#000"};
    z-index: 2;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: scale(0);

    @media (max-width: 620px) {
      display: none;
    }

    .swiper-cursor-inner {
      font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
      font-weight: 700;
      font-size: ${pxToRem(8)};
      height: ${pxToRem(8)};
      letter-spacing: 0.07em;
      line-height: normal;
      overflow: hidden;
      width: 100%;
      color: #fff;
      position: relative;
      text-transform: uppercase;
    }

    .swiper-cursor-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 120%);
      transition: transform var(--transition-default);

      &.prev {
        transform: translate(-50%, -120%);

        &.active {
          transform: translate(-50%, -50%);
        }
      }

      &.next {
        transform: translate(-50%, 120%);

        &.active {
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;

// Fake box
const Content = styled.div`
  padding-top: ${(props) => (props.paginationTop ? undefined : "2rem")};
  padding-bottom: ${(props) => (props.paginationTop ? "2rem" : undefined)};
  margin-top: -${(props) => (props.paginationTop ? undefined : "2rem")};
  margin-bottom: -${(props) => (props.paginationTop ? "2rem" : undefined)};
  padding-right: 15rem;
  padding-left: 15rem;
  margin-right: -15rem;
  margin-left: -15rem;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;

  @media (max-width: 620px) {
    margin: 0;
    padding: 0;
  }
`;

const StyledSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column;
`;

const StyledLayoutFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  order: ${(props) => (props.paginationTop ? "1" : undefined)};
  padding: ${(props) => props.paginationMargin || `${pxToRem(17)} 0 0 0`};
  box-sizing: border-box;
`;

const Caption = styled.div`
  flex: 0.8;
  position: relative;
  text-align: ${(props) => props.captionTextAligned || "right"};

  @media (max-width: 620px) {
    flex: auto;
    width: 100%;
    height: 4.5rem;
    overflow: hidden;
    text-align: ${(props) => props.captionTextAlignedM || "right"};
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  width: calc(100% + 2px);
  height: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
`;

const Slider = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [area, setArea] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [paginationHover, setPaginationHover] = useState(false);
  const [firstEnter, setFirstEnter] = useState(false);

  let el = useRef();
  let elPaginationWrapper = useRef();
  let elPagination = useRef();
  let elPaginationActive = useRef();
  let elCursor = useRef();
  const elCursorDrag = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const params = {
    slidesPerView: props.slidesPerViewM || 1,
    grabCursor: false,
    spaceBetween: props.spaceBetweenM || 10,
    speed: props.fade ? 0 : 1200,
    rewind: false,
    followFinger: true,
    allowTouchMove: true,
    effect: props.fade ? "fade" : "slide",
    freeMode: props.paginationTitle ? false : true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        if (props.paginationTitle) {
          return `<span class="${className} pagination-index-${index}" data-text="${props.slides[index].paginationTitle}">${props.slides[index].paginationTitle}</span>`;
        } else {
          return `<span class="${className} pagination-index-${index}" data-text="${
            index + 1
          }">${index + 1}</span>`;
        }
      },
    },
    breakpoints: {
      // when window width is >= 640px
      640: {
        slidesPerView: props.slidesPerView || 1,
        spaceBetween: props.spaceBetween || 0,
        rewind: true,
        followFinger: false,
        allowTouchMove: false,
        freeMode: false,
      },
    },
  };

  const paginationChange = (init = false) => {
    const paginationItem = [
      ...elPagination.current.querySelectorAll(".swiper-pagination-bullet"),
    ];
    const paginationActive = elPagination.current.querySelector(
      ".swiper-pagination-bullet-active"
    );

    if (!paginationActive) return;

    if (!props.paginationTitle) {
      paginationItem.forEach((item) => {
        if (item.classList.contains("swiper-pagination-bullet-active")) return;
        item.textContent = item.dataset.text;
      });

      paginationActive.textContent = convertToCircledNumber(
        paginationActive.textContent
      );
    }

    const getWidth = paginationActive.offsetWidth;
    const getLeft = paginationActive.offsetLeft;

    if (init) {
      // slide init
      gsap.set(elPaginationActive.current, {
        width: getWidth,
        x: getLeft,
      });
    } else {
      // slide chage
      gsap.to(elPaginationActive.current, {
        width: getWidth,
        x: getLeft,
        duration: 1.2,
        ease: CustomEase.create("custom", "M0,0 C0.33,0.22 0,1.01 1,1"),
      });
    }
  };

  const slideInit = (el) => {
    setSwiper(el);
    paginationChange(true);
  };

  const slideChange = (el) => {
    paginationChange();
    setSlideIndex(el.activeIndex);
  };

  useEffect(() => {
    if (!el.current) return;
    if (isMobile) return;

    const handleMouseMove = (event) => {
      if (isMobile) return;
      if (paginationHover) return;

      const elRect = el.current.getBoundingClientRect();
      const cursorRect = elCursor.current.getBoundingClientRect();

      const x = event.clientX - elRect.left - cursorRect.width / 2;
      const y = event.clientY - elRect.top - cursorRect.height / 2;

      if (!firstEnter) {
        gsap.set(elCursor.current, {
          left: x,
          top: y,
        });

        setFirstEnter(true);
      }

      gsap.to(elCursor.current, {
        scale: 1,
        left: x,
        top: y,
        duration: 0.6,
        delay: 0.01,
        ease: "power4.out",
      });

      if (x < elRect.width / 2) {
        // Left area
        setArea("left");
      } else {
        // Right area
        setArea("right");
      }
    };

    const handleMouseClick = (event) => {
      if (isMobile) return;
      if (paginationHover) return;

      const tl = gsap.timeline({
        defaults: {
          duration: 0.2,
          ease: "power1.out",
        },
      });

      tl.to(elCursor.current, {
        scale: 0.8,
      });

      tl.to(elCursor.current, {
        scale: 1,
      });

      if (area === "left") {
        // Left area
        if (swiper) {
          swiper.slidePrev();
        }
      } else {
        // Right area
        if (swiper) {
          swiper.slideNext();
        }
      }
    };

    const handleMouseLeave = () => {
      if (isMobile) return;
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.6,
        overwrite: true,
        ease: "power4.out",
        onComplete: () => {
          setFirstEnter(false);
        },
      });
    };

    const handlePaginationHover = () => {
      if (isMobile) return;
      if (paginationHover) return;
      setPaginationHover(true);
      setTimeout(() => {
        handleMouseLeave();
      }, 1);
    };

    const handlePaginationLeave = () => {
      if (isMobile) return;
      if (!paginationHover) return;
      setPaginationHover(false);
    };

    const currentEl = el.current;
    const currentElPaginationWrapper = elPaginationWrapper.current;

    currentEl.addEventListener("mousemove", handleMouseMove);
    currentEl.addEventListener("mouseleave", handleMouseLeave);
    currentEl.addEventListener("click", handleMouseClick);
    elPaginationWrapper.current.addEventListener(
      "mouseenter",
      handlePaginationHover
    );
    elPaginationWrapper.current.addEventListener(
      "mouseleave",
      handlePaginationLeave
    );

    return () => {
      if (currentEl) {
        currentEl.removeEventListener("mousemove", handleMouseMove);
        currentEl.removeEventListener("mouseleave", handleMouseLeave);
        currentEl.removeEventListener("click", handleMouseClick);
      }

      if (currentElPaginationWrapper) {
        currentElPaginationWrapper.removeEventListener(
          "mouseenter",
          handlePaginationHover
        );
        currentElPaginationWrapper.removeEventListener(
          "mouseleave",
          handlePaginationLeave
        );
      }
    };
  }, [
    isMobile,
    swiper,
    area,
    firstEnter,
    paginationHover,
  ]);

  useEffect(() => {
    if (props.paginationTitle) return;
    if (!isMobile) return;
    if (!el.current) return;
    if (!elCursorDrag.current) return;

    const handleMouseClick = () => {
      gsap.to(elCursorDrag.current, {
        scale: 0,
        duration: 0.3,
      });
    };

    const currentEl = el.current;

    currentEl.addEventListener("touchstart", handleMouseClick);

    return () => {
      if (currentEl) {
        currentEl.removeEventListener("touchstart", handleMouseClick);
      }
    };
  });

  return (
    <Wrapper
      ref={el}
      padding={props.padding}
      backgroundColor={props.backgroundColor}
      bulletHighlightColor={props.bulletHighlightColor}
      cursorColor={props.cursorColor}
      paginationTop={props.paginationTop}
      paginationTitle={props.paginationTitle}
      paginationMobile={props.paginationMobile}
      paginationJustify={props.paginationJustify}
      perfectSize={props.perfectSize}
      noPeek={props.noPeek}
    >
      <Content paginationTop={props.paginationTop}>
        <StyledSwiper
          className={props.className}
          {...params}
          onInit={(el) => slideInit(el)}
          onSlideChange={(el) => slideChange(el)}
        >
          {isMobile && !props.paginationTitle && (
            <ButtonCursor
              ref={elCursorDrag}
              text="Drag"
              fontSize={pxToRem(8)}
              sizeMobile={pxToRem(74)}
              topMobile={props.cursorDragTop || "50%"}
              leftMobile={props.cursorDragLeft || "95%"}
              bgColor={props.cursorColor}
              textColor={props.cursorColorText}
            />
          )}
          {props.slides.map((slide, index) => (
            <StyledSwiperSlide
              style={{ "--aspect": props.aspect || "16/9" }}
              key={index}
              zindex={props.slides.length - 1 - index}
            >
              <img src={slide.src} alt={slide.alt} draggable="false" />
            </StyledSwiperSlide>
          ))}

          <StyledLayoutFlex
            className="swiper-pagination-wrapper"
            paginationTop={props.paginationTop}
            paginationMargin={props.paginationMargin}
          >
            <Drag>
              <div className="swiper-pagination-flex">
                <div ref={elPaginationWrapper}>
                  <div className="swiper-pagination" ref={elPagination}></div>
                  <div
                    className="swiper-pagination-active"
                    ref={elPaginationActive}
                  ></div>
                </div>
              </div>
            </Drag>

            {props.slides[0].caption && (
              <Caption
                captionTextAligned={props.captionTextAligned}
                captionTextAlignedM={props.captionTextAlignedM}
              >
                {props.slides.map(
                  (slide, index) =>
                    slide.caption && (
                      <Paragraph4
                        className={slideIndex === index ? "active" : ""}
                        key={index}
                        noSplit={true}
                        noAnimated={true}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: slide.caption }}
                        />
                      </Paragraph4>
                    )
                )}
              </Caption>
            )}
          </StyledLayoutFlex>
        </StyledSwiper>
      </Content>

      {!isMobile && (
        <div className="swiper-cursor" ref={elCursor}>
          <div className="swiper-cursor-inner">
            <span
              className={`swiper-cursor-text prev ${
                area === "left" && "active"
              }`}
            >
              Prev
            </span>
            <span
              className={`swiper-cursor-text next ${
                area === "right" && "active"
              }`}
            >
              Next
            </span>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default Slider;
