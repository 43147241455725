import isTouchDevice from 'is-touch-device';
import React, { useCallback, useMemo, useState } from 'react';
import Proxy from './Proxy';
import PointerTypes from './types';

export const CursorContext = React.createContext();

const isTouchEnabledDevice = isTouchDevice();

function CursorProvider({ children }) {
  const [isExclusive, setExclusive] = useState(false);
  const [type, setType] = useState(PointerTypes.Default);
  const [options, setOptions] = useState({});

  const onMouseEnter = useCallback(({ options, type, onMouseEnter }) => (e) => {
    if (onMouseEnter instanceof Function) {
      onMouseEnter(e);
    }

    if (!isExclusive) {
      setType(type);
      setOptions(options || {});
    }
  }, [isExclusive]);

  const onMouseLeave = useCallback(({ onMouseLeave }) => (e) => {
    if (onMouseLeave instanceof Function) {
      onMouseLeave(e);
    }

    if (!isExclusive) {
      setType(PointerTypes.Default);
    }
  }, [isExclusive]);

  const context = useMemo(() => {
    return {
      bind: (options) => ({
        onMouseEnter: onMouseEnter(options),
        onMouseLeave: onMouseLeave(options),
      }),
      setType: (type, exclusive = false) => {
        setExclusive(exclusive);
        setType(type);
      },
      lock: () => setExclusive(true),
      unlock: () => setExclusive(false),
      setOptions
    };
  }, [onMouseEnter, onMouseLeave]);

  return (
    <CursorContext.Provider value={context}>
      {children}

      {!isTouchEnabledDevice && (
        <Proxy
          {...options}
          type={type}
        />
      )}
    </CursorContext.Provider>
  )
}

export default CursorProvider;
