import { motion, useIsPresent } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge } from '../Badge';
import { PointerMinion } from '../Cursor/PointerMinion';
import classes from './Card.module.scss';
import './Card.scss';
import { useCardSharedTransitions } from './contexts/cardSharedTrasitionContext';
import { UnicodeCircleNumberMap } from '../../utils';

// const Ripple = createRipples({
//   color: 'rgba(180, 174, 183, 0.4)',
//   during: 1000,
// });

function Card({
  href,
  image,
  index,
  isMobile,
  tags,
  title,
  type,
  year
}) {
  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const elRef = useRef(null);
  const location = useLocation();
  const { rootVariants, contentVariants } = useCardSharedTransitions();
  const isPresent = useIsPresent();

  const routerState = useMemo(() => ({
    from: location.pathname
  }), [location]);

  return (
    <div
      ref={elRef}
      className={classes.root}
    >
      <motion.div
        className="Card"
        custom={[
          {
            index
          },
          {
            width: isPresent ? '0%' : '100%',
            height: isPresent ? '4px' : '0%'
          }
        ]}
        variants={rootVariants}
      >
        <div
          className="cardWrapper"
          style={{ display: 'flex' }}
        >
          <Link to={href} state={routerState} style={{ height: "100%", display: "contents" }}>
            <div className="Card__header Card--border">
              <div className="CardIndex">
                <motion.div
                  custom={[
                    { delay: 1.2, index }
                  ]}
                  variants={contentVariants}
                >
                  <div className="CardIndex__label">N&#176;</div>
                  <div
                    className="CardIndex__index"
                    dangerouslySetInnerHTML={{ __html: `&#x${UnicodeCircleNumberMap[index + 1]?.toString(16)};` }}
                  />
                </motion.div>
              </div>
              <div className="CardTags">
                {tags.map((tag, index, collection) => (
                  <div
                    key={index}
                    className="CardTag"
                  >
                    <motion.div
                      custom={[
                        { delay: 1.2 + collection.length * 0.1, index }
                      ]}
                      variants={contentVariants}
                    >
                      #{tag}
                    </motion.div>
                  </div>
                ))}
              </div>
            </div>
            <div className="Card__body Card--border">
              <h2 className="CardTitle">
                <motion.div
                  custom={[
                    { delay: 0.52, duration: 1.20, index }
                  ]}
                  variants={contentVariants}
                >
                  {title}
                </motion.div>
              </h2>
              <div className="CardYear">
                <motion.div
                  custom={[
                    { delay: 0.62, duration: 1.10, index }
                  ]}
                  variants={contentVariants}
                >
                  Year — {year}
                </motion.div>
              </div>
            </div>
            <div className="Card__footer">
              <div className="CardImage__wrapper">
                <motion.img
                  custom={
                    [{ delay: 0.24, duration: 1.2, index, scale: 1 }, { index, delay: 0.2, duration: 1.2, scale: 1.1 }]
                  }
                  className="CardImage" src={image} alt=""
                  variants={{
                    enter: contentVariants.enter,
                    exit: isPresent ? contentVariants.exit : null
                  }}
                  style={{ transformOrigin: 'bottom center' }}
                />
              </div>
            </div>

            {!isMobile && typeof type === 'string' && (
              <PointerMinion
                boundingElement={elRef}
                offsetY={32}
              >
                {(ref) => (
                  <Badge ref={ref}>
                    {type}
                  </Badge>
                )}
              </PointerMinion>
            )}
          </Link>
        </div>
      </motion.div>
    </div>
  );
}

Card.propTypes = {
  href: PropTypes.string,
  root: PropTypes.instanceOf(Node),
  rootMargin: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  threshold: function (props, propName, componentName) {
    if (props[propName] < 0.0 || props[propName] > 1.0) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Validation failed. Range(0, 1).'
      );
    }
  }
};

Card.defaultProps = {
  tags: []
};

export default Card;
