import { createContext, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Durations, Easings } from "../../animation";
import { getRouteObject } from "../../routing";

/**
 * @typedef {object} PageTransitionOptions
 * @property {object} style
 * @property {object} motionProps
 */

/**
 * @callback PageOptionFn
 * @param {string} route
 * @returns {PageTransitionOptions}
 */

/**
 * @typedef {object} PageTransitionContextValue
 * @property {import('../../routing').RouteTransitionType} mode
 * @property {PageOptionFn} getOptions
 */

const defaultPageTransitionState = {
  mode: "wait",
};

export const PageTransitionContext = createContext(defaultPageTransitionState);

/**
 * @param {import('../../routing').RouteTransitionType} route
 * @returns {import('../../routing').RouteTransitionType}
 */
function getRouteTransitionMode(transitionType) {
  if (
    !transitionType
    || typeof transitionType !== 'string'
    || (transitionType !== 'sync' && transitionType !== 'wait')
  ) {
    return 'wait';
  }

  return transitionType;
}

/**
 * @param {ReturnType<getRouteObject>} from
 * @param {ReturnType<getRouteObject>} to
 * @returns {import('../../routing').RouteTransitionType}
 */
function resolveTransitionMode(from, to) {
  if (!from || !to) {
    return 'wait';
  }

  const fromMode = getRouteTransitionMode(from.options.transition);
  const toMode = getRouteTransitionMode(to.options.transition);

  if (
    (fromMode === 'sync' && toMode === 'sync')
    || (fromMode === 'sync' && toMode === 'wait')
  ) {
    return 'sync';
  }

  return 'wait';
}

/**
 * @param {'from'|'to'} type
 * @param {object} style
 * @return {object}
 */
function getStyles(type, style = {}) {
  return {
    ...style,
    position: type === "from" ? "absolute" : "relative",
    left: 0,
    top: 0,
    width: "100%",
    zIndex: type === "from" ? 1 : 0,
  };
}

/**
 * @param {object} options
 * @param {string} options.route
 * @param {import('../../routing').RouteData} options.to
 * @param {import('../../routing').RouteData} options.from
 * @returns
 */
function resolvePageTransitionOptions({ route, to, from, next }) {
  const isFromSync =
    route === from?.route && from?.options?.transition === "sync";
  const isToSync = route === to?.route && to?.options?.transition === "sync";


  if (isToSync && from?.options.transition === "sync") {
    return {
      style: {
        ...getStyles("to"),
      },
      motionProps: {
        // initial: {
        //   y: '50vh',
        // },
        // animate: {
        //   y: 0
        // },
        exit: {},
        transition: {
          ease: Easings.brunoOut,
          duration: Durations.base,
          delay: 0.1,
        },
      },
    };
  }

  if (next) {
    return {
      style: {
        ...getStyles("from", {
          opacity: "1",
        }),
      },
      motionProps: {
        exit: {
          opacity: "0",
        },
        transition: {
          duration: 0.1,
          delay: 3,
        },
      },
    };
  }

  if (isFromSync) {
    return {
      style: {
        ...getStyles("from", {
          transform: "scale(1) translateZ(0)",
          clipPath: "inset(0% 0% 0% 0%)",
          willChange: "clip-path, transform",
        }),
      },
      motionProps: {
        exit: {
          transform: "scale(0.88) translateZ(0)",
          clipPath: "inset(100% 0% 0% 0%)",
        },
        transition: {
          duration: Durations.base,
          ease: Easings.brunoOut,
          delay: 1.5,
        },
      },
    };
  }

  return {
    style: {
      position: "relative",
      zIndex: 0,
    },
    motionProps: {
      exit: {},
    },
  };
}

export function PageTransitionProvider({ children }) {
  const location = useLocation();

  const nextRoute = location.state?.next;
  const fromRoute = getRouteObject(location.state?.from);
  const toRoute = getRouteObject(location.pathname);
  const mode = resolveTransitionMode(fromRoute, toRoute);

  const getOptions = useCallback(
    (route) => {
      return resolvePageTransitionOptions({
        from: fromRoute,
        route: getRouteObject(route).route,
        to: toRoute,
        next: nextRoute,
      });
    },
    [fromRoute, toRoute, nextRoute]
  );

  const value = useMemo(
    () => ({
      getOptions,
      mode,
    }),
    [mode, getOptions]
  );

  return (
    <PageTransitionContext.Provider value={value}>
      {children}
    </PageTransitionContext.Provider>
  );
}

export default PageTransitionProvider;
