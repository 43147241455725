import { motion } from 'framer-motion';
import React from 'react';

function Default() {
  return (
    <motion.div exit={{}} />
  );
}

export default Default;
