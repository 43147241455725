import { flow } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import tokenizer from 'sbd';
import { merge } from 'lodash';

const defaultOptions = {
  html_boundries: false,
  newline_boundaries: true,
  preserve_whitespace: false,
  sanitize: true
}

const splitLine = (line) => (
  line.split('')
);

const split = flow([
  (text, options, byChar) => ({
    byChar, lines: tokenizer.sentences(text, merge({}, defaultOptions, options))
  }),
  ({ byChar, lines }) => (
    byChar
      ? lines.map(splitLine)
      : lines
  )
]);

function Split({
  byChars,
  children,
  options,
  text
}) {
  const lines = useMemo(() => (
    split(text, options, byChars)
  ), [byChars, options, text]);

  return children(lines);
}

Split.propTypes = {
  byChars: PropTypes.bool,
  children: PropTypes.func.isRequired,
  options: PropTypes.shape({
    html_boundaries: PropTypes.bool,
    newline_boundaries: PropTypes.bool,
    preserve_whitespace: PropTypes.bool,
    sanitize: PropTypes.bool
  }),
  text: PropTypes.string.isRequired
}

Split.defaultProps = {
  byChars: false,
  options: defaultOptions
}

export default Split;
