export const Tags = [
  {
    label: 'All',
    value: 0
  },
  {
    label: 'Fintech',
    value: 1
  },
  {
    label: 'Architecture',
    value: 2
  },
  {
    label: 'Healthcare',
    value: 3
  },
  {
    label: 'Tech',
    value: 4
  },
  {
    label: 'Environmental',
    value: 5
  },
  {
    label: 'Digital Platform',
    value: 6
  },
  {
    label: 'Wearable',
    value: 7
  }
];

export const Projects = [
  {
    href: 'https://www.happyring.com',
    type: 'Website',
    image: '/img/projects/happyring.jpg',
    title: 'Happy Ring',
    year: 2023,
    tags: [
      'Wearable',
      'Tech'
    ]
  },
  {
    href: '/case/depoly',
    type: 'Case Study',
    image: '/img/projects/depoly.jpg',
    title: 'DePoly SA',
    year: 2022,
    tags: [
      'Environmental',
      'Tech'
    ]
  },
  {
    href: '/case/roswell',
    type: 'Case Study',
    image: '/img/projects/roswellbiotech.jpg',
    title: 'Roswell Biotech',
    year: 2021,
    tags: [
      'Healthcare',
      'Tech'
    ]
  },
  {
    href: '/case/1910',
    type: 'Case Study',
    image: '/img/projects/1910genetics.jpg',
    title: '1910 Genetics',
    year: 2020,
    tags: [
      'Healthcare',
      'Tech'
    ]
  },
  {
    href: '/case/enzian',
    type: 'Case Study',
    image: '/img/projects/enzian.jpg',
    title: 'Enzian Health',
    year: 2020,
    tags: [
      'Digital Platform',
      'Healthcare'
    ]
  },
  {
    href: '/case/kollabo',
    type: 'Case Study',
    image: '/img/projects/kollabo.jpg',
    title: 'Kollabo AG',
    year: 2019,
    tags: [
      'Digital Platform',
      'SAAS'
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/1834313-Yova-AG-2019',
    type: 'Dribbble Case Study',
    image: '/img/projects/yova.jpg',
    title: 'Yova AG',
    year: 2019,
    tags: [
      'Environmental',
      'Fintech'
    ]
  },
  {
    legacy: true,
    href: 'https://dribbble.com/fiftyseven/projects/2687034-DARA-2018',
    type: 'Dribbble Case Study',
    image: '/img/projects/dara.jpg',
    title: 'Dara Architects',
    year: 2019,
    tags: [
      'Architecture'
    ]
  },
  {
    legacy: true,
    href: 'https://fiftyseven.co/dev/koniku/v1/',
    type: 'Website',
    image: '/img/projects/koniku.jpg',
    title: 'Koniku',
    year: 2017,
    tags: [
      'Healthcare',
      'Tech'
    ]
  }
];
