import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

export const VideoBackgroundContext = React.createContext();

const VideoBackgroundProvider = forwardRef(({ children }, ref) => {
  const value = useMemo(() => {
    return {
      pause: () => {
        if (ref.current instanceof HTMLVideoElement && !ref.current.paused) {
          ref.current.pause();
        }
      },
      play: () => {
        if (ref.current instanceof HTMLVideoElement && ref.current.paused) {
          ref.current.play();
        }
      }
    };
  }, [ref]);

  return (
    <VideoBackgroundContext.Provider value={value}>
      {children}
    </VideoBackgroundContext.Provider>
  )
});

VideoBackgroundProvider.displayName = 'VideoBackgroundProvider';

VideoBackgroundProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default VideoBackgroundProvider;
