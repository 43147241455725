import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import classes from './DropdownToggle.module.scss'

const DropdownToggle = React.forwardRef((
  {
    children,
    childrenProps,
    className,
    isDropdownVisible,
    ...props
  },
  ref
) => {

  return (
    <div
      ref={ref}
      className={classNames([
        classes.root
      ])}
      {...props}
    >
       {React.isValidElement(children) ? children : children({ isDropdownVisible, ...childrenProps })}
    </div>
  );
});

DropdownToggle.displayName = 'DropdownToggle';

DropdownToggle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  childrenProps: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func
};

DropdownToggle.defaultProps = {
  children: null,
  childrenProps: {},
  className: null,
  props: {},
  onClick: null
};

export default DropdownToggle;
