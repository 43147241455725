import React from "react";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";
import InfoHover from "./Icon/InfoHover";
import Services from "../Testimonial/Services";

const Wrapper = styled.div`
  margin-bottom: ${pxToRem(50)};
`;

const List = styled.ul`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  letter-spacing: 0.021em;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: ${pxToRem(50)};
  row-gap: ${pxToRem(8)};
  list-style: none;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 620px) {
    flex-direction: column;
    column-gap: ${pxToRem(36)};
    row-gap: ${pxToRem(2)};
    font-size: ${pxToRem(14)};
    letter-spacing: 0.02em;
    line-height: 1.571;
  }

  .menunav-item-3 {
    display: flex;
    align-items: center;
  }
`;

const Title = styled.span`
  color: ${(props) => props.color};
`;

const Value = styled.span`
  color: rgba(255, 255, 255, 0.4);
  margin-left: 0.2rem;
  position: relative;

  &:has(svg) {
    display: inline-flex;
    align-items: center;

    svg {
      width: 1em;
      height: 1em;
      margin-left: 0.5rem;
      margin-top: -${pxToRem(1)};

      @media screen and (max-width: 620px) {
        margin-top: -${pxToRem(1)};
      }
    }
  }
`;

const ListItem = styled.li`
  position: relative;
  color: ${(props) => props.color};
  line-height: 1.2;
  display: inline-block;

  @media screen and (max-width: 620px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .masking-text {
    @media screen and (max-width: 620px) {
      width: 100%;
    }
  }

  .line {
    @media screen and (max-width: 620px) {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
    }
  }

  &:last-child {
    ${Value} {
      &::after {
        display: none;
      }
    }
  }

  &:not(:last-child)&::after {
    content: "/";
    font-size: ${pxToRem(12)};
    letter-spacing: 0em;
    line-height: 2.916;
    position: absolute;
    top: 50%;
    right: ${pxToRem(-25)};
    transform: translateY(-50%);
    color: ${(props) => props.borderColor};
    opacity: ${(props) => props.opacity || "0.2"};

    @media (max-width: 620px) {
      display: none;
    }
  }
`;

const Menunavhero = (props) => {
  return (
    <Wrapper className={props.className}>
      <div className="masking-text">
        <List className="line">
          {props.items.map((item, index) => (
            <ListItem
              key={index}
              className={`menunav-item-${index}`}
              color={props.color}
              opacity={props.opacity}
              borderColor={props.borderColor}
            >
              {item.title === "Service(s)" ? (
                props.serviceInfo ? (
                  <>
                    {item.title && <Title>Service(s):</Title>}
                    {props.services && <InfoHover services={props.services} />}
                  </>
                ) : (
                  <>
                    {item.title && <Title>Service(s):</Title>}
                    {item.value && <Value>{item.value}</Value>}
                  </>
                )
              ) : (
                <>
                  {item.title && <Title>{item.title}: </Title>}
                  {item.value && <Value>{item.value}</Value>}
                </>
              )}
            </ListItem>
          ))}
        </List>
      </div>
    </Wrapper>
  );
};

export default Menunavhero;
