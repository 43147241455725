import { useSpring, AnimatePresence, useMotionValue, useVelocity } from 'framer-motion';
import React, { useDebugValue, useEffect, useMemo } from 'react';
import useMousePosition from '../../hooks/useMousePosition';
import Default from './Default';
import Large from './Large';
import PointerTypes from './types';

const STIFFNESS = 1000;
const DAMPING = 150;

const Cursors = {
  [PointerTypes.Default]: Default,
  [PointerTypes.Large]: Large
};

function Proxy({
  type,
  ...rest
}) {
  const { x, y } = useMousePosition();
  const mX = useSpring(x, { stiffness: STIFFNESS, damping: DAMPING });
  const mY = useSpring(y, { stiffness: STIFFNESS, damping: DAMPING });

  useEffect(() => {
    mX.set(x);
    mY.set(y);
  }, [x, y]); // eslint-disable-line react-hooks/exhaustive-deps
  // We are a root component, mX and mY will not change.

  // const Pointer = useMemo(() => (
  //   Cursors[type]
  // ), [type]);

  return (
    // <AnimatePresence custom={{ x, y }}>
      <Large
        {...rest}
        x={mX}
        y={mY}
        type={type}
        // key={type}
      />
    // </AnimatePresence>
  );
}

export default Proxy;
