import { useMount } from 'ahooks';
import { easeIn, easeInOut, easeOut, motion, transform, useIsPresent, useMotionValue } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Easings } from '../../animation';
import { off } from '../../utils';
import { useMaskedRevealUpdate } from '../Motion';
import { MotionFlex } from '../MotionFlex';
import classes from './TextButton.module.scss';

/** @type {import('framer-motion').Variants} */
const HoverVariants = {
  hover: ([[y], [[ease]], [delay] = [0]]) => ({
    y,
    transition: {
      ease,
      duration: 0.48,
      delay
    }
  }),
  normal: ([[, y], [, [ease]], [, delay] = [0, 0]]) => ({
    y,
    transition: {
      ease,
      duration: 0.48,
      delay
    }
  })
};

/** @type {import('framer-motion').Variants} */
const DecoratorVariants = {
  enter: {
    x: '0%'
  },
  exit: {
    x: '-110%'
  }
};

/**
 * @typedef {object} TextButtonProps
 * @property {boolean} [asLink=false]
 * @property {import('react').ReactNode} [children=null]
 * @property {string|number} [hotspot=-10]
 * @property {string} [href=null]
 * @property {import('react').ReactNode} [icon=null]
 * @property {boolean} [internal=false]
 * @property {'none'|'all'} [pointerEvents='all']
 * @property {React.CSSProperties} [style]
 * @property {import('react').AnchorHTMLAttributes['target']} [target=null]
 * @property {(e: HTMLElement) => void} [onClick=null]
 */

/**
 * @type {ReturnType<typeof import('react').forwardRef<HTMLElement, TextButtonProps>>}
 */
const TextButton = forwardRef((
  {
    asLink,
    children,
    hotspot,
    href,
    icon,
    internal,
    pointerEvents,
    style,
    target,
    onClick
  },
  ref
) => {
  const [hoverState, setHoverState] = useState('normal');
  const [decoratorAnimation, setDecoratorAnimation] = useState(undefined);
  const [isListeningForReveal, setIsListeningForReveal] = useState(true);
  const isPresent = useIsPresent();
  const isInteractive = pointerEvents !== 'none' || isPresent;
  const iconScaleValue = useMotionValue(0);

  const handleClick = useCallback((e) => {
    if (onClick instanceof Function && isInteractive) {
      e.preventDefault();
      onClick(ref?.current);
    }
  }, [isInteractive, onClick, ref]);

  const rootAttributes = useMemo(() => {
    if (asLink) {
      const isInternal = internal || href.startsWith('/');
      const link = isInternal ? { to: href } : { href };

      return {
        ...link,
        el: isInternal ? Link : 'a',
        target,
        rel: 'noopener noreferrer',
      };
    }

    return {};
  }, [asLink, href, internal, target]);

  const handleHoverStart = useCallback((state) => () => setHoverState(state), []);

  const handleRevealUpdate = useCallback((e) => {
    const { detail: { y } } = e;
    const yValue = parseInt(y);

    iconScaleValue.set(transform(yValue, [0, 110], [1, 0]));

    if (!decoratorAnimation && parseInt(yValue) < 5) {
      setDecoratorAnimation(DecoratorVariants.enter);
      off(ref?.current, 'onrevealupdate', handleRevealUpdate);
    }
  }, [decoratorAnimation, iconScaleValue, ref]);

  useMaskedRevealUpdate(ref, handleRevealUpdate);

  useMount(() => {
    // Dummy check to see if the element has a reveal animation.
    const fn = ref?.current?.onrevealcomplete || ref?.current?.onrevealupdate;

    setIsListeningForReveal(fn instanceof Function);
  });

  return (
    <MotionFlex
      ref={ref}
      {...rootAttributes}
      alignItems="center"
      className={classes.root}
      justifyContent="center"
      style={style}
      onClick={handleClick}
    >
      <div
        style={{
          inset: hotspot,
          pointerEvents,
          position: 'absolute'
        }}
        onMouseEnter={handleHoverStart('hover')}
        onMouseLeave={handleHoverStart('normal')}
      />

      <div className={classes.text}>
        <div className={classes.textInner}>
          <motion.div
            animate={hoverState}
            className={classes.textUpper}
            custom={[['0', '-100%'], [[Easings.easeOutCubic], [Easings.easeInCubic]], [0.48, 0]]}
            initial={false}
            variants={HoverVariants}
          >
            {children}
          </motion.div>

          <motion.div
            animate={hoverState}
            className={classes.textLower}
            custom={[['100%', '0%'], [[Easings.easeInCubic], [Easings.easeOutCubic]], [0, 0.48]]}
            initial={false}
            variants={HoverVariants}
          >
            {children}
          </motion.div>
        </div>

        <div className={classes.decorator}>
          <motion.div
            animate={!isListeningForReveal ? DecoratorVariants.enter : decoratorAnimation}
            className={classes.decoratorInner}
            initial={isInteractive ? DecoratorVariants.exit : DecoratorVariants.enter}
            transition={{
              ease: Easings.easeOutCubic,
              duration: 1.2
            }}
            variants={{
              exit: {
                x: '-110%'
              }
            }}
          />
        </div>
      </div>

      {icon && (
        <MotionFlex
          alignItems="center"
          className={classes.icon}
          justifyContent="center"
          style={{
            scale: isListeningForReveal ? iconScaleValue : 1,
            transformOrigin: 'center'
          }}
        >
          {icon}
        </MotionFlex>
      )}
    </MotionFlex>
  );
});

TextButton.displayName = 'TextButton';

TextButton.propTypes = {
  asLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hotspot: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  href: (props, propName, componentName) => {
    if (typeof props[propName] !== 'string' && props.asLink) {
      throw new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected \`string\`.`);
    }
  },
  icon: PropTypes.node,
  internal: PropTypes.bool,
  pointerEvents: PropTypes.oneOf(['all', 'none']),
  style: PropTypes.object,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  onClick: PropTypes.func
};

TextButton.defaultProps = {
  asLink: false,
  children: null,
  hotspot: -10,
  href: null,
  icon: null,
  internal: false,
  pointerEvents: 'all',
  style: null,
  target: null,
  onClick: null
};

/** @typedef {typeof TextButton.propTypes} T */

export default TextButton;
