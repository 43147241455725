import PropTypes from 'prop-types';

export const FlexPropTypes = {
  alignItems: PropTypes.oneOf([
    'baseline',
    'center',
    'flex-end',
    'flex-start',
    'stretch'
  ]),
  className: PropTypes.string,
  columnGap: PropTypes.number,
  children: PropTypes.node,
  direction: PropTypes.oneOf([
    'column',
    'column-reverse',
    'row',
    'row-reverse'
  ]),
  el: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string
  ]),
  gap: PropTypes.number,
  grow: PropTypes.number,
  justifyContent: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'space-around',
    'space-between',
    'space-evenly'
  ]),
  rowGap: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  wrap: PropTypes.bool,
  onClick: PropTypes.func
}

export const FlexDefaultProps = {
  alignItems: 'stretch',
  className: '',
  columnGap: null,
  direction: 'row',
  el: 'div',
  gap: null,
  grow: null,
  justifyContent: 'flex-start',
  rowGap: null,
  style: {},
  wrap: false,
  onClick: null
};
