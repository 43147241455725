import React, { useCallback, useRef } from 'react';
import { MotionFlex } from '../MotionFlex';
import Popover from '../Popover/Popover';
import TextButton from './TextButton';
import classes from './TextButtonWithPopover.module.scss';

const TextButtonOpener = ({
  ref,
  elRef,
  boundEvents: { onClick: onBoundClick },
  isVisible,
  onClick,
  ...props
}) => {
  ref(elRef.current);

  const handleClick = () => {
    onClick?.();
    onBoundClick?.();
  };

  return (
    <TextButton
      {...props}
      ref={elRef}
      onClick={handleClick}
    >
      {props.children}
    </TextButton>
  );
}

/**
 * @typedef {object} TextButtonWithPopoverPartialProps
 * @property {import('react').ReactNode} [cloneChildren=null]
 * @property {import('react').ReactNode} [cloneIcon=null]
 * @property {import('react').ReactNode} [content=null]
 */

/**
 * @typedef {import('./TextButton').TextButtonProps & TextButtonWithPopoverPartialProps} TextButtonWithPopoverProps
 */

/**
 * @type {ReturnType<typeof import('react').forwardRef<HTMLElement, TextButtonWithPopoverProps>>}
 */
export const TextButtonWithPopover = React.forwardRef((props, ref) => {
  const {
    cloneIcon = props.icon,
    content = null,
    onClick,
    ...restProps
  } = props;

  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const cloneRef = useRef(null);

  const calculateOffset = useCallback(() => {
    const offTop = cloneRef.current?.offsetTop ?? 0;
    const offsetHeight = cloneRef.current?.offsetHeight ?? 0;
    const offY = -1 * (offTop + offsetHeight);
    const wRef = ref.current?.getBoundingClientRect?.().width ?? 0;
    const wClone = cloneRef.current?.getBoundingClientRect?.().width ?? 0;
    const offX = Math.floor((wRef - wClone) / 2);

    return [offX, offY];
  }, [ref]);

  return (
    <Popover
      focusTrapOptions={{ active: false }}
      offset={calculateOffset}
      opener={TextButtonOpener}
      openerProps={{
        ...restProps,
        elRef: ref,
        onClick
      }}
      overlayProps={{
        invisible: true,
        noPointerEvents: true
      }}
      placement="bottom"
    >
      <MotionFlex
        className={classes.root}
        direction="column"
      >
        <div className={classes.content}>
          {content}
        </div>

        <TextButton
          {...restProps}
          ref={cloneRef}
          pointerEvents="none"
          icon={cloneIcon}
        >
          {props.cloneChildren ?? props.children}
        </TextButton>
      </MotionFlex>
    </Popover>
  )
});

TextButtonWithPopover.displayName = 'TextButtonWithPopover';
