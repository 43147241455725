import React, { useLayoutEffect, useRef, forwardRef } from "react";
import { useMedia } from "react-recipes";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import pxToRem from "../../Utils/pxToRem";

import Container from "../Layout/Container";
import Image from "../Media/Image";

import { gsap, Flip } from "../../../gsap";


import { useAtom } from "jotai";
import { nextTransition, lenisAtom } from "../../../atom/transitionAtom";


const Wrapper = styled.section`
  background-color: ${(props) => props.backgroundColor || "#fff"};
  color: ${(props) => props.color || "#000"};
  margin: ${(props) => props.margin} !important;
  padding: ${(props) => props.padding} !important;
  position: ${(props) => props.position} !important;
  transform: translateY(7%);

  /* .overview__hero img {
    display: none;

    &.active {
      display: block;
    }
  } */
`;

const ImageWrapper = styled.div`
  position: relative;
  top: -44px;

  @media screen and (max-width: 620px) {
    top: -40px;
  }
`;

const Section = forwardRef((props, el) => {
  // Responsive
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);
  
  const location = useLocation();
  
  // const [isNextTransition, setIsNextTransition] = useAtom(nextTransition);
  const [lenis] = useAtom(lenisAtom);

  // const el = useRef();
  const elImg = useRef();

  useLayoutEffect(() => {
    if (!el.current) return;
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        delay: location.state?.casetocase ? 3.5 : 2
      });

      tl.to(el.current, {
        y: 0,
        duration: 1.4,
        ease: "customEase2",
      });
    }, el);
    return () => ctx.revert();
  }, [el, location.state?.casetocase]);

  // TODO: not yet done
  // useLayoutEffect(() => {
  //   if (!isNextTransition) return;
  //     const clone = document.querySelector(".clone-transition");
  //     const img = elImg.current.querySelector("img");

  //     const flipTransition = () => {
  //       let state = Flip.getState([clone, img]);
  //       img.classList.add("active");
  //       clone.classList.remove("active");

  //       Flip.from(state, {
  //         zIndex: 98,
  //         fade: true,
  //         absolute: true,
  //         toggleClass: "flipping",
  //         duration: 1.4,
  //         ease: "customEase2",
  //         onComplete: () => {
  //           clone.innerHTML = "";
  //         }
  //       });
  //     };

  //     setTimeout(() => {
  //       flipTransition();
  //     }, 3000);
  // }, [isNextTransition]);

  return (
    <Wrapper
      ref={el}
      id={props.id}
      className={props.className}
      backgroundColor={props.backgroundColor}
      color={props.color}
      margin={props.margin}
      padding={props.padding}
      position={props.position}
    >
      <Container>
        <ImageWrapper ref={elImg}>
          <Image
            src={props.img}
            className="overview__hero mb-7"
            aspect="12/7"
            noAnimated={true}
            parallax={true}
            loading="eager"
            flipId="fullscreen"
          />
        </ImageWrapper>

        {props.children}
      </Container>
    </Wrapper>
  );
});

export default Section;
