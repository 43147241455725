import React from 'react';
import Appear from '../components/Appear';
import { TextButton } from '../components/Button';
import { Flex } from '../components/Flex';
import { MaskedReveal } from '../components/Motion';
import { MotionTrail } from '../components/Motion/MotionTrail';
import Page from '../components/Page';
import { Icon } from '../components/icons';
import { withRegisterRoute } from '../routing';
import { getRandomIntRange } from '../utils';
import './Home.scss';
import { useMedia } from 'react-recipes';

const MotionTrailSettings = {
  dir: '/img/trail',
  numberOfItems: 30,
  total: 30
};

const set = getRandomIntRange(1, MotionTrailSettings.total, MotionTrailSettings.numberOfItems);

const srcSet = [...Array(MotionTrailSettings.numberOfItems)].map(
  (_, i) => `/img/trail/${set[i].toString().padStart(2, '0')}.jpg`
);

function Home() {
  const isMobile = useMedia(['(max-width: 640px)'], [true], false);

  return (
    <Page>
      {!isMobile && <MotionTrail srcSet={srcSet} />}
      <div className="App Home">
        <Appear reverse>
          <div className="centerHolder">
            <MaskedReveal
              className="est"
              delay={[0, 0.5]}
            >
              EST.&nbsp;&nbsp;—&nbsp;&nbsp;2016
            </MaskedReveal>

            <Flex className="mainText" direction="column">
              <MaskedReveal
                delay={[0.15, 0.45]}
                style={{ paddingBottom: '0.075em' }}
              >
                Expression
              </MaskedReveal>

              <MaskedReveal
                delay={[0.3, 0.3]}
                style={{ paddingBottom: '0.07em' }}
              >
                designed.
              </MaskedReveal>
            </Flex>

            <MaskedReveal
              className="tagline"
              delay={[0.9, 0.15]}
            >
              your brand’s Story told through DIGITAL artistry
            </MaskedReveal>

            <MaskedReveal
              className="buttonHolder"
              delay={[1.15, 0]}
            >
              <TextButton
                asLink
                icon={(
                  <Icon type="link" />
                )}
                href="/work"
              >
                our work
              </TextButton>
            </MaskedReveal>
          </div>
        </Appear>
      </div>
    </Page>
  );
}

export default withRegisterRoute(Home, {
  route: '/',
  name: 'Index',
  title: "FiftySeven® — Design Studio.",
  weight: 0,
  options: {
    exact: true
  }
});
