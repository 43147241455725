import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Flex } from '../Flex';
import { MotionFlex } from '../MotionFlex';
import classes from './QuoteDecorator.module.scss';

function QuoteDecorator({
  children,
  className,
  motionProps
}) {
  return (
    <Flex
      className={classNames([
        classes.root,
        className
      ])}
    >
      <MotionFlex
        {...motionProps}
        alignItems="center"
        animate="enter"
        className={classes.body}
        initial="exit"
        justifyContent="center"
      >
        <span className={classes.symbol}>
          {children}
        </span>
      </MotionFlex>
    </Flex>
  )
}

QuoteDecorator.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

QuoteDecorator.defaultProps = {
  children: '“',
  className: null
};

export default QuoteDecorator;
