import { useUpdateEffect } from 'ahooks';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useImperativeHandle, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import OverlayMask from '../OverlayMask';

/**
 * @exports
 * @typedef {object} PopoverApi
 * @property {Function} show
 * @property {Function} hide
 * @property {Function} toggle
 */

/**
 * @typedef {object} PopoverReferenceArgs
 * @property {React.Dispatch} ref
 * @property {Function} onClick
 */

/**
 * @typedef {object} PopoverProps
 * @property {function(PopoverReferenceArgs):void} opener
 * @property {function(PopoverApi):void} children
 */

/** @type import('react').Context<PopoverApi>  */
const PopoverContext = React.createContext(null);

export function usePopover() {
  /** @type {PopoverApi} */
  const context = useContext(PopoverContext);

  if (!context) {
    throw new Error('usePopover must be used within a `PopoverProvider`');
  }

  return context;
}

/**
 * @param {PopoverProps} props
 * @returns {*}
 */
const Popover = React.forwardRef((
  {
    children,
    className,
    event,
    focusTrapOptions,
    offset,
    opener,
    openerProps,
    overlayProps,
    placement,
    onClose,
    onOpen
  },
  ref
) => {
  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const [isVisible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const {
    active: isFocusTrapActive = true,
    ...restFocustTrapOptions
  } = focusTrapOptions;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset
        }
      }
    ]
  });

  const open = useCallback(() => {
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const toggle = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  const api = useMemo(() => ({ close, open, toggle }), [close, open, toggle]);

  const boundEvents = useMemo(() => {
    if (event === 'click') {
      return {
        onClick: () => {
          api.toggle();
        }
      };
    }

    return {
      onMouseEnter: () => api.open(),
      onMouseLeave: () => api.close()
    };
  }, [api, event]);

  const referenceArgs = useMemo(() => ({
    ref: setReferenceElement,
    isVisible,
    boundEvents,
    ...openerProps
  }), [boundEvents, isVisible, openerProps]);

  const ReferenceElement = useMemo(() => opener(referenceArgs), [opener, referenceArgs]);

  useImperativeHandle(ref, () => api, [api]);

  useUpdateEffect(() => {
    if (isVisible && onOpen instanceof Function) {
      onOpen();
    }
    else if (onClose instanceof Function) {
      onClose();
    }
  }, [isVisible, onClose, onOpen]);

  return (
    <>
      {ReferenceElement}

      <AnimatePresence>
        {isVisible && (
          <OverlayMask
            {...overlayProps}
            noPointerEvents={event === 'hover'}
            onClick={close}
          >

            <FocusTrap
              active={isFocusTrapActive}
              focusTrapOptions={restFocustTrapOptions}
            >
              <div
                ref={setPopperElement}
                className={className}
                style={styles.popper}
                {...attributes.popper}
              >
                <PopoverContext.Provider value={api}>
                  {React.isValidElement(children) ? children : children(api)}
                </PopoverContext.Provider>
              </div>
            </FocusTrap>
          </OverlayMask>
        )}
      </AnimatePresence>
    </>
  );
});

Popover.displayName = 'Popover';

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  event: PropTypes.oneOf(['click', 'hover']),
  // eslint-disable-next-line react/forbid-prop-types
  focusTrapOptions: PropTypes.object,
  offset: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.array
  ]),
  opener: PropTypes.func.isRequired,
  openerProps: PropTypes.shape({}),
  overlayProps: PropTypes.shape({}),
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
    'top',
    'bottom',
    'right',
    'left'
  ]),
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

Popover.defaultProps = {
  className: null,
  event: 'click',
  focusTrapOptions: {},
  offset: [0, 0],
  openerProps: {},
  overlayProps: {},
  placement: 'auto',
  onClose: null,
  onOpen: null
};

export default Popover;
