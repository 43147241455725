import classnames from 'classnames';
import { motion } from 'framer-motion';
import { Howl } from 'howler';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Easings } from '../../animation';
import './AudioPlayer.scss';

/** @type {import('framer-motion').Variants} */
const variants = {
  playing: {
    x: '-110%'
  },
  stopped: {
    x: 0
  }
};

const handlePlayPause = (set, play) => () => {
  set(play);
}

function AudioPlayer({ src }) {
  const [isLoaded, setLoaded] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const audioRef = useRef(new Howl({
    loop: true,
    src
  }));

  useEffect(() => {
    const audio = audioRef.current;

    audio.on('load', () => {
      setLoaded(true);
    });

    audio.on('play', handlePlayPause(setPlaying, true));
    audio.on('pause', handlePlayPause(setPlaying, false));

    return () => {
      audio.unload();
    }
  }, [audioRef]);

  const handlePlayback = useCallback(() => {
    if (audioRef.current.playing()) {
      audioRef.current.pause();
    }
    else {
      audioRef.current.play();
    }
  }, [audioRef]);

  return (
    <div className={classnames([
      'AudioPlayer',
      {
        'AudioPlayer--playing': isPlaying,
        'AudioPlayer--loaded': isLoaded
      }
    ])}>

      <div
        className="AudioPlayer__controls"
        onClick={handlePlayback}
      >
        <div className="AudioPlayerToggle">
          <div className="AudioPlayerToggle__label AudioPlayerLabel">
            <div className="AudioPlayerLabel__text">sound</div>
            {/* <div className="AudioPlayerLabel__state">
              <span className="AudioPlayerState--off">off</span>
              <span className="AudioPlayerState--on">on</span>
            </div> */}
          </div>
          <motion.div
            className="AudioPlayerToggle--paused"
            initial="stopped"
            animate={isPlaying ? 'playing' : 'stopped'}
            variants={variants}
            transition={{ ease: Easings.easeOutCubic }}
          />
        </div>
      </div>
    </div>
  );
}

export default AudioPlayer;
