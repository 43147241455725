import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { FlexDefaultProps, FlexPropTypes } from '../Flex';
import Flex from '../Flex/Flex';
import classes from './List.module.scss';

function List({
  children,
  className,
  listItemProps,
  plain,
  ...props
}) {
  const [exitAnimation, setExitAnimation] = useState('exit');

  const onModalClose = useCallback(() => {
    setExitAnimation('exit');
  },[]);

  const onModalShow = useCallback(() => {
    setExitAnimation(null);
  }, []);

  return (
    <Flex
      {...props}
      className={classNames([
        classes.root,
        {
          [classes.plain]: plain
        },
        className
      ])}
      el="ul"
    >
      {React.Children.map(children, (child) => (
        React.isValidElement(child)
          ? React.cloneElement(child, {
            'data-list-item': '',
            ...listItemProps,
            exitAnimation,
            onModalClose,
            onModalShow
          })
          : null
      ))}
    </Flex>
  );
}

List.propTypes = {
  ...FlexPropTypes,
  listItemProps: PropTypes.object,
  plain: PropTypes.bool
};

List.defaultProps = {
  ...FlexDefaultProps,
  listItemProps: {},
  plain: true
};

export default List;
