import React, { useMemo } from 'react';
import { isMotionComponent, motion } from 'framer-motion';
import { useNormalizeValue } from '../../hooks';
import { Easings } from '../../animation';

/** @type {{ [key: string]: import('framer-motion').Variants }} */
const ClipPathMaskVariants = {
  bottom: {
    enter: ({
      delay: [delay],
      duration: [duration]
    }) => ({
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
      transition: {
        delay,
        duration,
        ease: Easings.easeOutCubic
      }
    }),
    exit: ({
      delay: [, delay],
      duration: [, duration]
    }) => ({
      clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)',
      transition: {
        delay,
        duration,
        ease: Easings.easeInCubic
      }
    })
  },
  left: {
    enter: ({
      delay: [delay],
      duration: [duration]
    }) => ({
      clipPath: 'polygon(100% 100%, 0 100%, 0 0, 100% 0)',
      transition: {
        delay,
        duration,
        ease: Easings.easeOutCubic
      }
    }),
    exit: ({
      delay: [, delay],
      duration: [, duration]
    }) => ({
      clipPath: 'polygon(0 100%, 0 100%, 0 0, 0 0)',
      transition: {
        delay,
        duration,
        ease: Easings.easeInCubic
      }
    })
  },
  right: {
    enter: ({
      delay: [delay],
      duration: [duration]
    }) => ({
      clipPath: 'polygon(100% 100%, 0 100%, 0 0, 100% 0)',
      transition: {
        delay,
        duration,
        ease: Easings.easeOutCubic
      }
    }),
    exit: ({
      delay: [, delay],
      duration: [, duration]
    }) => ({
      clipPath: 'polygon(100% 100%, 100% 100%, 100% 0, 100% 0)',
      transition: {
        delay,
        duration,
        ease: Easings.easeInCubic
      }
    })
  },
  top: {
    enter: ({
      delay: [delay],
      duration: [duration]
    }) => ({
      clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%)',
      transition: {
        delay,
        duration,
        ease: Easings.easeOutCubic
      }
    }),
    exit: ({
      delay: [, delay],
      duration: [, duration]
    }) => ({
      clipPath: 'polygon(0px 0px, 100% 0px, 100% 0%, 0% 0%)',
      transition: {
        delay,
        duration,
        ease: Easings.easeInCubic
      }
    })
  }
};

/** @typedef {'bottom'|'left'|'right'|'top'} Direction  */

/**
 * @typedef {object} ClipPathMaskProps
 * @property {any} [as='div']
 * @property {[number, number] | number} [delay=0]
 * @prop {[number, number] | number} [duration=1400]
 * @property {Direction} [maskDirection='top']
 */

/** @type {import('react').FC<import('react').HTMLAttributes<HTMLElement> & ClipPathMaskProps>} */
export const ClipPathMask = ({ children, ...props }) => {
  const {
    as = 'div',
    delay = 0,
    duration = 1,
    maskDirection = 'top',
    ...rest
  } = props;

  const As = useMemo(() => {
    if (typeof as === 'string') {
      return motion[as];
    }

    if (isMotionComponent(as)) {
      return as;
    }

    throw new Error(`Invalid value for prop 'as': ${as}`);
  }, [as]);

  return (
    React.createElement(
      As,
      {
        custom: {
          delay: useNormalizeValue(delay),
          duration: useNormalizeValue(duration, true)
        },
        variants: ClipPathMaskVariants[maskDirection] ?? {},
        ...rest
      },
      children
    )
  );
};
