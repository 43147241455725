import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import Grid from "../Case/Grid";
import GridItem from "../Case/GridItem";
import Paragraph5 from "../Case/Text/Paragraph5";
import pxToRem from "../Utils/pxToRem";
import AnimTextPerline from "../Case/Motion/Textperline";
import { BusinessInquiry } from "../BusinessInquiry";
import Story from "./Story";

import { gsap } from "../../gsap";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .businessinquiry {
    font-size: ${pxToRem(17)};
    display: flex;
    align-items: center;
    gap: ${pxToRem(11)};

    @media screen and (max-width: 620px) {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-left: 15px;
      gap: ${pxToRem(10)};
    }

    .businessinquiry-wrapper {
      display: flex;
      gap: ${pxToRem(10)};

      @media screen and (max-width: 620px) {
        flex-direction: column;
        gap: 0;
      }
    }

    .businessinquiry-flex {
      gap: ${pxToRem(10)};

      @media screen and (max-width: 620px) {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .businessinquiry-cta {
      display: flex;
      gap: ${pxToRem(10)};
    }

    .businessinquiry-indicator {
      width: ${pxToRem(9.4)};
      height: ${pxToRem(9.4)};

      @media screen and (max-width: 620px) {
        position: absolute;
        margin-left: -15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 125%);
        margin-top: 1.1em;
      }
    }

    .businessinquiry-text {
      font-size: ${pxToRem(17)};
      letter-spacing: 0.05em;
      line-height: 1.6;
    }

    .businessinquiry-badge {
      font-size: ${pxToRem(12)};
      font-weight: 900;
      letter-spacing: 0.05em;
      padding: 3px 5px;
    }

    .businessinquiry-book {
      font-size: ${pxToRem(15)};
      text-transform: uppercase;
      letter-spacing: 0.08em;
      line-height: 1.5;
    }
  }
`;

const Container = styled.div`
  padding: 0 calc(var(--case-container) * ${pxToRem(1)});

  @media screen and (max-width: 620px) {
    max-width: 100%;
    padding: 0 ${pxToRem(40)};
  }
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    margin-top: ${pxToRem(550)};

    @media screen and (max-width: 620px) {
      margin-top: ${pxToRem(160)};
      padding-bottom: ${pxToRem(380)} !important;
    }
  }

  &:not(:last-of-type) {
    padding-bottom: ${pxToRem(380)};

    @media screen and (max-width: 620px) {
      padding-bottom: ${pxToRem(160)};
    }
  }
`;

const Title = styled.h3`
  font-family: "Eurostile Next LT Pro Light Ext", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(130)};
  letter-spacing: -0.02em;
  line-height: 0.8;
  text-align: center;
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(60)};
  }
`;

const Subtitle = styled.h4`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(20)};
  letter-spacing: 0.07em;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  margin-top: ${pxToRem(50)};
  margin-bottom: ${pxToRem(150)};
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(16)};
    margin-top: ${pxToRem(20)};
    margin-bottom: ${pxToRem(80)};
  }

  .bracket {
    position: relative;
    top: 1px;
  }

  .collapse-text {
    width: 0px;
    transform-origin: center;
    display: inline-block;

    .masking-text {
      display: inline-flex;
    }

    .line {
      transform: translateY(101%);
      display: inline-block;
    }
  }
`;

const Value = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();
  const elSubtitle = useRef();
  const itemRefs = useRef([]);

  const addItemRef = (el, index) => {
    itemRefs.current[index] = el;
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // if (isMobile) return;

      gsap.utils.toArray(itemRefs.current).forEach((el, i) => {
        const collapseText = el.querySelector('.collapse-text');
        const line = collapseText.querySelector('.line');

        const tlCollapseText = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top bottom"
          },
        });
        
        tlCollapseText.to(collapseText, {
          width: "auto",
          duration: 0.8,
          ease: "circ.inOut"
        });
  
        tlCollapseText.to(line, {
          y: 2,
          duration: 0.8,
        }, "-=0.1");
      });

      
    }, el);

    return () => ctx.revert();
  }, [isMobile]);

  return (
    <Wrapper ref={el}>
      {props.data.map((item, index) => (
        <React.Fragment key={index}>
          <Item>
            <Container>
              <div>
                <AnimTextPerline>
                  <Title dangerouslySetInnerHTML={{ __html: item.title }} />
                </AnimTextPerline>
                <Subtitle ref={(el) => addItemRef(el, index)}>
                  <span className="bracket">(&nbsp;</span>
                  <span className="collapse-text">
                    <span className="masking-text">
                      <span className="line">
                        {item.subtitle}
                      </span>
                    </span>
                  </span>
                  <span className="bracket">&nbsp;)</span>
                </Subtitle>

                {!item.descriptionCenter ? (
                  <Grid>
                    <GridItem start={6} end={13}>
                      <AnimTextPerline>
                        {item.descriptionLeft.map((text, index2) => (
                          <Paragraph5
                            key={index2}
                            margin={`0 0 ${pxToRem(25)} 0`}
                            color="#d1d1d2"
                          >
                            {text}
                          </Paragraph5>
                        ))}
                      </AnimTextPerline>
                    </GridItem>
                    <GridItem
                      start={13}
                      end={20}
                      padding={isMobile ? `0` : `0 ${pxToRem(39)} 0 0`}
                    >
                      <AnimTextPerline>
                        {item.descriptionRight.map((text, index3) => (
                          <Paragraph5
                            key={index3}
                            margin={`0 0 ${pxToRem(25)} 0`}
                            color="#d1d1d2"
                          >
                            {text}
                          </Paragraph5>
                        ))}
                      </AnimTextPerline>
                    </GridItem>
                  </Grid>
                ) : (
                  <Grid>
                    <GridItem start={9} end={17}>
                      <AnimTextPerline>
                        {item.descriptionCenter.map((text, index4) => (
                          <Paragraph5
                            key={index4}
                            margin={`0 0 ${pxToRem(25)} 0`}
                            color="#d1d1d2"
                          >
                            {text}
                          </Paragraph5>
                        ))}
                      </AnimTextPerline>

                      <div className="businessinquiry">
                        <BusinessInquiry
                          className="businessinquiry-flex"
                          isBook={true}
                          delay={3}
                        />
                      </div>
                    </GridItem>
                  </Grid>
                )}
              </div>
            </Container>
          </Item>

          {index === 0 && <Story />}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Value;
