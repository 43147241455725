import { useEventListener } from 'ahooks';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import classes from './Select.module.scss';

/**
 * @typedef {object} Option
 * @property {string} label
 * @property {string} value
 */

/**
 * @typedef {object} SelectPartialProps
 * @property {string} [label]
 * @property {Option[]} options
 */

/**
 * @typedef {SelectPartialProps & import('react').SelectHTMLAttributes<HTMLSelectElement>} SelectProps
 */

/** @type {import('framer-motion').ForwardRefComponent<HTMLSelectElement, SelectProps>} */
export const Select = React.forwardRef((props, ref) => {
  const {
    className,
    label,
    options = [],
    ...rest
  } = props;

  /** @type {import('react').MutableRefObject<HTMLSelectElement>} */
  const elRef = useRef();
  const [value, setValue] = useState(() => options?.[0].label)


  useEventListener(
    'change',
    (e) => setValue(options.find(({ value }) => e.target.value == value)?.label),
    {
      target: elRef
    }
  );

  return (
    <div className={classes.root}>
      {typeof label === 'string' && (
        <label
          className={classes.label}
          htmlFor={rest.id}
        >
          {label}&#58;
        </label>
      )}

      <div
        className={classes.controlWrapper}
        data-value={value}
      >
        <select
          {...rest}
          className={classNames(classes.control, className)}
          ref={(node) => {
            if (ref?.current) {
              ref.current = node;
            }

            elRef.current = node;
          }}
        >
          {options.map(({ label, value }, idx) => (
            <option
              key={idx}
              value={value}
            >
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
});

Select.displayName = 'Select';
