import { merge } from 'immutable';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Popover from '../Popover';

const defaultPopoverProps = {
  placement: 'auto',
  overlayProps: {
    invisible: true
  }
};

function Dropdown({
  children,
  opener: Opener,
  openerProps,
  popoverProps
}) {
  const mergedPopoverProps = useMemo(() => (
    merge({}, defaultPopoverProps, popoverProps)
  ), [popoverProps]);

  const opener = useCallback(({ ref, isVisible, boundEvents }) => {
    const { className, ...rest } = openerProps;

    return (
      <Opener
        {...boundEvents}
        {...rest}
        ref={ref}
        className={className}
        isDropdownVisible={isVisible}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Opener, openerProps]);

  return (
    <Popover
      opener={opener}
      {...mergedPopoverProps}
    >
      {children}
    </Popover>
  );
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  // label: PropTypes.node,
  opener: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  openerProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  popoverProps: PropTypes.object,
};

Dropdown.defaultProps = {
  openerProps: {},
  popoverProps: defaultPopoverProps
};

export default Dropdown;
