import { useContext } from 'react';
import { PageTransitionContext } from './PageTransitionProvider';

/**
 * @returns {import('./PageTransitionProvider').PageTransitionContextValue}
 */
function usePageTransition() {
  const context = useContext(PageTransitionContext);

  if (!context) {
    throw new Error('usePageTransition must be used within a `PageTransitionProvider`.');
  }

  return context;
}

export default usePageTransition;
