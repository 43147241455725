import React from "react";
import { useMedia } from "react-recipes";
import pxToRem from "../../Utils/pxToRem";

// Component
import Grid from "../Grid";
import GridItem from "../GridItem";
import Chaptersection from "../Chaptersection";
import Heading2 from "../Text/Heading2";
import Label1 from "../Text/Label1";
import Border from "../Border";
import Image from "../Media/Image";
import Accordion from "../Accordion";
import AnimTextPerline from "../Motion/Textperline";

const Chapter = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  return (
    <>
      <Chaptersection
        className={props.menuItemClass}
        menuitem={props.menuItem}
        title={props.title}
        number={props.number}
      />
      <Image
        src={props.imgIntro}
        className={props.imgIntroClass ?? `${isMobile ? "my-8" : "mt-9 mb-8"}`}
        aspect={props.imgIntroAspect ?? "12/7"}
        animateMask={true}
      />

      <Heading2 text={props.headingText} />

      <Border className={`${isMobile ? "my-7" : "mt-5 mb-6"}`} />

      <Grid rowGap={pxToRem(160)}>
        {!isMobile && (
          <GridItem start={1} end={8}>
            <Label1 as="h3" text="Challenge" />
          </GridItem>
        )}
        {isMobile ? (
          <GridItem>
            <Accordion
              className="relative"
              isOpen={true}
              title="Challenge"
              borderColor={props.borderColor}
              noPaddingTop={true}
            >
              {props.challengeParagraph}
            </Accordion>

            <Accordion title="Direction" borderColor={props.borderColor}>
              {props.directionParagraph}
            </Accordion>

            <Accordion title="Result" borderColor={props.borderColor}>
              {props.resultParagraph}
            </Accordion>
          </GridItem>
        ) : (
          <>
            <GridItem start={8} className="relative">
              <AnimTextPerline>{props.challengeParagraph}</AnimTextPerline>
            </GridItem>

            <GridItem start={8} end={16}>
              <Label1 text="Direction" />

              <AnimTextPerline>{props.directionParagraph}</AnimTextPerline>
            </GridItem>

            <GridItem start={17}>
              <Label1 text="Result" delay={0.25} />

              <AnimTextPerline delay={0.25}>
                {props.resultParagraph}
              </AnimTextPerline>
            </GridItem>
          </>
        )}
      </Grid>
    </>
  );
};

export default Chapter;
