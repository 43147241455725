import React, { useEffect, useLayoutEffect, useRef } from 'react';
import classes from './MotionTrail.module.scss';
import { ImageTrail } from './codedrops/imageTrail';
import { useResourceLoader } from './useResourceLoader';
import { useIsPresent } from 'framer-motion';

/**
 * @typedef {object} MotionTrailProps
 * @property {string[]} [srcSet]
 */

/** @type {import('react').FC<MotionTrailProps>} */
export const MotionTrail = (props) => {
  const { srcSet = [] } = props;

  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const elRef = useRef();

  /** @type {import('react').MutableRefObject<ImageTrail>} */
  const trail = useRef();

  const isPresent = useIsPresent();

  const { loading, resources } = useResourceLoader(srcSet);

  useLayoutEffect(() => {
    if (!loading && resources.length > 0 && elRef.current.children.length > 0) {
      trail.current = new ImageTrail(
        elRef.current, {
        className: classes.content__img,
        filters: {
          blur: [10, 0],
          brightness: [0, 1],
          grayscale: [600, 0]
        },
        transforms: {
          scale: [0.3, 1.6]
        }
      });
    }
  }, [loading, resources.length]);

  useEffect(() => {
    if (!isPresent && trail.current instanceof ImageTrail) {
      trail.current.dispose();
      trail.current = null;
    }
  }, [isPresent]);

  return (
    <div
      ref={elRef}
      className={classes.root}
    >
      {resources.map((resource, key) => (
        <div
          key={key}
          alt="" src={resource}
          className={classes.content__img}
        >
          <div
            className={classes['content__img-inner']}
            style={{ backgroundImage: `url(${resource})` }}
          />
        </div>
      ))}
    </div>
  );
};
