import React, { useLayoutEffect, useRef } from "react";
import { useMedia } from "react-recipes";
import { gsap, ScrollTrigger } from "../../../gsap";

const Sticky = (props) => {
  // Responsive
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();

  useLayoutEffect(() => {
    if (isMobile) return;
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: el.current,
        start: props.offsetStart ? `top top+=${props.offsetStart}%` : `top top`,
        end: props.offsetEnd ? `bottom bottom-=${props.offsetEnd}%` : `bottom bottom`,
        endTrigger: el.current,
        scrub: true,
        pin: ".sticky-content",
        pinSpacing: false,
      });
    }, el);

    return () => ctx.revert();
  }, [isMobile, props]);

  return (
    <div ref={el}>
      {props.children}
    </div>
  );
};

export default Sticky;
