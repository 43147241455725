import React from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";

const Container = styled.div`
  padding: 0 calc(var(--case-container) * ${pxToRem(1)});
`;

const LayoutContainer = (props) => {
  return <Container className={props.className}>{props.children}</Container>;
};

export default LayoutContainer;
