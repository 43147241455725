// Importing utility functions from 'utils.js'
import { gsap } from 'gsap';
import { Image } from './image.js';
import { getMouseDistance, getPointerPos, lerp } from './utils.js';

/**
 * @typedef {'grayscale' | 'brightness' | 'blur'} Filter
 */

/**
 * @typedef {'scale'} Transform
 */

/**
 * @typedef {{ [Key in Filter]: [number, number] }} Filters
 */

/**
 * @typedef {{ [Key in Transform]: [number, number] }} Transforms
 */

export class ImageTrail {
  // Class properties initialization
  DOM = { el: null }; // Object to hold DOM elements
  images = []; // Array to store Image objects
  imagesTotal = 0; // Variable to store total number of images
  imgPosition = 0; // Variable to store the position of the upcoming image
  zIndexVal = 1; // z-index value for the upcoming image
  activeImagesCount = 0; // Counter for active images
  isIdle = true; // Flag to check if all images are inactive
  // Mouse distance from the previous trigger, required to show the next image
  threshold = 80;

  /** @type {number} */
  #rId = null;

  /** @type {import('framer-motion').Point} */
  #mousePos = { x: 0, y: 0 };

  /** @type {import('framer-motion').Point} */
  #cacheMousePos = { ...this.#mousePos };

  /** @type {import('framer-motion').Point} */
  #lastMousePos = { ...this.#mousePos };

  /** @type {Filters} */
  #filters = {};

  /** @type {Transforms} */
  #transforms = {};

  /**
   * Constructor for the ImageTrail class.
   * Initializes the instance, sets up the DOM elements, creates Image objects for each image element, and starts the rendering loop.
   * @param {HTMLElement} DOM_el - The parent DOM element containing all image elements.
   * @param {object} options
   * @param {{ className: string }} options.className - An object containing the class name for the image elements.
   * @param {Filters} options.filters - An object containing the filters to be applied to the images.
   * @param {Transforms} options.transforms - An object containing the transforms to be applied to the images.
   */
  constructor(DOM_el, { className,filters, transforms } = {}) {
    // Store the reference to the parent DOM element.
    this.DOM.el = DOM_el;

    this.#filters = filters;
    this.#transforms = transforms


    // Create and store Image objects for each image element found within the parent DOM element.
    this.images = [...this.DOM.el.querySelectorAll(`.${className}`)].map(img => new Image(img));

    // Store the total number of images.
    this.imagesTotal = this.images.length;

    // Adding an event listener to the window to update mouse position on mousemove event
    window.addEventListener('mousemove', this.#handlePointerMove);
    window.addEventListener('touchmove', this.#handlePointerMove);

    const onPointerMoveEv = () => {
      // Initialize cacheMousePos with the current mousePos values.
      // This is necessary to have a reference point for the initial mouse position.
      this.#cacheMousePos = { ...this.#mousePos };
      // Initiate the rendering loop.
      requestAnimationFrame(() => this.render());
      // Remove this mousemove event listener after it runs once to avoid reinitialization.
      window.removeEventListener('mousemove', onPointerMoveEv);
      window.removeEventListener('touchmove', onPointerMoveEv);
    };
    // Set up an initial mousemove event listener to run onMouseMoveEv once.
    window.addEventListener('mousemove', onPointerMoveEv);
    window.addEventListener('touchmove', onPointerMoveEv);
  }

  dispose = () => {
    cancelAnimationFrame(this.#rId);

    window.removeEventListener('mousemove', this.#handlePointerMove);
    window.removeEventListener('touchmove', this.#handlePointerMove);
  }

  #handlePointerMove = (ev) => {
    // If it's a touch event, we'll use the first touch point
    if (ev.touches) {
      this.#mousePos = getPointerPos(ev.touches[0]);
    } else {
      // If it's a mouse event, proceed as usual
      this.#mousePos = getPointerPos(ev);
    }
  }

  /**
   * The `render` function is the main rendering loop for the `ImageTrail` class, updating images based on mouse movement.
   * It calculates the distance between the current and the last mouse position, then decides whether to show the next image.
   * @returns {void}
   */
  render() {
    // Calculate distance between current mouse position and last recorded mouse position.
    let distance = getMouseDistance(this.#mousePos, this.#lastMousePos);

    // Smoothly interpolate between cached mouse position and current mouse position for smoother visual effects.
    this.#cacheMousePos.x = lerp(this.#cacheMousePos.x || this.#mousePos.x, this.#mousePos.x, 0.3);
    this.#cacheMousePos.y = lerp(this.#cacheMousePos.y || this.#mousePos.y, this.#mousePos.y, 0.3);

    // If the calculated distance is greater than the defined threshold, show the next image and update lastMousePos.
    if (distance > this.threshold) {
      this.showNextImage();
      this.#lastMousePos = this.#mousePos;
    }

    // If all images are inactive (isIdle is true) and zIndexVal is not 1, reset zIndexVal to avoid endless incrementation.
    if (this.isIdle && this.zIndexVal !== 1) {
      this.zIndexVal = 1;
    }

    // Request the next animation frame, creating a recursive loop for continuous rendering.
    this.#rId = requestAnimationFrame(() => this.render());
  }

  /**
   * Based on the speed of the mouse, calculates a size value within a specific range.
   * The function maps the speed to a size multiplier, ensuring that higher speeds result in larger sizes.
   * It uses a maximum speed threshold to normalize the size adjustment.
   *
   * @param {number} speed - The current speed of the mouse movement.
   * @param {number} minSize - The minimum size limit for the transformation.
   * @param {number} maxSize - The maximum size limit for the transformation.
   * @returns {number} - The adjusted size value.
   */
  mapSpeedToSize(speed, minSize, maxSize) {
    const maxSpeed = 200;
    return minSize + (maxSize - minSize) * Math.min(speed / maxSpeed, 1);
  }

  /**
   * Determines the brightness level based on the mouse speed, staying within defined limits.
   * The faster the mouse movement, the higher the brightness, with a maximum speed threshold for normalization.
   *
   * @param {number} speed - Current speed of the mouse movement.
   * @param {number} minBrightness - Minimum brightness level allowed.
   * @param {number} maxBrightness - Maximum brightness level allowed.
   * @returns {number} - The calculated brightness level.
   */
  mapSpeedToBrightness(speed, minBrightness, maxBrightness) {
    const maxSpeed = 70;
    return minBrightness + (maxBrightness - minBrightness) * Math.min(speed / maxSpeed, 1);
  };

  /**
   * Adjusts the blur effect based on the current speed of the mouse, ensuring it remains within a specific range.
   * Higher speeds result in more blur. A maximum speed threshold is used for normalization purposes.
   *
   * @param {number} speed - The current speed of the mouse movement.
   * @param {number} minBlur - The minimum blur effect allowed.
   * @param {number} maxBlur - The maximum blur effect allowed.
   * @returns {number} - The computed blur value.
   */
  mapSpeedToBlur(speed, minBlur, maxBlur) {
    const maxSpeed = 90;
    return minBlur + (maxBlur - minBlur) * Math.min(speed / maxSpeed, 1);
  };

  /**
   * Modifies the grayscale level of an image based on the mouse's speed, within set minimum and maximum boundaries.
   * Faster movements lead to higher grayscale levels. A maximum speed is defined to normalize the grayscale adjustment.
   *
   * @param {number} speed - The detected speed of the mouse movement.
   * @param {number} minGrayscale - The lowest permissible grayscale level.
   * @param {number} maxGrayscale - The highest permissible grayscale level.
   * @returns {number} - The adjusted grayscale level.
   */
  mapSpeedToGrayscale(speed, minGrayscale, maxGrayscale) {
    const maxSpeed = 90;
    return minGrayscale + (maxGrayscale - minGrayscale) * Math.min(speed / maxSpeed, 1);
  };

  /**
   * The `showNextImage` function is responsible for displaying, animating, and managing the next image in the sequence.
   * It increments the zIndexVal, selects the next image, stops ongoing animations, and defines a series of GSAP animations.
   * @returns {void}
   */
  showNextImage() {
    // Calculate the horizontal and vertical distances between the current and last mouse positions.
    let dx = this.#mousePos.x - this.#cacheMousePos.x;
    let dy = this.#mousePos.y - this.#cacheMousePos.y;

    // Compute the Euclidean distance between the current and last mouse positions. This represents the direct line distance regardless of direction.
    let speed = Math.sqrt(dx * dx + dy * dy);

    // Increment zIndexVal for next image.
    ++this.zIndexVal;

    // Select the next image in the sequence, or revert to the first image if at the end of the sequence.
    this.imgPosition = this.imgPosition < this.imagesTotal - 1 ? this.imgPosition + 1 : 0;

    // Retrieve the Image object for the selected position.
    const img = this.images[this.imgPosition];

    // let scaleFactor = this.mapSpeedToSize(speed, ...this.#transforms.scale); // Assuming min scale of 0.3 and max scale of 2
    // let brightnessValue = this.mapSpeedToBrightness(speed, ...this.#filters.brightness); // Assuming min brightness of 0 (0%) and max brightness of 1.3 (130%)
    // let blurValue = this.mapSpeedToBlur(speed, ...this.#filters.blur);
    // let grayscaleValue = this.mapSpeedToGrayscale(speed, ...this.#filters.grayscale);

    // Stop any ongoing GSAP animations on the target image element to prepare for new animations.
    gsap.killTweensOf(img.DOM.el);

    // Define GSAP timeline.
    img.timeline = gsap.timeline({
      onStart: () => this.onImageActivated(),
      onComplete: () => this.onImageDeactivated()
    })
      .fromTo(img.DOM.el, {
        opacity: 1,
        scale: 1,
        zIndex: this.zIndexVal,
        x: this.#cacheMousePos.x - img.rect.width / 2,
        y: this.#cacheMousePos.y - img.rect.height / 2
      }, {
        opacity: 1,
        duration: 0.8,
        ease: 'power3',
        scale: 1,
        // filter: `grayscale(${grayscaleValue * 100}%) brightness(${brightnessValue * 100}%) blur(${blurValue}px)`,
        // filter: `brightness(${brightnessValue * 100}%)`,
        x: this.#mousePos.x - img.rect.width / 2,
        y: this.#mousePos.y - img.rect.height / 2
      }, 0)
      /* Inner image */
      .fromTo(img.DOM.inner, {
        scale: 2
      }, {
        duration: 0.8,
        ease: 'power3',
        scale: 1
      }, 0)
      /* Inner image */
      // then make it disappear
      .to(img.DOM.el, {
        duration: 0.8,
        ease: 'power3.in',
        scale: 0.2
      }, 0.45)
      .to(img.DOM.el, {
        opacity: 0,
        duration: 0.4,
        ease: 'power3'
      }, '-=0.1');
  }

  /**
   * onImageActivated function is called when an image's activation (display) animation begins.
   * It increments the activeImagesCount and sets isIdle flag to false.
   * @returns {void}
   */
  onImageActivated = () => {
    // Increment the counter for active images.
    this.activeImagesCount++;

    // Set the isIdle flag to false as there's at least one active image.
    this.isIdle = false;
  }

  /**
   * onImageDeactivated function is called when an image's deactivation (disappearance) animation ends.
   * It decrements the activeImagesCount and sets isIdle flag to true if no images are active.
   * @returns {void}
   */
  onImageDeactivated = () => {
    // Decrement the counter for active images.
    this.activeImagesCount--;

    // If there are no active images, set the isIdle flag to true.
    if (this.activeImagesCount === 0) {
      this.isIdle = true;
    }
  }
}
