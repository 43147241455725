import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";

import { gsap, ScrollTrigger } from "../../../gsap";

const Wrapper = styled.div`
  overflow: hidden;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  box-sizing: border-box;
  will-change: transform;
`;

const Placeholder = styled.div`
  background-color: rgb(215 215 215);
`;

const Image = (props) => {
  let el = useRef();
  let [isImgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    if (!props.loading) {
      ScrollTrigger.create({
        trigger: el.current,
        once: true,
        start: "top-=1000px bottom+=200px",
        onEnter: () => {
          setImgLoaded(true);
        },
        onEnterBack: () => {
          setImgLoaded(true);
        }
      })
    } else {
      setImgLoaded(true);
    }
  }, [props.loading]);

  useLayoutEffect(() => {
    if (!isImgLoaded) return;
    let ctx = gsap.context(() => {
      if (props.parallax) {
        const tl = gsap.timeline({
          defaults: {
            ease: "none",
          },
          scrollTrigger: {
            trigger: el.current,
            start: "clamp(top bottom)",
            end: "bottom top",
            scrub: true,
          },
        });

        gsap.set(".img", {
          yPercent: -15,
        });

        tl.to(".img", {
          yPercent: 15,
        });
      }

      if (props.noAnimated) return;
      if (!props.animateMask) {
        gsap.from(el.current, {
          delay: props.delay || 0,
          y: 35,
          ease: "customEase2",
          scrollTrigger: {
            trigger: el.current,
          },
        });
      } else {
        const tl = gsap.timeline({
          delay: props.delay || 0,
          defaults: {
            duration: gsap.defaults().duration * 2,
            ease: "customEase2",
          },
          scrollTrigger: {
            trigger: el.current,
            start: "top-=15% bottom",
            end: "bottom top",
            scrub: true,
          },
        });

        tl.from(el.current, {
          yPercent: 10,
          scale: 0.85,
        });

        tl.from(
          ".img",
          {
            scale: 1.15,
          },
          0
        );

        tl.to(
          ".img",
          {
            yPercent: 15,
            ease: "none",
          },
          "<=80%"
        );
      }
    }, el);

    return () => ctx.revert();
  }, [props.animateMask, props.delay, props.noAnimated, props.parallax, isImgLoaded]);

  return (
    <Wrapper
      ref={el}
      className={props.className}
      width={props.width}
      {...(props.noAspect ? {} : {style: {"--aspect": props.aspect || "16/9"}})}
      parallax={props.parallax}
      margin={props.margin}
    >
      {props.src ? (
        <img
          data-flip-id={props.flipId}
          className="img"
          src={props.loading ? props.src : isImgLoaded ? props.src : ''}
          alt={props.alt}
          // loading={props.loading ?? "lazy"}
          draggable="false"
        />
      ) : (
        <Placeholder />
      )}
    </Wrapper>
  );
};

export default Image;
