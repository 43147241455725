import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";

import { gsap, ScrollTrigger } from "../../../gsap";

const Wrapper = styled.div`
  overflow: hidden;
  background: #2d2926;

  .img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform-origin: center;
    transform: scale(1.23);
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Imagerotate = (props) => {
  let el = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(el.current, {
        delay: props.delay || 0,
        y: 35,
        ease: "customEase2",
        scrollTrigger: {
          trigger: el.current,
        },
      });

      const tl = gsap.timeline({
        paused: true, // Start with the timeline paused
        repeat: -1,
        yoyo: true,
        defaults: {
          duration: 30,
          ease: "sine.out",
        },
      });

      tl.set(
        ".img",
        {
          willChange: "transform",
        },
      );

      tl.to(".img", {
        scale: 1.35,
      });
      tl.to(
        ".img",
        {
          rotate: 60,
        },
        0.2
      );

      ScrollTrigger.create({
        trigger: el.current,
        start: "top-=30% bottom",
        end: "bottom top",
        onEnter: () => tl.play(),
        onLeave: () => tl.pause(),
        onEnterBack: () => tl.play(),
        onLeaveBack: () => tl.pause(),
      });
    }, el);

    return () => ctx.revert();
  }, [props.delay]);

  return (
    <Wrapper
      ref={el}
      className={props.className}
      {...(props.noAspect
        ? {}
        : { style: { "--aspect": props.aspect || "16/9" } })}
    >
      <Flex>
        {props.src && (
          <img
            className="img"
            src={props.src}
            alt={props.alt}
            loading={props.loading ?? "lazy"}
            draggable="false"
          />
        )}
      </Flex>
    </Wrapper>
  );
};

export default Imagerotate;
