import React, { useRef, useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import pxToRem from "../../Utils/pxToRem";
import { throttle } from "lodash";
import ImageRegular from "../Media/ImageRegular";

import { gsap, ScrollTrigger } from "../../../gsap";

const Wrapper = styled.span`
  position: relative;
`;

const Text = styled.span`
  text-decoration: underline;
  cursor: default;
`;

const BannerWrapper = styled.span`
  overflow: hidden;
  position: absolute;
  top: ${(props) => props.top || pxToRem(-100)};
  left: ${(props) => props.left || pxToRem(130)};
  z-index: 1;
  width: ${(props) => props.width || pxToRem(313)};
  height: ${(props) => props.height || pxToRem(190)};
  transform: translate(-50%, -50%);
`;

const Banner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  will-change: transform;

  .banner-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Close = styled.span`
  position: absolute;
  top: ${pxToRem(18)};
  right: ${pxToRem(16)};
  width: ${pxToRem(34)};
  height: ${pxToRem(34)};
  z-index: 1;
  background-color: ${(props) => props.closeColor || "#000"};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  &.hidden {
    display: none;
  }

  &:hover {
    .close-line {
      &.left,
      &.right {
        transform: rotate(0deg);
      }
    }
  }

  .close-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-line {
    background-color: currentColor;
    position: absolute;
    height: 1px;
    width: ${pxToRem(12)};
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.68, 1);
    background-color: ${(props) => props.closeLine || "#fff"};

    &.left {
      transform: rotate(-45deg);
    }

    &.right {
      transform: rotate(45deg);
    }
  }
`;

const Bannerlogo = (props) => {
  // Responsive
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);
  const [firstReverse, setFirstReverse] = useState(false);

  const tl = useRef();

  let el = useRef();
  let elBannerWrapper = useRef();
  let elBanner = useRef();
  let elClose = useRef();

  const mouseHover = () => {
    if (!tl.current) return;
    tl.current.reverse();
  };

  const mouseLeave = () => {
    if (!tl.current) return;
    tl.current.play();
  };

  useLayoutEffect(() => {
    if (!elBanner.current) return;
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        onComplete: () => {
          gsap.set(elBannerWrapper.current, {
            pointerEvents: "none",
          });

          if (!firstReverse) return;
          if (elClose.current.classList.contains("hidden")) return;
          if (isMobile) return;
          elClose.current.classList.add("hidden");
        },
        onReverseComplete: () => {
          gsap.set(elBannerWrapper.current, {
            pointerEvents: "inherit",
          });
        },
      });

      tl.current.to(elBanner.current, {
        yPercent: 101,
        duration: 1.5,
        ease: "expo.inOut",
      });

      ScrollTrigger.create({
        trigger: elBannerWrapper.current,
        once: true,
        onEnter: () => {
          if (tl.current) {
            tl.current.reverse(0);
            setFirstReverse(true);
          }
        },
      });
    }, el);

    return () => ctx.revert();
  }, [isMobile, firstReverse]);

  return (
    <Wrapper ref={el}>
      <BannerWrapper
        ref={elBannerWrapper}
        width={props.width}
        height={props.height}
        top={props.top}
        left={props.left}
      >
        <Banner ref={elBanner} bannerColor={props.bannerColor}>
          <Close
            ref={elClose}
            className="banner-close"
            closeColor={props.closeColor}
            closeLine={props.closeLine}
            onClick={mouseLeave}
          >
            <span className="close-wrapper">
              <span className="close-line left"></span>
              <span className="close-line right"></span>
            </span>
          </Close>
          <ImageRegular
            className="banner-img"
            src={props.src}
            alt="Logo Banner"
          />
        </Banner>
      </BannerWrapper>

      <Text
        onMouseEnter={mouseHover}
        onClick={mouseHover}
        onMouseLeave={mouseLeave}
      >
        {props.text}
      </Text>
    </Wrapper>
  );
};

export default Bannerlogo;
