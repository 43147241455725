import classnames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import './Analog.scss';

function Analog({ time }) {
  const hoursAngle = time.h * 30 + time.m / 2 + time.s / 120;
  const minutesAngle = time.h * 360 + time.m * 6 + time.s / 10;

  return (
    <div className="AnalogClock">
      <div className="AnalogClock__body">
        <motion.div
          className={classnames([
            'AnalogClockHand',
            'AnalogClockHand__hours'
          ])}
          initial={false}
          animate={{ rotate: hoursAngle }}
        >
        </motion.div>

        <motion.div
          className={classnames([
            'AnalogClockHand',
            'AnalogClockHand__minutes'
          ])}
          initial={false}
          animate={{ rotate: minutesAngle }}
        >
        </motion.div>
      </div>
    </div>
  );
}

export default Analog;
