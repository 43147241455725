import PropTypes from 'prop-types';
import React from 'react';
import { useMemo } from 'react';
import { Durations, Easings } from '../../animation';
import { Flex } from '../Flex';
import { MotionFlex } from '../MotionFlex';
import classes from './Avatar.module.scss';

function Avatar({
  className,
  name,
  src,
  title
}) {
  const variants = useMemo(() => ({
    enter: ([{ idx, delay }] = [{}]) => ({
      y: '0%',
      transition: {
        delay: (delay ?? 0) + (idx ?? 0) * Durations.gap,
        duration: Durations.base,
        ease: Easings.easeOutCubic
      }
    }),
    exit: {
      y: '120%'
    }
  }), []);

  return (
    <Flex
      className={className}
      style={{ overflow: 'hidden' }}
    >
      <MotionFlex
        animate="enter"
        custom={[{ delay: 0.82 }]}
        initial="exit"
        variants={variants}
      >
        <img
          className={classes.image}
          alt={name}
          src={src}
        />

        <Flex
          direction="column"
          justifyContent="center"
        >
          <div className={classes.name}>
            {name}
          </div>

          {title && (
            <div className={classes.title}>
              {title}
            </div>
          )}
        </Flex>
      </MotionFlex>
    </Flex>
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string
};

Avatar.defaultProps = {
  className: null,
  title: null
};

export default Avatar;
