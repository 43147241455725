import React from 'react';
import useClipboard from 'react-use-clipboard';
import { Flex } from '../Flex';
import { Icon } from '../icons';
import { TextButtonWithPopover } from './TextButtonWithPopover';
import classes from './EmailInquiryButton.module.scss';

/**
 * @typedef {object} EmailInquiryButtonProps
 * @property {never} children
 * @property {string} email
 */

/** @type {ReturnType<typeof import('react').forwardRef<HTMLElement, EmailInquiryButtonProps>>} */
export const EmailInquiryButton = React.forwardRef(({ email }, ref) => {
  const [, copyToClipboard] = useClipboard(email);

  return (
    <TextButtonWithPopover
      ref={ref}
      cloneChildren="Email Copied"
      cloneIcon={(
        <Icon type="checkmark" />
      )}
      content={(
        <Flex
          gap={12}
        >
          <div
            className={classes.icon}
          >
            <Icon
              type="infoCircle"
            />
          </div>
          <p className={classes.paragraph}>
            Due to limited availability, please include an estimate of your budget and timeline in your email.
          </p>
        </Flex>
      )}
      icon={(
        <Icon type="plus" />
      )}
      onClick={copyToClipboard}
    >
      Copy Email
    </TextButtonWithPopover>
  )
});
