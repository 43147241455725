import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";

import { gsap } from "../../gsap";

const Section = styled.div`
  background-color: ${(props) => props.backgroundColor || "#fff"};
  color: ${(props) => props.color || "#000"};
  margin-top: 92vh; // fallback
  margin-top: 92dvh;
  position: relative;
  z-index: 1;
  visibility: hidden;
`;

const Content = (props) => {
  const el = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.delayedCall(1.4, () => {
        gsap.set(el.current, {
          autoAlpha: 1,
        });
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <Section
      ref={el}
      className={props.className}
      color={props.color}
      backgroundColor={props.backgroundColor}
    >
      {props.children}
    </Section>
  );
};

export default Content;
