import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";

import { gsap } from "../../../gsap";

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
`;

const VideoBg = (props) => {
  let el = useRef();

  useLayoutEffect(() => {
    if (props.noAnimated) return;
    let ctx = gsap.context(() => {
      if (!props.animateMask) {
        gsap.from(el.current, {
          delay: props.delay || 0,
          y: 35,
          ease: "customEase2",
          scrollTrigger: {
            trigger: el.current,
          }
        });
      } else {
        const tl = gsap.timeline({
          delay: props.delay || 0,
          defaults: {
            duration: gsap.defaults().duration * 2,
            ease: "customEase2",
          },
          scrollTrigger: {
            trigger: el.current,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
          }
        });

        tl.from(el.current, {
          yPercent: 10,
          scale: 0.85
        });

        tl.from('.img', {
          scale: 1.15
        }, 0);

        tl.to('.img', {
          yPercent: 15,
          ease: "none"
        }, "<=80%");
      }

    }, el);

    return () => ctx.revert();
  }, [props.animateMask, props.delay, props.noAnimated]);

  return <Wrapper ref={el} className={props.className} backgroundColor={props.backgroundColor} padding={props.padding}>{props.children}</Wrapper>;
};

export default VideoBg;
