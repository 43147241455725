import React, { useRef } from "react";
import { useMedia } from "react-recipes";
import styled from "styled-components";
import Heading1 from "../Case/Text/Heading1";
import Label3 from "../Case/Text/Label3";
import pxToRem from "../Utils/pxToRem";
import AnimTextPerline from "../Case/Motion/Textperline";
import LayoutFlex from "../Case/Layout/Flex";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Item = styled.div`
  width: 100%;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${pxToRem(637)};

  @media screen and (max-width: 620px) {
    margin-top: ${pxToRem(254)};
  }
`;

const ItemTitle = styled.h3`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(38.8)};
  letter-spacing: -0.04em;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(25)};
  }
`;

const ItemNumber = styled.span`
  font-family: "Helvetica Now Text", sans-serif;
  font-weight: 800;
  font-size: ${pxToRem(20)};
  letter-spacing: -0.02em;
  line-height: 1;
  text-align: center;
  margin-top: ${pxToRem(39)};
  margin-bottom: ${pxToRem(86)};
  display: block;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(20)};
    margin-top: ${pxToRem(20)};
    margin-bottom: ${pxToRem(50)};
  }
`;

const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  font-family: "Suisse Intl", sans-serif;
  font-size: clamp(14px, ${pxToRem(16)}, ${pxToRem(16)});
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.56;
  color: #d1d1d2;
`;

const ItemListItem = styled.li``;

const Container = styled.div`
  padding: 0 calc(var(--case-container) * ${pxToRem(1)});

  @media screen and (max-width: 620px) {
    max-width: 100%;
    padding: 0 ${pxToRem(40)};
  }
`;

const Services = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();

  return (
    <Wrapper ref={el}>
      <Item>
        <Container>
          <AnimTextPerline>
            <Heading1
              as="h2"
              text="Services"
              align="center"
              margin={`${
                isMobile ? `0 0 ${pxToRem(12)} 0` : `0 0 ${pxToRem(30)} 0`
              }`}
            />
          </AnimTextPerline>
          <AnimTextPerline>
            <Label3 align="center" opacity="0.4">
              We work across four disciplines: strategy, <br />
              branding, digital, and CONTENT
            </Label3>
          </AnimTextPerline>

          <LayoutFlex
            direction={isMobile ? "column" : "row"}
            gap={pxToRem(120)}
            justify="space-between"
            margin={isMobile ? `${pxToRem(108)} 0 0 0` : `${pxToRem(100)} 0 ${pxToRem(25)} 0`}
          >
            {props.data.map((item, index) => (
              <LayoutFlex
                direction="column"
                key={index}
                margin={`0 0 ${pxToRem(25)} 0`}
              >
                <AnimTextPerline>
                  <ItemTitle>{item.title}</ItemTitle>
                </AnimTextPerline>

                <AnimTextPerline>
                  <ItemNumber>(&nbsp;{index + 1}&nbsp;)</ItemNumber>
                </AnimTextPerline>

                <AnimTextPerline>
                  <ItemList>
                    {item.items.map((list, index) => (
                      <ItemListItem key={index}>
                        <span>
                          <span>-</span> {list}
                          <br />
                        </span>
                      </ItemListItem>
                    ))}
                  </ItemList>
                </AnimTextPerline>
              </LayoutFlex>
            ))}
          </LayoutFlex>
        </Container>
      </Item>
    </Wrapper>
  );
};

export default Services;
