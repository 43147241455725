/**
 * Checks if the given element has a parent element with the given class name.
 * @param {HTMLElement|null} element
 * @param {string|string[]} className
 * @returns {boolean}
 */
export function hasParentElementWithClass(element, className) {
  if (!(element instanceof HTMLElement)) {
    return false;
  }

  const classes = Array.isArray(className) ? className : [className];
  let currentElement = element;

  while (currentElement) {
    // eslint-disable-next-line no-loop-func
    if (classes.some((name) => currentElement.classList.contains(name))) {
      return true;
    }

    currentElement = currentElement.parentElement;
  }

  return false;
}

/**
 * Checks if the given element has a parent element.
 * @param {HTMLElement|null} element
 * @param {HTMLElement|null} targetElement
 * @returns {boolean}
 */
export function hasParentElement(element, targetElement) {
  if (!(element instanceof HTMLElement) || !(targetElement instanceof HTMLElement)) {
    return false;
  }

  let currentElement = element;

  while (currentElement) {
    if (currentElement.parentElement === targetElement) {
      return true;
    }

    currentElement = currentElement.parentElement;
  }

  return false;
}

/**
 * @param {Window|Document|HTMLElement|EventTarget|null} obj
 * @param  {...any} args
 */
export function on(obj, ...args) {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...args);
  }
}

/**
 * @param {Window|Document|HTMLElement|EventTarget|null} obj
 * @param  {...any} args
 */
export function off(obj, ...args) {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...args);
  }
}

/**
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}


/**
 * Returns an array of unique random integers within a specified range.
 *
 * @param {number} min - The minimum value of the range (inclusive).
 * @param {number} max - The maximum value of the range (exclusive).
 * @param {number} range - The number of integers to return.
 * @returns {number[]} An array of unique random integers within the specified range.
 */
export function getRandomIntRange(min, max, range) {
  const set = new Set();

  while (set.size < range) {
    set.add(getRandomInt(min, max));
  }

  return Array.from(set);
}

export const UnicodeCircleNumberMap = [
  0x24EA, 0x2460, 0x2461, 0x2462, 0x2463,
  0x2464, 0x2465, 0x2466, 0x2467, 0x2468,
  0x2469, 0x246A, 0x246B, 0x246C, 0x246D,
  0x246E, 0x246F, 0x2470, 0x2471, 0x2472,
  0x2473
];
