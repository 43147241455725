import React from 'react';
import styled from 'styled-components';
import pxToRem from '../../Utils/pxToRem';
import AnimTextPerline from '../Motion/Textperline';

const Text = styled.h4`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(20)};
  letter-spacing: 0.02em;
  line-height: 1.15;
  text-transform: ${(props) => props.transform || 'uppercase'};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin || `0 0 ${pxToRem(28)} 0`};

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(16)};
    letter-spacing: 0.02em;
    line-height: 1.437;
    margin: ${(props) => props.margin || `0 0 ${pxToRem(14)} 0`};
  }
`;

const Label1 = (props) => {
  return (
    <AnimTextPerline right={true} noAnimated={props.noAnimated} delay={props.delay}>
      <Text className={props.className} color={props.color} align={props.align} transform={props.transform} margin={props.margin}>{props.text}</Text>
    </AnimTextPerline>
  );
};

export default Label1;
