import React from "react";
import styled from "styled-components";
import pxToRem from "../../Utils/pxToRem";

const Text = styled.p`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: ${pxToRem(22)};
  letter-spacing: 0.01em;
  line-height: 1.545;
  text-transform: ${(props) => props.transform};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
  display: block;

  &:not(:last-child) {
    margin: ${(props) => props.margin || `0 0 ${pxToRem(36)} 0`};
  }

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(18)};
    letter-spacing: 0.01em;
    line-height: 1.44;
  }
`;

const Paragraph1 = (props) => {

  return (
      <Text
        className={props.className}
        color={props.color}
        align={props.align}
        transform={props.transform}
        margin={props.margin}
      >
        {props.children}
      </Text>
  );
};

export default Paragraph1;
