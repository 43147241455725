import React from 'react';
import styled from 'styled-components';

// Component only for desktop
const Wrapper = styled.div`
  @media (min-width: 620px) {
    box-sizing: border-box;

    margin: ${(props) => props.margin};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};

    padding: ${(props) => props.padding};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
  }
`;

const FakeSpacing = (props) => {
  return (
    <Wrapper className={props.className} margin={props.margin} padding={props.padding} marginLeft={props.marginLeft} marginRight={props.marginRight} marginTop={props.marginTop} marginBottom={props.marginBottom} paddingLeft={props.paddingLeft} paddingRight={props.paddingRight} paddingTop={props.paddingTop} paddingBottom={props.paddingBottom}>
      {props.children}
      </Wrapper>
  );
};

export default FakeSpacing;
