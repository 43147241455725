import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import pxToRem from "../Utils/pxToRem";
import { gsap  } from "../../gsap";
import AnimTextPerline from "../Case/Motion/Textperline";
import AnimTextPerlineNested from "../Case/Motion/Textperlinenested";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: ${pxToRem(307)};

  @media screen and (max-width: 620px) {
    max-width: 100%;
  }

  &:not(:last-of-type) {
    padding-bottom: ${pxToRem(380)};

    @media screen and (max-width: 620px) {
      padding-bottom: ${pxToRem(80)};
    }
  }

  .pathline {
    height: 100%;
    position: absolute;
    top: 55%;
    transform: translateY(-50%) scale(0.95);
    transform-origin: top;

    &.right {
      right: -150%;
    }

    &.left {
      top: 55%;
      left: -150%;
    }

    path {
      stroke-dashoffset: 0.005;
      stroke-dasharray: 0px, 999999px;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 620px) {
    max-width: 100%;
    padding: 0 ${pxToRem(40)};
  }
`;

const Title = styled.h3`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(39)};
  letter-spacing: 0.04em;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(24)};
    letter-spacing: 0.0625em;
  }
`;

const Subtitle = styled.h4`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(20)};
  letter-spacing: 0.07em;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(20)};
  }
`;

const Number = styled.span`
  font-family: "Helvetica Now Text", sans-serif;
  font-weight: 800;
  font-size: ${pxToRem(20)};
  letter-spacing: -0.02em;
  line-height: 1.15;
  text-align: center;
  margin-top: ${pxToRem(39)};
  margin-bottom: ${pxToRem(86)};
  display: block;

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(20)};
    letter-spacing: -0.07em;
    margin-top: ${pxToRem(20)};
    margin-bottom: ${pxToRem(40)};
  }
`;

const Description = styled.p`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, ${pxToRem(16)}, ${pxToRem(16)});
  letter-spacing: 0.04em;
  line-height: 1.5625;
  text-align: left;
  color: #b1b1b1;
  
  @media screen and (max-width: 620px) {
    color: #d1d1d2;
  }

  .hidden {
    margin: 0 3px;
  }

  b,
  strong {
    --scale: 0;
    /* font-weight: 700; */
    font-weight: 400;
    position: relative;

    &::after {
      content: '';
      border: 1px solid rgba(209, 209, 210, 0.5);
      padding: 0px ${pxToRem(3)};
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 6px;
      transform-origin: left;
      transform: translate(-50%, -50%) scaleX(var(--scale));
      will-change: transform;
    }
  }

  &:not(:last-of-type) {
    margin: 0 0 ${pxToRem(25)} 0;
  }
`;

const Timeline = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();
  const itemRefs = useRef([]);

  const addItemRef = (el, index) => {
    itemRefs.current[index] = el;
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (isMobile) return;
      gsap.utils.toArray(itemRefs.current).forEach((el, i) => {
        const pathline = el.querySelector(".pathline path");

        let tl = null;

        if (i === 0) {
          tl = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              scrub: true,
              start: "top center",
              end: "bottom center",
            },
          });
        } else {
          tl = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              scrub: true,
              start: "top center-=20%",
              end: "bottom center",
            },
          });
        }
        
        
        tl.to(pathline, {
          drawSVG: "100%",
          ease: "none",
        });
      });
    }, el);

    return () => ctx.revert();
  }, [isMobile]);

  return (
    <Wrapper ref={el}>
      {props.data.map((item, index) => (
        <Item ref={(el) => addItemRef(el, index)} key={index}>
          {index === 0 && (
            <svg
              className="pathline pathline-1 right"
              width="281"
              height="881"
              viewBox="0 0 281 881"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.74643 0.539524C76.6654 18.5713 145.728 70.4154 196.535 147.577C247.343 224.739 276.852 322.599 280.184 425.034C283.515 527.47 260.47 628.347 214.858 711.045C169.246 793.743 103.8 853.308 29.3269 879.967"
                stroke="white"
                strokeOpacity="0.2"
              />
            </svg>
          )}

          {index === 1 && (
            <svg
              className="pathline pathline-2 left"
              width="232"
              height="928"
              viewBox="0 0 232 928"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M207.48 0.802227C143.075 45.8696 89.5087 114.143 52.9343 197.817C16.3601 281.49 -1.73741 377.167 0.72088 473.885C3.17917 570.604 26.0936 664.439 66.8314 744.64C107.569 824.842 164.477 888.154 231.018 927.343"
                stroke="white"
                strokeOpacity="0.2"
              />
            </svg>
          )}

          {index === 2 && (
            <svg
              className="pathline pathline-3 right"
              width="246"
              height="932"
              viewBox="0 0 246 932"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8224 1.1287C85.1531 40.1629 144.105 105.944 185.042 189.996C225.979 274.048 247.012 372.493 245.415 472.608C243.819 572.724 219.666 669.894 176.087 751.563C132.508 833.232 71.5143 895.632 1.00493 930.726"
                stroke="white"
                strokeOpacity="0.2"
              />
            </svg>
          )}

          <Container>
            <div>
              <AnimTextPerline>
                <Title>{item.title}</Title>
              </AnimTextPerline>
              <AnimTextPerline>
                <Subtitle>{item.subtitle}</Subtitle>
              </AnimTextPerline>
              <AnimTextPerline>
                <Number>(&nbsp;{index + 1}&nbsp;)</Number>
              </AnimTextPerline>

              <AnimTextPerlineNested>
                {item.description.map((text, index2) => (
                  <Description
                    key={index2}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                ))}
              </AnimTextPerlineNested>
            </div>
          </Container>
        </Item>
      ))}
    </Wrapper>
  );
};

export default Timeline;
