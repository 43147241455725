import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { CONTEXT_MENU_ITEM_TYPE_ACTION, CONTEXT_MENU_ITEM_TYPE_SEPARATOR } from './constants';
import classes from './ContextMenu.module.scss';
import ContextMenuItemAction from './ContextMenuItemAction';
import ContextMenuItemSerparator from './ContextMenuItemSeparator';

function ContextMenuItemRenderer(props) {
  return useMemo(() => {
    const type = props.type ?? CONTEXT_MENU_ITEM_TYPE_ACTION;

    if (props.render instanceof Function) {
      const { render: Component } = props;

      return <Component />;
    }

    if (type === CONTEXT_MENU_ITEM_TYPE_SEPARATOR) {
      return (
        <ContextMenuItemSerparator />
      );
    }

    return (
      <ContextMenuItemAction {...props} />
    );
  }, [props]);
}

const ContextMenu = React.forwardRef((
  {
    className,
    compressed,
    menuItems,
    theme,
    style,
    onClick
  },
  ref
) => {
  const visibleMenuItems = useMemo(() => (
    menuItems?.filter(({ visible = true }) => (visible instanceof Function ? visible() : visible))
  ), [menuItems]);

  return (
    <div
      ref={ref}
      className={classNames([
        classes.root,
        classes[theme],
        {
          [classes.compressed]: compressed
        },
        className,
      ])}
      style={style}
      onClick={onClick}
    >
      <div
        className={classes.itemList}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
      >
        {visibleMenuItems.map((itemProps, key) => (
          <ContextMenuItemRenderer
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            {...itemProps}
          />
        ))}
      </div>
    </div>
  );
});

ContextMenu.displayName = 'ContextMenu';

ContextMenu.propTypes = {
  className: PropTypes.string,
  compressed: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  menuItems: PropTypes.array,
  style: PropTypes.object,
  theme: PropTypes.string,
  onClick: PropTypes.func
};

ContextMenu.defaultProps = {
  className: null,
  compressed: false,
  menuItems: [],
  style: {},
  theme: 'light',
  onClick: null
};

export default ContextMenu;
