import React, { useRef, useEffect, useLayoutEffect } from "react";
import { useMedia } from "react-recipes";
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Image from "../Media/Image";

import { gsap, ScrollTrigger } from "../../../gsap";

SwiperCore.use([A11y, Autoplay]);

const Slider = (props) => {
  let el = useRef();
  let elSwiper = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const params = {
    slidesPerView: 1,
    grabCursor: false,
    allowTouchMove: false,
    spaceBetween: 0,
    speed: 1,
    centeredSlides: true,
    centeredSlidesBounds: true,
    autoplay: {
      delay: props.speed || 600,
      disableOnInteraction: false,
    },
    onSwiper: (swiper) => {
      elSwiper.current = swiper;
    },
  };

  useEffect(() => {
    if (!elSwiper.current) return;
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: el.current,
        start: "top-=1000px bottom+=200px",
        onEnter: () => {
          elSwiper.current.autoplay.start();
        },
        onEnterBack: () => {
          elSwiper.current.autoplay.start();
        },
        onLeave: () => {
          elSwiper.current.autoplay.stop();
        },
        onLeaveBack: () => {
          elSwiper.current.autoplay.stop();
        },
      });
    }, elSwiper);

    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (props.noAnimated) return;
      gsap.from(el.current, {
        delay: props.delay || 0,
        y: 35,
        ease: "customEase2",
        scrollTrigger: {
          trigger: el.current,
        },
      });
    }, el);

    return () => ctx.revert();
  }, [props.delay, props.noAnimated, isMobile]);

  return (
    <div className={props.className} ref={el}>
      <Swiper {...params} ref={elSwiper}>
        {props.slides.map((slide, index) => (
          <SwiperSlide key={index} zindex={props.slides.length - 1 - index}>
            <Image src={slide.src} aspect={props.aspect} noAnimated={true} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
