import React from 'react';
import styled from 'styled-components';
import pxToRem from "../Utils/pxToRem";
import AnimTextPerline from "../Case/Motion/Textperline";

const Text = styled.h2`
  font-family: "Eurostile Next LT Pro Light Ext", sans-serif;
  font-weight: 300;
  font-size: ${pxToRem(130)};
  letter-spacing: -0.02em;
  line-height: 0.82;
  text-align: center;
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(46)};
    margin: ${(props) => props.marginMobile};
  }
`;

const BigTitle = (props) => {
  return (
    <AnimTextPerline>
      <Text
        className={props.className}
        color={props.color}
        align={props.align}
        transform={props.transform}
        margin={props.margin}
        marginMobile={props.marginMobile}
      >
        {props.children}
      </Text>
    </AnimTextPerline>
  );
};

export default BigTitle;
