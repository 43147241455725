import { motion, usePresence } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import classes from "./Page.module.scss";
import { usePageTransition } from "./PageTransitionProvider";
import { gsap, ScrollTrigger } from "../gsap";
import Lenis from "@studio-freight/lenis";
import throttle from "lodash/throttle";

import { lenisAtom } from "../atom/transitionAtom";
import { useAtom } from "jotai";

function Page({ children, dimOnExit, style, title }) {
  const page = useRef();
  const location = useLocation();
  const { getOptions } = usePageTransition();
  const { motionProps, style: motionStyle } = getOptions(location.pathname);
  const [isPresent, safeToRemove] = usePresence();

  const elLenis = useRef();
  const elContent = useRef();

  const [, setLenis] = useAtom(lenisAtom);
  const [isLenis, setIsLenis] = useState(false);

  const [clicked, setClicked] = useState(false);
  const scrollbar = useRef(null);
  const scrollbarInner = useRef(null);
  const scrollbarThumb = useRef(null);

  useEffect(() => {
    if (!isPresent) {
      if (page.current instanceof HTMLElement) {
        page.current.classList.add(classes.hideScrollbars);
      }

      document.documentElement.classList.add("wait-cursor");
      safeToRemove();
    }
  }, [isPresent, safeToRemove]);

  useLayoutEffect(() => {
    document.documentElement.classList.remove("wait-cursor");

    if (!dimOnExit) {
      setIsLenis(true);
    } else {
      if (!elLenis) return;
      const initLenis = new Lenis({
        duration: 0.9,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -11 * t)), // https://www.desmos.com/calculator/brs54l4xou
        orientation: "vertical",
        gestureOrientation: "vertical",
        wrapper: elLenis.current,
        content: elContent.current,
      });

      setLenis(initLenis);

      let isScrolling = false;

      const onScrollBar = () => {
        if (!scrollbar.current) return;
        scrollbar.current.classList.remove("active");
        isScrolling = false;
      };

      const throttledOnScroll = throttle(onScrollBar, 2000);

      const onScroll = (progress) => {
        ScrollTrigger.update();

        if (!scrollbar.current) return;
        gsap.set(scrollbarThumb.current, {
          transform: `translate3d(0,${
            progress * (window.innerHeight - thumbHeight - 14 * 2)
          }px,0)`,
          force3D: true,
        });

        if (!scrollbar.current.classList.contains("active")) {
          scrollbar.current.classList.add("active");
        }

        if (!isScrolling) {
          isScrolling = true;

          throttledOnScroll();
        }
      };

      const update = (time) => {
        initLenis.raf(time * 1000);
      };

      gsap.ticker.add(update);
      initLenis.on("scroll", ({ progress }) => {
        onScroll(progress);
      });

      gsap.ticker.lagSmoothing(0);

      const thumbHeight = scrollbarThumb.current.getBoundingClientRect().height;
      const innerHeight = scrollbarInner.current.getBoundingClientRect().height;

      // Reset thumb to top
      scrollbarThumb.current.style.transform = `translate3d(0,0px,0)`;

      const clamp = (num, min, max) =>
        num <= min ? min : num >= max ? max : num;

      const mapRange = (inMin, inMax, input, outMin, outMax) => {
        return ((input - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
      };

      const onPointerMove = (e) => {
        if (!clicked) return;
        e.preventDefault();

        const offset = (window.innerHeight - innerHeight) / 2;
        const y = mapRange(
          0,
          window.innerHeight,
          e.clientY,
          -offset,
          innerHeight + offset
        );

        const progress = clamp(0, y / innerHeight, 1);
        const newPos = initLenis.limit * progress;

        initLenis.scrollTo(newPos, {
          duration: 1.8,
          force: true,
        });
      };

      const currentScrollbarThumb = scrollbarThumb.current;

      const onPointerDown = () => setClicked(true);
      const onPointerUp = () => setClicked(false);

      window.addEventListener("pointermove", onPointerMove, false);
      window.addEventListener("pointerup", onPointerUp, false);
      currentScrollbarThumb.addEventListener(
        "pointerdown",
        onPointerDown,
        false
      );

      if (
        elContent.current.getBoundingClientRect().height === window.innerHeight
      ) {
        scrollbar.current.classList.add("is--hidden");
      } else {
        scrollbar.current.classList.remove("is--hidden");
      }

      ScrollTrigger.defaults({
        scroller: elLenis.current,
      });

      ScrollTrigger.scrollerProxy(initLenis, {
        scrollTop(value) {
          return arguments.length
            ? initLenis.scrollTo(value, { duration: 0 })
            : initLenis.scroll;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
      });

      setIsLenis(true);

      return () => {
        document.documentElement.classList.remove("wait-cursor");
        initLenis.on("scroll", onScroll);
        window.removeEventListener("pointermove", onPointerMove);
        window.removeEventListener("pointerup", onPointerUp);
        currentScrollbarThumb.removeEventListener("pointerdown", onPointerDown);
        gsap.ticker.remove(update);
      };
    }
  }, [dimOnExit, setLenis, clicked]);

  return (
    <motion.div
      {...motionProps}
      ref={page}
      className={classes.root}
      style={motionStyle}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div
        className="scrollbar"
        ref={scrollbar}
        onMouseEnter={() => {
          scrollbar.current.classList.add("is--hover");
        }}
        onMouseLeave={() => {
          scrollbar.current.classList.remove("is--hover");
        }}
      >
        <div className="inner" ref={scrollbarInner}>
          <div className="thumb" id="thumb" ref={scrollbarThumb}></div>
        </div>
      </div>

      <main
        className="Page__content"
        style={{
          ...style,
        }}
      >
        <div className="linsa" ref={elLenis}>
          <div id="content" ref={elContent}>
            {isLenis && children}
          </div>
        </div>
      </main>

      {!isPresent && !location?.state?.next && (
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.6,
          }}
        />
      )}
    </motion.div>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
  dimOnExit: PropTypes.bool,
};

Page.defaultProps = {
  style: {},
  title: "FiftySeven® — Design Studio.",
  dimOnExit: false,
};

export default Page;
