import PropTypes from 'prop-types';
import React from 'react';
import { useMemo } from 'react';
import { Durations, Easings } from '../../animation';
import { Flex } from '../Flex';
import { MotionFlex } from '../MotionFlex';
import classes from './Details.module.scss';
import Services from './Services';

function Details({
  client,
  industry,
  services,
  year,
  theme
}) {
  const variants = useMemo(() => ({
    enter: ([{ idx, delay = 1 }] = [{}]) => ({
      y: '0%',
      transition: {
        delay: (delay ?? 0) + (idx ?? 0) * Durations.gap,
        duration: Durations.base,
        ease: Easings.easeOutCubic
      }
    }),
    exit: {
      y: '120%'
    }
  }), []);

  return (
    <Flex
      className={classes.root}
      justifyContent="space-between"
      wrap
    >
      <Flex style={{ overflow: 'hidden' }}>
        <MotionFlex
          animate="enter"
          custom={[{ idx: 0 }]}
          initial="exit"
          variants={variants}
        >
          <div className={classes.label}>
            Client:
          </div>
          <div className={classes.value}>
            {client}
          </div>
        </MotionFlex>
      </Flex>

      <Flex style={{ overflow: 'hidden' }}>
        <MotionFlex
          animate="enter"
          custom={[{ idx: 2 }]}
          initial="exit"
          variants={variants}
        >
          <div className={classes.label}>
            Industry:
          </div>
          <div className={classes.value}>
            {industry}
          </div>
        </MotionFlex>
      </Flex>

      <Flex style={{ overflow: 'hidden' }}>
        <MotionFlex
          animate="enter"
          custom={[{ idx: 1 }]}
          initial="exit"
          variants={variants}
        >
          <Services
            services={services}
            theme={theme}
          />
        </MotionFlex>
      </Flex>

      <Flex style={{ overflow: 'hidden' }}>
        <MotionFlex
          animate="enter"
          custom={[{ idx: 3 }]}
          initial="exit"
          variants={variants}
        >
          <div className={classes.label}>
            Year:
          </div>
          <div className={classes.value}>
            {year}
          </div>
        </MotionFlex>
      </Flex>
    </Flex>
  );
}

Details.propTypes = {
  client: PropTypes.string,
  industry: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

Details.defaultProps = {
  client: '',
  industry: '',
  services: [],
  theme: {},
  year: 0
};

export default Details;
