import classNames from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import './Panel.scss';

export const paddingSizeToClassNameMap = {
  none: null,
  s: 'Panel--paddingSmall',
  m: 'Panel--paddingMedium',
  l: 'Panel--paddingLarge'
}

/**
 * @typedef {object} PanelProps
 * @property {React.ReactNode} children
 * @property {string} [className]
 * @property {keyof typeof paddingSizeToClassNameMap} [paddingSize='m']
 * @property {boolean} [rounded=false]
 * @property {'light'|'dark'} [theme='dark']
 */

/** @type {import('react').ForwardRefRenderFunction<HTMLDivElement, PanelProps>} */
const Panel = React.forwardRef((
  {
    children,
    className,
    paddingSize = 'm',
    rounded = false,
    theme = 'dark',
    ...rest
  },
  ref
) => {
  return (
    <motion.div
      {...rest}
      ref={ref}
      className={classNames([
        'Panel',
        `Panel--${theme}`,
        {
          [paddingSizeToClassNameMap[paddingSize]]: paddingSize !== 'none',
          'Panel--rounded': rounded
        },
        className
      ])}
    >
      {children}
    </motion.div>
  );
});

Panel.propTypes = {
  paddingSize: PropTypes.oneOf(Object.keys(paddingSizeToClassNameMap)),
  rounded: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light'])
};

Panel.defaultProps = {
  paddingSize: 'm',
  rounded: false,
  theme: 'dark',
};

export default Panel;
