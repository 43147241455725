import { useInViewport } from 'ahooks';
import { motion, usePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
// import { useInView } from 'react-intersection-observer';

// function clamp(min, max, v) {
//   return Math.min(Math.max(v, min), max);
// }

// function progress(from, to, value) {
//   const toFromDifference = to - from;
//   return toFromDifference === 0 ? 1 : (value - from) / toFromDifference;
// }

// const VERTICAL_DOWN = 'VERITCAL_DOWN';
// const VERTICAL_UP = 'VERTICAL_UP';

// const ScrollDirection = {
//   0x00: VERTICAL_UP,
//   0x10: VERTICAL_DOWN
// };

// function getScrollDirection(scroll, prevScroll) {
//   return scroll.top > prevScroll.top ? ScrollDirection[0x10] : ScrollDirection[0x00];
// }

// function onScroll({ root, entry, prevScroll, setIntersectionData }, e) {
//   const direction = getScrollDirection({ top: root.scrollTop, left: root.scrollLeft }, prevScroll.current);

//   const rRect = root.getBoundingClientRect();
//   const tRect = entry.target.getBoundingClientRect();

//   const d = clamp(0, 1, progress(rRect.top + rRect.height, rRect.top, tRect.top));

//   prevScroll.current = { top: root.scrollTop, left: root.scrollLeft };
//   setIntersectionData({ top: root.scrollTop, left: root.scrollLeft, d });
// }

function Appear({
  children,
  className,
  el,
  exit,
  reverse,
  root,
  rootMargin,
  style,
  threshold,
  trigger = 'inViewport',
  variants,
  onAnimationComplete,
  onAnimationStart
}) {
  const [animation, setAnimation] = useState();
  // const { ref, inView, entry } = useInView({
  //   root,
  //   rootMargin,
  //   threshold,
  //   triggerOnce: !reverse
  // });

  const ref = useRef();
  const [inView] = useInViewport(ref, { root, rootMargin, threshold });
  const [isPresent, safeToRemove] = usePresence();
  // const onScrollRef = useRef(null);
  // const [intersectionData, setIntersectionData] = useState({ top: 0, left: 0 });
  // const prevScroll = useRef({ top: 0, left: 0 });

  // useEffect(() => {
  //   if (children instanceof Function && inView && entry && root && root instanceof HTMLElement && !onScrollRef.current) {
  //     onScrollRef.current = onScroll.bind(null, { root, entry, prevScroll, setIntersectionData });
  //     root.addEventListener('scroll', onScrollRef.current);
  //   }

  //   return () => {
  //     if (onScrollRef.current) {
  //       console.log(`removeEvent`);
  //       root.removeEventListener('scroll', onScrollRef.current);
  //       onScrollRef.current = null;
  //     }
  //   }
  // }, [
  //   children,
  //   entry,
  //   inView,
  //   prevScroll,
  //   root
  // ]);

  useEffect(() => {
    if (!isPresent) {
      safeToRemove();
    }
  }, [isPresent, safeToRemove, reverse]);

  useEffect(() => {
    if ((inView || trigger !== 'inViewport') && isPresent) {
      setAnimation('enter');
    }
    else if (isPresent && trigger === 'inViewport' && reverse) {
      setAnimation('exit');
    }
  }, [inView, isPresent, reverse, root, trigger]);

  useEffect(() => {
    if (children instanceof Function) {
      console.log(`inView: ${inView}`);
    }
  }, [children, inView]);

  if (!isPresent && !inView) {
    return null;
  }

  return React.createElement(motion[el], {
    animate: animation,
    // children: children instanceof Function ? children(intersectionData) : children,
    children,
    className,
    exit,
    inherit: false,
    initial: 'exit',
    ref,
    style,
    variants,
    onAnimationComplete,
    onAnimationStart
  });
}

Appear.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  el: PropTypes.string,
  exit: PropTypes.string,
  reverse: PropTypes.bool,
  root: PropTypes.instanceOf(Node),
  rootMargin: PropTypes.string,
  style: PropTypes.object,
  threshold: function (props, propName, componentName) {
    if (props[propName] < 0.0 || props[propName] > 1.0) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Validation failed. Range(0, 1).'
      );
    }
  },
  trigger: PropTypes.oneOf(['inViewport', 'mount']),
  variants: PropTypes.object,
  onAnimationComplete: PropTypes.func,
  onAnimationStart: PropTypes.func
};

Appear.defaultProps = {
  el: 'div',
  exit: 'exit',
  reverse: false,
  root: null,
  rootMargin: '0px',
  style: {},
  threshold: 0,
  trigger: 'inViewport',
  onAnimationComplete: null,
  onAnimationStart: null
}

export default Appear;
