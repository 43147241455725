import PropTypes from 'prop-types';
import PointerTypes from './types';

export const CursorProps = {
  label: PropTypes.node,
  type: PropTypes.oneOf(
    Object.keys(PointerTypes)
  ).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};
