import React, { useContext } from 'react';

/**
 * @typedef {import('../api/siteConfiguration').SiteConfiguration} SiteConfigurationContextValue
 */

/** @type {SiteConfigurationContextValue} */
const defaultContextValue = {
  businessInquiries: null
};

const SiteConfigurationContext = React.createContext(null);

export const { Provider: SiteConfigurationProvider } = SiteConfigurationContext;

export const useSiteConfiguration = () => (
  useContext(SiteConfigurationContext) ?? defaultContextValue
);
