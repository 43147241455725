import { useInViewport } from 'ahooks';
import classNames from 'classnames';
import { AnimatePresence, easeInOut, motion, transform, useIsPresent, useMotionValue } from 'framer-motion';
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useMedia } from 'react-recipes';
import { FreeMode, Mousewheel, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Easings } from '../animation';
import Appear from '../components/Appear';
import Card from '../components/Card';
import { CardMore } from '../components/Card/CardMore';
import { CardSharedTrasitionsContextProvider } from '../components/Card/contexts/cardSharedTrasitionContext';
import Container from '../components/Container';
import ContextMenu from '../components/ContextMenu';
import contextMenuClasses from '../components/ContextMenu/ContextMenu.module.scss';
import Dropdown, { DropdownValue } from '../components/Dropdown';
import { useMaskedRevealUpdate } from '../components/Motion';
import MaskedReveal from '../components/Motion/MaskedReveal';
import Page from '../components/Page';
import { Select } from '../components/Select';
import { withRegisterRoute } from '../routing';
import { Projects, Tags } from '../worksData';
import './Works.scss';

function getProjectCount() {
  return Projects.filter(({ legacy }) => !legacy).length;
}

const ProjectsCount = React.forwardRef((_, ref) => {
  const scaleValue = useMotionValue(0);

  useMaskedRevealUpdate(
    ref,
    useCallback(({ detail: { y } }) => {
      const yValue = transform(parseInt(y), [0, 100], [1, 0], { ease: easeInOut });
      scaleValue.set(yValue);
    }, [scaleValue])
  );

  return (
    <motion.div
      ref={ref}
      className="no"
      style={{
        scale: scaleValue,
        transformOrigin: 'bottom'
      }}
    >
      {getProjectCount()}
    </motion.div>

  );
});

ProjectsCount.displayName = 'ProjectsCount';

function Title({ root, isMobile }) {
  const elRef = useRef();
  const isPresent = useIsPresent();
  const [inView] = useInViewport(elRef, { root: () => root.current });

  if (!isPresent && !inView && !isMobile) {
    return null;
  }

  return (
    <div className="title" ref={elRef}>
      <MaskedReveal
        className="lineMask"
        delay={[0, 0]}
      >
        <div
          data-swiper-parallax={isMobile ? 170 : 340}
          style={{ display: 'flex', alignItems: 'flex-start' }}
        >
          Visual
          <MaskedReveal delay={[0, 0]}>
            <ProjectsCount />
          </MaskedReveal>
        </div>
      </MaskedReveal>

      <MaskedReveal
        className="lineMask"
        delay={[0.3, 0]}
      >
        <div data-swiper-parallax={isMobile ? 70 : 140}>
          eloquence.
        </div>
      </MaskedReveal>
    </div>
  )
}

function Works() {
  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const swiperRef = useRef();
  const [tagFilter, setTagFilter] = useState(Tags[0]);
  const [areLegacyProjectsVisible, setLegacyProjectsVisibility] = useState(false);
  const [showHint, setShowHint] = useState(true);
  const isPresent = useIsPresent();

  const isMobile = useMedia(
    ['(max-width: 640px)'],
    [true],
    false
  );

  const dropdownVariants = useMemo(() => ({
    enter: ([delay]) => ({
      y: 0,
      transition: {
        delay,
        ease: Easings.easeOutCubic
      }
    }),
    exit: ([, delay]) => ({
      y: '150%',
      transition: {
        delay,
        ease: Easings.easeOutCubic
      }
    })
  }), []);

  const hintVariants = useMemo(() => ({
    enter: ([{ delay }]) => ({
      scale: 1,
      transition: {
        delay,
        ease: Easings.easeOutCubic
      }
    }),
    exit: ([, { delay } = {}]) => ({
      scale: 0,
      transition: {
        delay,
        ease: Easings.easeOutCubic
      }
    })
  }), []);

  const title =
    <Title root={swiperRef} isMobile={isMobile} />

  const handleTagFilterChange = useCallback(({ value, onChange }) => () => {
    setTagFilter(value);

    if (onChange instanceof Function) {
      onChange();
    }
  }, []);

  const handleLegacyProjectsVisibilityChange = useCallback(() => {
    setLegacyProjectsVisibility(true);
  }, []);

  const dropdown = useMemo(() => {
    if (isMobile) {
      return (
        <Select
          label="Industry"
          options={Tags}
          onChange={(e) => {
            setTagFilter(Tags[e.target.value]);
          }}
        />
      );
    }

    return (
      <Dropdown
        opener={DropdownValue}
        openerProps={{
          className: 'Work__dropdown',
          label: 'Industry:',
          value: tagFilter.label
        }}
        popoverProps={{
          placement: 'bottom-start'
        }}
      >
        {({ close: onChange }) => (
          <ContextMenu
            compressed
            className="ContextMenu__Works"
            menuItems={Tags.filter(({ value }) => value !== tagFilter.value).map((tag, idx, arr) => ({
              render: () => {
                return (
                  <div
                    className={classNames([
                      contextMenuClasses.item,
                      'Work__contextmenu-item',
                    ])}
                    onClick={handleTagFilterChange({ value: tag, onChange })}
                  >
                    <motion.div
                      custom={[idx * 0.05, (arr.length - idx - 1) * 0.05]}
                      exit="exit"
                      initial="exit"
                      variants={dropdownVariants}
                    >
                      {tag.label}
                    </motion.div>
                  </div>
                )
              }
            }))}
          />
        )}
      </Dropdown>
    );
  }, [isMobile, tagFilter.label, tagFilter.value, handleTagFilterChange, dropdownVariants]);

  const filteredProjects = useMemo(() => {
    if (tagFilter.label === 'All') {
      return (
        Projects
          .filter(({ legacy }) => !legacy || (legacy && areLegacyProjectsVisible))
      );
    }

    return (
      Projects
        .filter(({ tags, legacy }) => (
          tags.includes(tagFilter.label))
          && (!legacy || (legacy && areLegacyProjectsVisible))
        )
    );
  }, [areLegacyProjectsVisible, tagFilter]);

  return (
    <CardSharedTrasitionsContextProvider>
      <Page title="Works">
        <Container
          className={classNames([
            'Container__content',
            'Work'
          ])}
          size="l"
        >
          <div className="Work__content-wrapper">
            <Swiper
              ref={swiperRef}
              freeMode
              modules={[
                FreeMode,
                Mousewheel,
                Parallax
              ]}
              mousewheel
              parallax
              slidesPerView="auto"
              spaceBetween={3}
              style={{ width: '100%', height: '100%' }}
              onTouchStart={() => {
                setShowHint(false);
              }}
            >

              <SwiperSlide
                className={classNames([
                  'Work__slide',
                  'Work__slide-title'
                ])}
              >
                <MaskedReveal className="Work__dropdown-wrapper">
                  <div data-swiper-parallax={isMobile ? 0 : 260}>
                    {dropdown}
                  </div>
                </MaskedReveal>

                {title}
              </SwiperSlide>

              {filteredProjects.map(({ id, ...cardProps }, idx) => {
                return (
                  <SwiperSlide
                    key={idx}
                    className={classNames([
                      'Work__slide',
                      'Work__slide-card'
                    ])}
                  >
                    <Appear
                      disabled
                      reverse={false}
                      trigger={isMobile ? 'mount' : 'inViewport'}
                      root={swiperRef.current}
                    >
                      <Card
                        {...cardProps}
                        index={idx}
                        isMobile={isMobile}
                        root={swiperRef.current}
                      />
                    </Appear>
                  </SwiperSlide>
                );
              })}

              {!areLegacyProjectsVisible && (
                <SwiperSlide
                  key={filteredProjects.length}
                  className={classNames([
                    'Work__slide',
                    'Work__slide-card'
                  ])}
                >
                  <Appear
                    disabled
                    reverse={false}
                    trigger={isMobile ? 'mount' : 'inViewport'}
                  >
                    <CardMore
                      index={filteredProjects.length}
                      title="Archives"
                      onClick={handleLegacyProjectsVisibilityChange}
                    />
                  </Appear>
                </SwiperSlide>
              )}
            </Swiper>
          </div>

          {isMobile && (
            <AnimatePresence>
              {showHint && isPresent && (
                <motion.div
                  custom={[{ delay: 1 }, { delay: 0 }]}
                  initial="exit"
                  animate={showHint ? 'enter' : 'exit'}
                  exit="exit"
                  variants={hintVariants}
                  className="Drag__Works"
                >
                  <span>Drag</span>
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </Container>
      </Page>
    </CardSharedTrasitionsContextProvider>
  );
}

export default withRegisterRoute(Works, {
  route: '/work',
  name: () => (
    <span>Work<sup>{getProjectCount()}</sup></span>
  ),
  weight: 5,
  options: {
    navBackOptions: { mobile: true }
  }
});
