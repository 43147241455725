import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import VideoBg from "./VideoBg";
import Video from "./Video";
import pxToRem from "../../Utils/pxToRem";
import { useInView } from "framer-motion";
import ButtonCursor from "../ButtonCursor";
import ImagePadding from "./ImagePadding";

import { gsap } from "../../../gsap";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, FreeMode]);

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledSwiper = styled(Swiper)`
  padding: 0 calc(var(--case-container) * ${pxToRem(1)}) !important;
  width: ${(props) =>
    props.offsetContainer
      ? `calc((100% + var(--case-container)) * ${pxToRem(1)}) !important`
      : "100%"};
  margin: ${(props) =>
    props.offsetContainer
      ? `0 calc(var(--case-container) * -${pxToRem(1)}) !important`
      : undefined};
  box-sizing: border-box;
`;

const IconMedia = styled.div`
  position: absolute;
  width: ${pxToRem(34)};
  height: ${pxToRem(34)};
  bottom: ${pxToRem(30)};
  right: ${pxToRem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.iconMediaBorderColor || "rgba(255, 255, 255, 0.2)"};
  border-radius: 100%;
  overflow: hidden;
  transition: transform 0.3s ease;
  will-change: transform;
  transform-style: preserve-3d;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 620px) {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    bottom: ${pxToRem(15)};
    right: ${pxToRem(15)};
  }

  .play,
  .pause {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: none;

    &.active {
      display: flex;
    }

    svg {
      height: 35%;
    }
  }

  .play {
    svg {
      @media (max-width: 620px) {
        margin-left: 1.5px;
      }
    }
  }
`;

const SwiperSlider = (props) => {
  const [firstEnter, setFirstEnter] = useState(false);
  const [dragHover, setDragHover] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const el = useRef(null);
  const videoRef = useRef([]);
  const elPlay = useRef([]);
  const elPause = useRef([]);
  const elCursor = useRef();
  const elIconMedia = useRef([]);

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const isInView = useInView(el, { margin: "100% 0px" });

  const pauseVideo = useCallback(
    (index) => {
      if (!videoRef.current[index]) return;
      videoRef.current[index].pause();
  
      if (props.hideIconMedia) return;
      if (!elPause.current[index] && !elPlay.current[index]) return;
      elPause.current[index].classList.remove("active");
      elPlay.current[index].classList.add("active");
    },
    [props.hideIconMedia]
  );
  

  const playVideo = useCallback(
    (index) => {
      if (!videoRef.current[index]) return;
      videoRef.current[index].play();
  
      if (props.hideIconMedia) return;
      if (!elPause.current[index] && !elPlay.current[index]) return;
      elPause.current[index].classList.add("active");
      elPlay.current[index].classList.remove("active");
    },
    [props.hideIconMedia]
  );

  useEffect(() => {
    if (isInView) {
      if (!isMobile) {
        playVideo(slideIndex);
      } else {
        videoRef.current.forEach((item, index) => {
          playVideo(index);
        });
      }
    } else {
      if (!isMobile) {
        pauseVideo(slideIndex);
      } else {
        videoRef.current.forEach((item, index) => {
          pauseVideo(index);
        });
      }
    }
  }, [pauseVideo, playVideo, isInView, isMobile, slideIndex]);

  useEffect(() => {
    if (!isMobile) return;
    if (!el.current) return;
    if (!elCursor.current) return;

    const handleMouseClick = () => {
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.3,
        display: "none",
        overwrite: true,
      });
    };

    const currentEl = el.current;

    if (currentEl) {
      currentEl.addEventListener("touchstart", handleMouseClick);
    }
  
    return () => {
      if (currentEl) {
        currentEl.removeEventListener("touchstart", handleMouseClick);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    if (!elCursor.current) return;
    if (isMobile) return;

    const handleMouseMove = (event) => {
      if (dragHover) return;
      if (isMobile) return;

      const elRect = el.current.getBoundingClientRect();
      const cursorRect = elCursor.current.getBoundingClientRect();

      const x = event.clientX - elRect.left - cursorRect.width / 2;
      const y = event.clientY - elRect.top - cursorRect.height / 2;

      if (!firstEnter) {
        gsap.set(elCursor.current, {
          left: x,
          top: y,
        });

        setFirstEnter(true);
      }

      gsap.to(elCursor.current, {
        scale: 1,
        left: x,
        top: y,
        duration: 0.6,
        delay: 0.01,
        ease: "power4.out",
      });
    };

    const handleMouseClick = () => {
      if (dragHover) return;
      if (isMobile) return;

      const tl = gsap.timeline({
        defaults: {
          duration: 0.3,
          ease: "power2.out",
        },
      });

      tl.to(elCursor.current, {
        scale: 0.9,
      });

      tl.to(elCursor.current, {
        scale: 1,
      });
    };

    const handleMouseLeave = () => {
      if (isMobile) return;
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.6,
        overwrite: true,
        ease: "power4.out",
        onComplete: () => {
          setFirstEnter(false);
        },
      });
    };

    const mediaHandleEnter = () => {
      if (isMobile) return;
      setDragHover(true);
      handleMouseLeave();
    };

    const mediaHandleLeave = () => {
      setDragHover(false);
    };

    if (el.current) {
      el.current.addEventListener("mousemove", handleMouseMove);
      el.current.addEventListener("mouseleave", handleMouseLeave);
      el.current.addEventListener("click", handleMouseClick);
    }

    if (elIconMedia.current && Array.isArray(elIconMedia.current)) {
      elIconMedia.current.forEach((elMedia) => {
        if (elMedia) {
          elMedia.addEventListener("mousemove", mediaHandleEnter);
          elMedia.addEventListener("mouseleave", mediaHandleLeave);
        }
      });
    }

    const currentEl = el.current;
    const currentElIconMedia = elIconMedia.current;

    return () => {
      if (currentEl) {
        currentEl.removeEventListener("mousemove", handleMouseMove);
        currentEl.removeEventListener("mouseleave", handleMouseLeave);
        currentEl.removeEventListener("click", handleMouseClick);
      }

      if (currentElIconMedia && Array.isArray(currentElIconMedia)) {
        currentElIconMedia.forEach((elMedia) => {
          if (elMedia) {
            elMedia.removeEventListener("mousemove", mediaHandleEnter);
            elMedia.removeEventListener("mouseleave", mediaHandleLeave);
          }
        });
      }
    };
  }, [isMobile, firstEnter, dragHover]);

  const slideChange = (el) => {
    if (isMobile) return;
    el.slides.forEach((slide, idx) => {
      pauseVideo(idx);
    });

    playVideo(el.activeIndex);
    setSlideIndex(el.activeIndex);
  };

  return (
    // className={isMobile ? "pl-5 mt-1" : "pl-10 mt-1"}
    <Wrapper ref={el} className={props.className}>
      <ButtonCursor
        ref={elCursor}
        text="Drag"
        fontSize={pxToRem(8)}
        sizeMobile={pxToRem(74)}
        topMobile="50%"
        leftMobile="95%"
        bgColor={props.cursorBgColor}
        textColor={props.cursorTextColor}
      />

      <StyledSwiper
        offsetContainer={props.offsetContainer}
        spaceBetween={props.spaceBetween ?? isMobile ? 10 : 15}
        slidesPerView={isMobile ? props.slidesPerView : 1.5}
        freeMode={true}
        grabCursor={props.grabCursor ?? true}
        onSlideChange={(el) => slideChange(el)}
        // pagination={{ clickable: true }}
      >
        {props.slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <VideoBg backgroundColor={slide.backgroundColor}>
              {props.borderCorner ? (
                <>
                  {slide.src ? (
                    <Video
                      ref={(el) => (videoRef.current[index] = el)}
                      noScrollTrigger={true}
                      src={slide.src}
                      poster={slide.poster}
                      autoplay={true}
                      aspect={slide.aspect}
                      paddingVideo={slide.paddingVideo}
                      borderColor={props.borderColor}
                      hideIconMedia={true}
                    />
                  ) : (
                    <ImagePadding
                      noAnimated={true}
                      src={slide.poster}
                      aspect={slide.aspect}
                      padding={slide.paddingVideo}
                    />
                  )}
                </>
              ) : (
                <>
                  {slide.src ? (
                    <Video
                      ref={(el) => (videoRef.current[index] = el)}
                      noScrollTrigger={true}
                      src={slide.src}
                      poster={slide.poster}
                      aspect={slide.aspect}
                      autoplay={true}
                      paddingVideo={slide.paddingVideo}
                      borderRadius="unset"
                      borderWidth="unset"
                      borderColor="unset"
                      borderStyle="unset"
                      hideIconMedia={true}
                    />
                  ) : (
                    <ImagePadding
                      noAnimated={true}
                      src={slide.poster}
                      aspect={slide.aspect}
                      padding={slide.paddingVideo}
                    />
                  )}
                  {!props.hideIconMedia && (
                    <IconMedia
                      ref={(el) => (elIconMedia.current[index] = el)}
                      iconMediaBorderColor={props.iconMediaBorderColor}
                    >
                      <span
                        ref={(el) => (elPlay.current[index] = el)}
                        className="play"
                        onClick={() => playVideo(index)}
                      >
                        <svg
                          width="6"
                          height="9"
                          viewBox="0 0 6 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 4.5L1.09788e-07 8.39711L4.50484e-07 0.602885L6 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>

                      <span
                        ref={(el) => (elPause.current[index] = el)}
                        className="pause"
                        onClick={() => pauseVideo(index)}
                      >
                        <svg
                          width="6"
                          height="8"
                          viewBox="0 0 6 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="1.5" height="8" fill="currentColor" />
                          <rect
                            x="4"
                            width="1.5"
                            height="8"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </IconMedia>
                  )}
                </>
              )}
            </VideoBg>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Wrapper>
  );
};

export default SwiperSlider;
