import { useEffect } from 'react';
import { off, on } from '../../utils';

/**
 * @template {EventTarget} T
 * @param {import('react').RefObject<T>} ref
 * @param {Function} fn
 */
export const useMaskedRevealUpdate = (ref, fn) => {
  useEffect(() => {
    const domElement = ref?.current ?? null;
    on(domElement, 'onrevealupdate', fn);

    return () => {
      off(domElement, 'onrevealupdate', fn);
    }
  }, [fn, ref]);
};
