import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  background-color: ${(props) => props.backgroundColor || '#fff'};
  background: ${(props) => props.background};
  color: ${(props) => props.color || "#000"};
  margin: ${props => props.margin} !important;
  padding: ${props => props.padding} !important;
  position: ${props => props.position} !important;
  /* content-visibility: auto; */
`;

const Section = forwardRef((props, el) => {
  return (
    <Wrapper ref={el} id={props.id} className={props.className} background={props.background} backgroundColor={props.backgroundColor} color={props.color} margin={props.margin} padding={props.padding} position={props.position}>
      {props.children}
    </Wrapper>
  );
});

export default Section;
