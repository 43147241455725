import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import Image from "./Image";
import pxToRem from "../../Utils/pxToRem";
import ButtonCursor from "../ButtonCursor";

import { gsap, Draggable } from "../../../gsap";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: calc((100% + var(--case-container)) * ${pxToRem(1)});
  margin-left: calc(var(--case-container) * -${pxToRem(1)});
  margin-right: calc(var(--case-container) * -${pxToRem(1)});
  padding-left: calc((var(--case-container)) * ${pxToRem(1)});
  padding-right: calc((var(--case-container)) * ${pxToRem(1)});
  position: relative;
`;

const Scroll = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding-left: calc((var(--case-container)) * ${pxToRem(1)});
  padding-right: calc((var(--case-container)) * ${pxToRem(1)});
  box-sizing: border-box;
  gap: ${(props) => props.gap};
`;

const ImageDrag = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef(null);
  const elCursor = useRef(null);
  const elScroll = useRef(null);

  useEffect(() => {
    if (isMobile) {
      Draggable.create(elScroll.current, {
        type: "x",
        bounds: el.current,
        inertia: true,
      });
    }

    if (!isMobile) return;
    if (!el.current) return;
    if (!elCursor.current) return;

    const handleMouseClick = () => {
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.3,
        display: "none",
        overwrite: true,
      });
    };

    const currentEl = el.current;

    if (currentEl) {
      currentEl.addEventListener("touchstart", handleMouseClick);
    }
  
    return () => {
      if (currentEl) {
        currentEl.removeEventListener("touchstart", handleMouseClick);
      }
    };
  }, [isMobile]);

  return (
    <Wrapper ref={el} className={props.className}>
      {isMobile && (
        <ButtonCursor
          ref={elCursor}
          text="Drag"
          fontSize={pxToRem(8)}
          sizeMobile={pxToRem(74)}
          topMobile="50%"
          leftMobile="83%"
          bgColor={props.cursorBgColor}
          textColor={props.cursorTextColor}
        />
      )}
      <Scroll gap={props.gap} ref={elScroll}>
        <Image
          width={props.width}
          src={props.src}
          aspect={props.aspect || "16/9"}
        />
      </Scroll>
    </Wrapper>
  );
};

export default ImageDrag;
