import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

/**
 * @typedef {object} ContainerContext
 * @property {DOMRect} [bounds]
 */

const ContainerContext = React.createContext(null);

/** @type {import('react').FC<ContainerContext>} */
export const ContainerProvider = ({
  bounds,
  children,
}) => {
  const value = useMemo(() => ({
    bounds
  }), [bounds]);

  return (
    <ContainerContext.Provider value={value}>
      {children}
    </ContainerContext.Provider>
  );
};

ContainerProvider.propTypes = {
  bounds: PropTypes.object,
  children: PropTypes.node
};

ContainerProvider.defaultProps = {
  bounds: {},
  children: null
};

export function useContainer() {
  /** @type {Omit<ContainerContext, "scroller"> & { enableScroll: function, disableScroll: function }>} */
  const context = useContext(ContainerContext);

  if (!context) {
    throw new Error('useContainer must be used within a `ContainerProvider`.');
  }

  return context;
}
