import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
// import FontFaceObserver from "fontfaceobserver";

// import { gsap, SplitText } from "../../../gsap";

const Wrapper = styled.div`
  width: ${(props) => props.width};
`;

const AnimTextPerline = (props) => {
  const el = useRef();
  // let splitText = useRef(null);
  // const [fontLoaded, setFontLoaded] = useState(false);


  // TODO: should be on app so no multiple loaded,
  // temporary solution because the breaking text is not properly after transition
  // useEffect(() => {
  //   const font1 = new FontFaceObserver("Suisse Intl");
  //   const font2 = new FontFaceObserver("Eurostile Next LT Pro Light Ext");
  //   const font3 = new FontFaceObserver("Eurostile Next LT Pro Bold Ext");
  //   const font4 = new FontFaceObserver("Eurostile Next LT Pro SmBdExt");
  //   const font5 = new FontFaceObserver("Eurostile Next LT Pro Extended");
  //   const font6 = new FontFaceObserver("HelveticaNowText Light");
  //   const font7 = new FontFaceObserver("HelveticaNowText ExtraLight");
  //   const font8 = new FontFaceObserver("Helvetica Now Text");
  //   const font9 = new FontFaceObserver("Helvetica Now Display");
  //   const font10 = new FontFaceObserver("HelveticaNowText Medium");
  //   const font11 = new FontFaceObserver("HelveticaNowText ExtraBold");

  //   const font1Load = font1.load(null, 10000);
  //   const font2Load = font2.load(null, 10000);
  //   const font3Load = font3.load(null, 10000);
  //   const font4Load = font4.load(null, 10000);
  //   const font5Load = font5.load(null, 10000);
  //   const font6Load = font6.load(null, 10000);
  //   const font7Load = font7.load(null, 10000);
  //   const font8Load = font8.load(null, 10000);
  //   const font9Load = font9.load(null, 10000);
  //   const font10Load = font10.load(null, 10000);
  //   const font11Load = font11.load(null, 10000);

  //   Promise.all([
  //     font1Load,
  //     font2Load,
  //     font3Load,
  //     font4Load,
  //     font5Load,
  //     font6Load,
  //     font7Load,
  //     font8Load,
  //     font9Load,
  //     font10Load,
  //     font11Load,
  //   ]).finally(() => {
  //     setFontLoaded(true);
  //   });
  // }, []);

  // useLayoutEffect(() => {
  //   if (!fontLoaded) return;
  //   // TODO: change this later after layouting is done
  //   let ctx = gsap.context(() => {

  //     if (!props.noSplit) {
  //       splitText.current = new SplitText(el.current.children, {
  //         type: "lines",
  //         linesClass: "line",
  //       });
  
  //       gsap.utils.toArray(splitText.current.lines).forEach((el, i) => {
  //         const wrapper = document.createElement("div");
  //         wrapper.classList.add("masking-text");
  //         el.parentNode.insertBefore(wrapper, el);
  //         wrapper.appendChild(el);
  //       });
  //     }

  //     if (props.noAnimated) return;
  //     const textLength = props.children.length > 2; // if paragraph is more than 2

  //     const tl = gsap.timeline({
  //       delay: props.delay || 0,
  //       defaults: {
  //         duration: textLength ? gsap.defaults().duration / 1.5 : gsap.defaults().duration,
  //         stagger: props.stagger || (textLength ? gsap.defaults().stagger / 2 : gsap.defaults().stagger)
  //       },
  //       scrollTrigger: {
  //         trigger: props.trigger || el.current,
  //       },
  //     });

  //     tl.from(".line", {
  //       yPercent: 105,
  //     });

  //     if (props.right) {
  //       tl.from(el.current, {
  //         x: 15,
  //       }, "<=50%");
  //     }

  //   }, el);

  //   return () => ctx.revert();
  // }, [fontLoaded, props.children.length, props.delay, props.noAnimated, props.noSplit, props.right, props.stagger, props.trigger]);

  return (
    <Wrapper className={props.className} ref={el} right={props.right} stagger={props.stagger} noSplit={props.noSplit} noAnimated={props.noAnimated} width={props.width}>
      {props.children}
    </Wrapper>
  );
};

export default AnimTextPerline;
