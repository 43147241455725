import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { hasParentElementWithClass } from '../utils';

function HamburgerMenuHotspot({
  menuOpen,
  offsetX,
  width,
  onMouseEnter,
  onMouseLeave
}) {
  const handleMouseEnter = useCallback((e) => {
    if (!menuOpen) {
      onMouseEnter?.(e);
    }
  }, [menuOpen, onMouseEnter]);

  const handleMouseLeave = useCallback((e) => {
    if (!hasParentElementWithClass(e.relatedTarget, 'SiteHeaderLinks__wrapper')) {
      onMouseLeave?.(e);
    }
  }, [onMouseLeave]);

  return (
    <div
      data-hotspot
      style={{
        position: 'absolute',
        right: offsetX * -1,
        top: 0,
        width: Math.floor(width ?? 0) + offsetX,
        height: 'calc(100% + 10px)',
        zIndex: 9
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
}

HamburgerMenuHotspot.propTypes = {
  menuOpen: PropTypes.bool,
  offsetX: PropTypes.number,
  width: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

HamburgerMenuHotspot.defaultProps = {
  menuOpen: false,
  offsetX: 10,
  width: 0
};

export default HamburgerMenuHotspot;
