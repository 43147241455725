import classnames from 'classnames';
import { motion } from 'framer-motion';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Durations, Easings } from '../../animation';
import { useNormalizeValue } from '../../hooks';
import './Block.scss';

function Block({
  className,
  delay,
  stagger,
  text,
  title
}) {
  const [delayEnter, delayExit] = useNormalizeValue(delay);
  const withTitle = isString(title);

  return (
    <div
      className={classnames([
        'Block',
        className
      ])}
    >
      {withTitle && (
        <div className="Block__title">
          <motion.div
            className="BlockTitle"
            variants={{
              enter: {
                y: '0%',
                transition: {
                  delay: delayEnter,
                  duration: Durations.base,
                  ease: Easings.easeOutCubic
                }
              },
              exit: {
                y: '100%',
                transition: {
                  delay: delayExit + text.length * stagger,
                  duration: Durations.base,
                  ease: Easings.easeInCubic
                }
              }
            }}
          >
            {title}
          </motion.div>
        </div>
      )}

      <div className="Block__body">
        <motion.div
          className="BlockBody"
          variants={{
            enter: {
              transition: {
                delayChildren: delayEnter + (withTitle ? stagger : 0),
                staggerChildren: stagger
              }
            },
            exit: {
              transition: {
                delayChildren: delayExit,
                staggerChildren: stagger,
                staggerDirection: -1
              }
            }
          }}
        >
          {text.map((__html, key) => (
            <div
              key={key}
              className={classnames([
                'Block__line',
                `line__${key}`
              ])}
            >
              <motion.div
                dangerouslySetInnerHTML={{ __html }}
                variants={{
                  enter: {
                    y: '0%',
                    transition: {
                      duration: Durations.base,
                      ease: Easings.easeOutCubic
                    }
                  },
                  exit: {
                    y: '100%',
                    transition: {
                      duration: Durations.base,
                      ease: Easings.easeInCubic
                    }
                  }
                }}
              />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

Block.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  stagger: PropTypes.number,
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string
};

Block.defaultProps = {
  delay: 0,
  stagger: Durations.gap
};

export default Block;
