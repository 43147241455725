import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Easings } from '../../animation';
import classes from './Dropdown.module.scss';

const DropdownValue = React.forwardRef(({
  className,
  label,
  theme,
  value,
  onClick
}, ref) => {
  const variants = useMemo(() => ({
    initial: {
      y: '120%',
    },
    enter: () => ({
      y: 0,
      transition: {
        ease: Easings.brunoIn
      }
    }),
    exit: () => ({
      y: '-120%',
      transition: {
        ease: Easings.brunoOut
      }
    })
  }), []);

  return (
    <div
      className={classNames([
        classes.root,
        classes.container,
        className
      ])}
      onClick={onClick}
    >
      {label && (
        <div className={classes.DropdownLabel}>{label}</div>
      )}
      <div
        ref={ref}
        className={classNames([
          classes.DropdownToggle,
          classes.DropdownToggleInner,
          classes[theme]
        ])}
      >
        <div className={classes.DropdownToggleText}>
          <AnimatePresence mode="wait">
            <motion.div
              key={value}
              animate="enter"
              exit="exit"
              inherit={false}
              initial="initial"
              variants={variants}
            >
              {value}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
});

DropdownValue.displayName = 'DropdownValue';

DropdownValue.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func
};

DropdownValue.defaultProps = {
  className: null,
  label: null,
  theme: 'light',
  value: null,
  onClick: null
};

export default DropdownValue;
