import { motion, useIsPresent } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Durations, Easings } from '../../animation';
import { useNormalizeValue } from '../../hooks';
import classNames from 'classnames';

const resolveDelay = (delay, withoutDelay) => (
  withoutDelay ? {} : { delay }
);

const RevealVariants = {
  enter: ([[delay], { withoutDelay }]) => ({
    y: '0%',
    transition: {
      ease: Easings.easeOutCubic,
      duration: Durations.base,
      ...resolveDelay(delay, withoutDelay)
    }
  }),
  exit: ([[, delay], { withoutDelay }]) => ({
    y: '110%',
    transition: {
      ease: Easings.easeInCubic,
      duration: Durations.base,
      ...resolveDelay(delay, withoutDelay)
    }
  }),
};

function MaskedReveal({
  as,
  auto,
  children,
  className,
  delay,
  elStyle,
  style,
  withoutDelay,
  onRevealComplete
}) {
  const domElement = useRef();
  const [delayEnter, delayExit] = useNormalizeValue(delay);
  const [isRevealed, setIsRevealed] = useState(false);
  const isPresent = useIsPresent();

  const animationTargets = useMemo(() => {
    if (auto) {
      return {};
    }

    return {
      animate: 'enter',
      initial: 'exit',
      exit: 'exit'
    }
  }, [auto]);

  const handleAnimationStart = useCallback((variant) => {
    if (!isPresent) {
      return;
    }

    if (variant === 'exit') {
      setIsRevealed(false);
    }
  }, [isPresent]);

  const handleAnimationComplete = useCallback((variant) => {
    if (!isPresent) {
      return;
    }

    if (variant === 'enter') {
      const event = new CustomEvent('onrevealcomplete');
      domElement.current?.dispatchEvent(event);
      setIsRevealed(true);
    }
  }, [isPresent]);

  const handleUpdate = useCallback((values) => {
    const event = new CustomEvent('onrevealupdate', { detail: values });
    domElement.current?.dispatchEvent(event);
  }, []);

  const element = useMemo(() => (
    React.isValidElement(children)
      ? React.cloneElement(
        React.Children.only(children),
        {
          ref: domElement
        }
      )
      : children
    ), [children]);

  useEffect(() => {
    if (onRevealComplete instanceof Function && isRevealed) {
      onRevealComplete();
    }
  }, [isRevealed, onRevealComplete]);

  useLayoutEffect(() => {
    const el = domElement.current;

    if (el) {
      // Create dummy event handler to check if the element has a reveal animation.
      el.onrevealcomplete = () => {};
      el.onrevealupdate = () => {};
    }

    return () => {
      if (el) {
        delete el.onrevealcomplete;
        delete el.onrevealupdate;
      }
    }
  }, []);

  return (
    React.createElement(
      as,
      {
        className: classNames('MaskedReveal', className),
        style: {
          ...style,
          overflow: isRevealed && isPresent ? null : 'hidden'
        }
      },
      <motion.div
        {...animationTargets}
        style={elStyle}
        custom={[[delayEnter, delayExit], { withoutDelay }]}
        variants={RevealVariants}
        onAnimationComplete={handleAnimationComplete}
        onAnimationStart={handleAnimationStart}
        onUpdate={handleUpdate}
      >
        {element}
      </motion.div>
    )
  );
}

MaskedReveal.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  auto: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  elStyle: PropTypes.object,
  style: PropTypes.object,
  withoutDelay: PropTypes.bool
};

MaskedReveal.defaultProps = {
  as: 'div',
  auto: false,
  className: null,
  delay: 0,
  elStyle: {},
  style: {},
  withoutDelay: false
};

export default MaskedReveal;
