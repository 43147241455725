import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useMemo,
} from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import pxToRem from "../../Utils/pxToRem";

// Component
import Container from "../Layout/Container";
import Section from "../Section";
import Overlay from "../Overlay";

import { gsap, ScrollTrigger, Flip } from "../../../gsap";
import AnimTextPerline from "../Motion/Textperline";
import Chapter from "../Text/Chapter";
import Menunavhero from "../Menunavhero";
import Heading1 from "../Text/Heading1";
import ButtonCursor from "../ButtonCursor";

import { useAtom } from "jotai";
import { nextTransition } from "../../../atom/transitionAtom";

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  a {
    text-decoration: none;
  }

  &.active {
    visibility: hidden;
  }
`;

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMenunavhero = styled(Menunavhero)`
  order: 2;
  margin-top: ${pxToRem(27)};
  color: #fff;

  @media screen and (max-width: 620px) {
    margin-top: ${pxToRem(20)};
    order: 2;
  }
`;

const StyledHeading1 = styled(Heading1)`
  order: 1;
  margin-top: ${pxToRem(73)};
  white-space: nowrap;

  @media screen and (max-width: 620px) {
    margin-top: ${pxToRem(20)};
    order: 1;
    padding: 0 2rem;
    box-sizing: border-box;
    white-space: normal;
  }
`;

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const Next = forwardRef((props, el) => {
  const location = useLocation();

  const [firstEnter, setFirstEnter] = useState(false);
  const [mouseClick, setMouseClick] = useState(false);

  const elCursor = useRef();

  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const clone = useRef();

  // const [isNextTransition, setIsNextTransition] = useAtom(nextTransition);

  const routerState = useMemo(
    () => ({
      from: location.pathname,
      casetocase: true,
      next: false, // TODO: change this to true later
    }),
    [location]
  );

  const cloneProject = (el, flipId) => {
    // el.dataset.flipId = flipId;

    const cloneDiv = (fromDiv, toDiv) => {
      const clone = fromDiv.cloneNode(true);
      clone.removeAttribute("class");
      clone.removeAttribute("style");

      clone.classList.add("is--cloned");

      toDiv.appendChild(clone);
    };

    cloneDiv(el, clone.current);
  };

  useLayoutEffect(() => {
    clone.current = document.querySelector(".clone-transition");
    
    let ctx = gsap.context(() => {
      gsap.from(".next__bg", {
        scale: 1.4,
        yPercent: -50,
        ease: "none",
        scrollTrigger: {
          trigger: el.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },
      });

      gsap.to(".next__overlay", {
        opacity: 0.3,
        ease: "none",
        scrollTrigger: {
          trigger: el.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },
      });
    }, el);

    return () => ctx.revert();
  }, [el]);

  const handleMouseClick = () => {
    if (isMobile) return;
    if (!elCursor.current) return;

    setMouseClick(true);

    setTimeout(() => {
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.6,
        overwrite: true,
        ease: "power4.out",
      });  
    }, 1000);
  };

  useEffect(() => {
    if (!el.current) return;
    if (!elCursor.current) return;
    if (isMobile) return;
    if (mouseClick) return;

    const handleMouseMove = (event) => {
      if (isMobile) return;

      const elRect = el.current.getBoundingClientRect();
      const cursorRect = elCursor.current.getBoundingClientRect();

      const x = event.clientX - elRect.left - cursorRect.width / 2;
      const y = event.clientY - elRect.top - cursorRect.height / 2;

      if (!firstEnter) {
        gsap.set(elCursor.current, {
          left: x,
          top: y,
        });

        setFirstEnter(true);
      }

      gsap.to(elCursor.current, {
        scale: 1,
        left: x,
        top: y,
        duration: 0.6,
        delay: 0.01,
        ease: "power4.out",
      });
    };

    const handleMouseLeave = () => {
      if (isMobile) return;
      gsap.to(elCursor.current, {
        scale: 0,
        duration: 0.6,
        overwrite: true,
        ease: "power4.out",
        onComplete: () => {
          setFirstEnter(false);
        },
      });
    };

    // const handleMouseClick = () => {
    //   if (!el.current) return;

    //   // setIsNextTransition(true)
    //   // const img = el.current.querySelector("img");

    //   if (!isMobile) {
    //     gsap.to(elCursor.current, {
    //       scale: 0,
    //     });
    //   }

    //   // let ctx = gsap.context(() => {
    //   //   // gsap.delayedCall(1.5, () => {
    //   //     // gsap.to(".next__overlay", {
    //   //     //   opacity: 0,
    //   //     //   duration: 0.3,
    //   //     //   ease: "power2.out",
    //   //     //   onComplete: () => {
    //   //     //     gsap.set("#next img", {
    //   //     //       autoAlpha: 0,
    //   //     //     });
    //   //     //   },
    //   //     // });

    //   //     gsap.to(".line", {
    //   //       yPercent: -101,
    //   //       duration: 0.6,
    //   //       delay: 2.0,
    //   //       ease: "customEase3",
    //   //       onComplete: () => {
    //   //         cloneProject(img, "fullscreen");
    //   //         clone.current.classList.add("active");
    //   //         // el.current.classList.add("active");
    //   //       },
    //   //     });
    //   //   // });
    //   // }, el);

    //   // return () => ctx.revert();
    // };

    const currentEl = el.current;

    currentEl.addEventListener("mousemove", handleMouseMove);
    currentEl.addEventListener("mouseleave", handleMouseLeave);
    // currentEl.addEventListener("click", handleMouseClick);
    
    return () => {
      if (currentEl) {
        currentEl.removeEventListener("mousemove", handleMouseMove);
        currentEl.removeEventListener("mouseleave", handleMouseLeave);
        // currentEl.removeEventListener("click", handleMouseClick);
      }
    };
  }, [isMobile, firstEnter, el, mouseClick]);

  return (
    <Wrapper ref={el}>
      <ButtonCursor
        ref={elCursor}
        text="Open"
        size={pxToRem(134)}
        sizeMobile={pxToRem(111)}
        topMobile="65%"
        leftMobile="50%"
        bgColor={props.cursorBgColor}
        textColor={props.cursorTextColor}
      />
      <Section id="next" position="relative" padding="0">
        <Link state={routerState} to={props.link} onClick={handleMouseClick}>
          <img
            className="next__bg"
            src={props.img}
            alt="Next Case"
            draggable="false"
          />
          <Container>
            <Overlay className="next__overlay" opacity={0.8} />
            <Content type={props.type}>
              <AnimTextPerline stagger="0">
                <Chapter
                  className="chapter"
                  text="Case Study"
                  color="#ffffff"
                  number="②"
                />
              </AnimTextPerline>

              <Flex>
                <StyledMenunavhero
                  type={props.type}
                  className={isMobile ? "mt-8" : "mb-4"}
                  items={props.menuitem}
                  services={props.services}
                  opacity="1"
                  serviceInfo={false}
                />
                <AnimTextPerline>
                  <StyledHeading1
                    type={props.type}
                    text={props.title}
                    align="center"
                    color="#ffffff"
                  />
                </AnimTextPerline>
              </Flex>
            </Content>
          </Container>
        </Link>
      </Section>
    </Wrapper>
  );
});

export default Next;
