import React from 'react';
import classes from './ContextMenu.module.scss';

function ContextMenuItemSerparator() {
  return (
    <hr className={classes.separator} />
  );
}

export default ContextMenuItemSerparator;
