import React from 'react';
import styled from 'styled-components';
import pxToRem from '../../Utils/pxToRem';
import AnimTextPerline from '../Motion/Textperline';

const Text = styled.p`
  font-family: "Suisse Intl", sans-serif;
  font-weight: 300;
  font-size: clamp(12px, ${pxToRem(16)}, ${pxToRem(16)});
  letter-spacing: 0.04em;
  line-height: 1.428;
  text-transform: ${(props) => props.transform};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
  display: block;

  b, strong {
    font-weight: 500;
  }

  @media screen and (min-width: 1376px) {
    font-size: clamp(13px, ${pxToRem(16)}, ${pxToRem(16)});
  }

  @media screen and (max-width: 620px) {
    font-size: ${pxToRem(10)};
    letter-spacing: 0.08em;
    line-height: 1.5;
  }
`;

const Paragraph4 = (props) => {
  return (
    <AnimTextPerline className={props.className} noSplit={props.noSplit} noAnimated={props.noAnimated}>
      <Text lineHeight={props.lineHeight} color={props.color} align={props.align} transform={props.transform} margin={props.margin}>{props.children}</Text>
    </AnimTextPerline>
  );
};

export default Paragraph4;
