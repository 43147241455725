import { useEffect } from 'react';

const mutationObserverInit = {
  subtree: false,
  childList: false,
  attributes: false,
  attributeFilter: [],
  attributeOldValue: false,
  characterData: false,
  characterDataOldValue: false
};

/**
 * @param {Node|import('react').MutableRefObject} ref
 * @param {MutationCallback} callback
 * @param {MutationObserverInit} options
 */
function useMutationObserver(ref, callback, options = mutationObserverInit) {
  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const observer = new MutationObserver(callback);
    const node = ref?.current || ref;

    observer.observe(node, options);
    return () => {
      observer.disconnect();
    };
  }, [ref, callback, options]);
}

export default useMutationObserver;
