import React, { useRef, forwardRef, useEffect } from "react";
import styled from "styled-components";
import pxToRem from "../Utils/pxToRem";
import Plyr from "plyr";
import "plyr/dist/plyr.css"; // Import Plyr CSS styles

const Wrapper = styled.div`
  overflow: hidden;
  width: ${(props) => props.width};
  --plyr-color-main: ${(props) => props.playerColorMain};
  --plyr-video-control-color: ${(props) => props.playerIconColor};
  --plyr-audio-control-color-hover: ${(props) => props.playerIconColor};
  --plyr-video-control-color-hover: ${(props) => props.playerIconColor};
  --plyr-control-radius: 3px;

  .plyr__control--overlaid {
    width: ${pxToRem(84)};
    height: ${pxToRem(84)};
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transform: translate(-50%, -70%);

    @media screen and (max-width: 620px) {
      width: ${pxToRem(50)};
      height: ${pxToRem(50)};
    }

    &:hover {
      &::before {
        transform: translate(-25%, -50%) scale(1.3);
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-25%, -50%);
      width: 0;
      height: 0;
      border-top: ${pxToRem(10)} solid transparent;
      border-bottom: ${pxToRem(10)} solid transparent;
      border-left: ${pxToRem(15)} solid var(--plyr-video-control-color);
      transition: transform 0.8s var(--transition-ease);
      will-change: transform;

      @media screen and (max-width: 620px) {
        border-top: ${pxToRem(5)} solid transparent;
        border-bottom: ${pxToRem(5)} solid transparent;
        border-left: ${pxToRem(8)} solid var(--plyr-video-control-color);
      }
    }

    .textduration {
      position: absolute;
      bottom: -25%;
      left: 50%;
      transform: translate(-50%, 0%);
      color: var(--plyr-color-main);
      font-family: "Suisse Intl", sans-serif;
      font-size: ${pxToRem(9)};
      font-weight: 500;
      letter-spacing: 0.15em;
      line-height: 1.4;
    }

    svg {
      display: none;
    }
  }

  .plyr--video .plyr__controls {
    --plyr-video-control-color: ${(props) => props.playerColorMain};
  }

  .plyr__video-wrapper {
    pointer-events: none;
  }

  /* .plyr--playing {
    .plyr__poster {
      opacity: 1 !important;
    }
  } */
`;

const VideoWrapper = styled.div`
  width: 100%;
  padding: ${(props) => props.padding};

  .overlay-video {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  video {
    pointer-events: none !important;
    position: relative;
  }
`;

const VideoElement = styled.video`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: ${(props) => props.paddingVideo};
`;

const VideoFallback = styled.p`
  padding: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
`;

const VideoCustomPlayer = forwardRef((props, ref) => {
  const refWrapper = useRef(null);
  const refVideo = useRef(null);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Use template literals to add leading zeros if needed
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const player = new Plyr(refVideo.current, {
      hideControls: false,
      controls: [
        // "play-large",
        // "play",
        // "progress",
        // "current-time",
        // "mute",
        // "volume",
        // "fullscreen",
      ],
      fullscreen: {
        enabled: false,
        iosNative: false,
      }
    });

    if (!player) return;
    player.toggleControls(false);

    player.on("play", (event) => {
      player.toggleControls(true);
    });

    player.on("pause", (event) => {
      player.toggleControls(false);
    });

    player.on("loadedmetadata", (event) => {
      const formattedTime = formatTime(player.duration);

      const videoel = player?.elements?.wrapper;
      if (videoel) {
        const overlayVideo = document.createElement("div");
        overlayVideo.classList.add("overlay-video");
        videoel.insertBefore(overlayVideo, videoel.firstChild);
      }

      if (player?.elements?.buttons) {
        const play = player?.elements?.buttons?.play[0];
        if (play) {
          const textDuration = document.createElement("p");
          textDuration.textContent = formattedTime;
          textDuration.classList.add("textduration");
          play.appendChild(textDuration);
        }
      }
    });

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, []);

  return (
    <Wrapper
      ref={refWrapper}
      className={props.className}
      width={props.width}
      playerColorMain={props.playerColorMain}
      playerIconColor={props.playerIconColor}
    >
      <VideoWrapper padding={props.padding} style={{ "--aspect": props.aspect || "16/9" }}>
        <VideoElement
          ref={refVideo}
          autoPlay={props.autoPlay ?? false}
          playsInline={props.playsInline ?? false}
          loop={props.loop ?? true}
          muted={props.muted ?? true}
          controls={props.controls ?? true}
          preload={props.preload ?? "metadata"}
          data-poster={props.poster}
          paddingVideo={props.paddingVideo}
        >
          <source src={props.src} type={props.type ?? "video/mp4"} />
          <VideoFallback>
            {props.fallbackMessage ??
              "Your browser does not support the video element. Please consider updating to a modern browser."}
          </VideoFallback>
        </VideoElement>
      </VideoWrapper>
    </Wrapper>
  );
});

export default VideoCustomPlayer;
