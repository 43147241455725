import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Easings } from '../animation';
import classes from  './HamburgerMenu.module.scss';
import { rgba } from 'polished';

function HamburgerMenu({
  color,
  isActive,
  onClick
}) {
  const variants = useMemo(() => ({
    line: (from, to) => ({
      open: {
        y: to,
        transition: {
          ease: Easings.easeOutCubic
        }
      },
      closed: {
        y: from,
        transition: {
          ease: Easings.easeOutCubic
        }
      }
    })
  }), []);

  return (
    <div
      className={`HamburgerMenu ${classes.root}`}
      style={{
        '--line-color': isActive ? rgba(color, 0.3) : color
      }}
    >
      <div
        className={classes.interactiveArea}
        onClick={onClick}
      />

      <motion.div className={classes.line}>
        <motion.div
          className={classes.lineInner}
          variants={variants.line(0, 2)}
        />
      </motion.div>

      <div className={classes.line}>
        <div className={classes.lineInner} />
      </div>

      <div className={classes.line}>
        <motion.div
          className={classes.lineInner}
          variants={variants.line(0, -2)}
        />
      </div>
    </div>
  );
}

HamburgerMenu.propTypes = {
  color: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

HamburgerMenu.defaultProps = {
  color: '#c4c4c4',
  isActive: false,
  onClick: () => { }
};

export default HamburgerMenu;
