let flexGapSupported = null;

export function isFlexGapSupported() {
  if (flexGapSupported !== null) {
    return flexGapSupported;
  }

  if (typeof document === 'undefined') {
    return false;
  }

  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  document.body.appendChild(flex);
  flexGapSupported = flex.scrollHeight === 1;
  flex.parentNode.removeChild(flex);

  return flexGapSupported;
}

/**
 * @param {number} val
 * @returns {string|null}
 */
export function getGapValue(val) {
  return typeof val === 'number' ? `${val}px` : null;
}

/**
 * @param {number} idx
 * @param {number} [delay=0.15]
 * @return {number}
 */
export function getDelay(idx, delay = 0.15) {
  return idx > 0 ? delay : 0;
}
