import React, { useLayoutEffect, useEffect, useRef } from "react";
import styled from "styled-components";
import { useMedia } from "react-recipes";
import pxToRem from "../Utils/pxToRem";

// Component
import Label1 from "./Text/Label1";
import Label2 from "./Text/Label2";
import Paragraph2 from "./Text/Paragraph2";
import Border from "./Border";
import AnimTextPerline from "./Motion/Textperline";

import { gsap, ScrollTrigger, Draggable } from "../../gsap";

const MasterWrapper = styled.div`
  @media (max-width: 620px) {
    width: auto;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 620px) {
    display: flex;
    gap: ${pxToRem(80)};
    width: max-content;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${pxToRem(108)};
  row-gap: ${pxToRem(108)};
  list-style: none;
  padding: 0;
  position: relative;

  @media (max-width: 620px) {
    column-gap: ${pxToRem(80)};
    row-gap: ${pxToRem(80)};
  }

  @media screen and (max-width: 620px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${pxToRem(110)};
  position: relative;

  @media (max-width: 620px) {
    width: ${(props) => props.itemWidthMobile || pxToRem(230)};
    gap: ${pxToRem(82)};
  }

  &.active {
    &:nth-child(3n + 1),
    &:nth-child(3n + 2) {
      &:after {
        transform: scaleY(1);
      }
    }
  }

  &:nth-child(3n + 1),
  &:nth-child(3n + 2) {
    &:after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: ${(props) =>
        props.borderColor || "rgba(27, 26, 28, 0.2)"};
      position: absolute;
      top: 0;
      right: -${pxToRem(54)};
      transform-origin: top;
      transform: scaleY(0);
      transition: transform var(--transition-default);

      @media (max-width: 620px) {
        right: -${pxToRem(40)};
      }
    }
  }
`;

const Pointnumber = (props) => {
  const isMobile = useMedia(["(max-width: 640px)"], [true], false);

  const el = useRef();
  const elWrapper = useRef();
  const itemRefs = useRef([]);
  const itemRefs2 = useRef([]);

  // This function is used to assign a ref for each item
  const addItemRef = (el, index) => {
    itemRefs.current[index] = el;
  };

  const addItemRef2 = (el, index) => {
    itemRefs2.current[index] = el;
  };
  
  useEffect(() => {
    if (!isMobile) return;
    Draggable.create(elWrapper.current, {
      type: "x",
      bounds: el.current,
      inertia: true,
    });
}, [isMobile]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.utils
        .toArray([itemRefs.current, itemRefs2.current])
        .forEach((ref, index) => {
          if (ref.length <= 0) return;
          ScrollTrigger.create({
            trigger: el.current,
            onEnter: () => {
              gsap.delayedCall(index * 0.2, () => {
                ref.classList.add("active");
              });
            },
          });
        });
    }, el);

    return () => ctx.revert();
  }, [isMobile]);

  return (
    <div ref={el}>
      <Label1 text={props.title} />

      {props.title && (
        <Border
          className={isMobile && props.title ? "mt-4" : "mt-4 mb-5"}
          color={props.borderColor}
        />
      )}

      {!props.title && (
        <Border
          className={isMobile ? "mt-9" : "mt-7 mb-5"}
          color={props.borderColor}
        />
      )}

      <MasterWrapper>
        <Wrapper ref={elWrapper} className={isMobile ? "my-9" : ""}>
          <List className={props.className}>
            {props.items.map((item, index) => (
              <Item
                ref={(el) => addItemRef(el, index)}
                key={index}
                borderColor={props.borderColor}
                itemWidthMobile={props.itemWidthMobile}
              >
                <AnimTextPerline
                  right={true}
                  trigger={el.current}
                  delay={index * 0.15}
                >
                  <Label2 text={item.number} />
                </AnimTextPerline>

                <AnimTextPerline trigger={el.current}>
                  <Paragraph2>{item.desc}</Paragraph2>
                </AnimTextPerline>
              </Item>
            ))}
          </List>

          {!isMobile && props.items2 && (
            <Border className="my-5" color={props.borderColor} />
          )}

          {props.items2 && (
            <List className={props.className}>
              {props.items2.map((item, index) => (
                <Item
                  ref={(el) => addItemRef2(el, index)}
                  key={index}
                  borderColor={props.borderColor}
                  itemWidthMobile={props.itemWidthMobile}
                >
                  <AnimTextPerline
                    right={true}
                    trigger={el.current}
                    delay={0.15 * props.items.length + 0.15 * index}
                  >
                    <Label2 text={item.number} />
                  </AnimTextPerline>

                  <AnimTextPerline trigger={el.current}>
                    <Paragraph2>{item.desc}</Paragraph2>
                  </AnimTextPerline>
                </Item>
              ))}
            </List>
          )}
        </Wrapper>
      </MasterWrapper>

      {isMobile && (
        <Border className={isMobile ? "" : "my-5"} color={props.borderColor} />
      )}
    </div>
  );
};

export default Pointnumber;
