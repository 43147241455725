import React from 'react';
import './Digital.scss';

function Digital ({ time }) {
  return (
    <div className="DigitalClock">
      {time.formatted}
    </div>
  );
}

export default Digital;
